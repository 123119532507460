<script setup lang="ts">
import {ref} from "vue"
import {loginWith} from "@/common"
import {storeToRefs} from "pinia"
import {useLoginForm, useAccount} from "@/store"
import {errorIn} from "@/animation"
import {notify} from "@kyvg/vue3-notification"
import RequestPasswordReset from "@/comps/RequestPasswordReset.vue"
import MobileModal from "@/comps/MobileModal.vue"

const Account = useAccount()
const {username, password} = storeToRefs(useLoginForm())

const emit = defineEmits([ 'close', 'success'])

const isShowReset = ref(false)



// const username = ref("")
// const password = ref("")

const err = ref("")
const isLoading = ref(false)


async function login() {
    console.log("提交登录请求")

    isLoading.value = true
    err.value = ""

    

    try {
        await loginWith(username.value, password.value)

        // console.log("登录成功", res)
        notify({type: "success", text: "登录成功"})
        
        // localStorage() 保存的 chatboy_session_key 会在 apiFetch 中自动设置

        Account.reload()

        emit('close')
        emit('success')

        
    } catch (e) {
        err.value = (e as Error).message
    } finally {
        isLoading.value = false
    }
    
}

</script>

<template v-slot="{username, password}">
    <form class="flex-col-center flex-gap28" @submit.prevent="login">
        <div class="flex-col-center flex-gap20 width100">
            <div class="form-input-ctrl input-medium width100">
                <input type="text" placeholder='邮箱' name="username" v-model="username" autocomplete="username" />
            </div>
            <div class="form-input-ctrl input-medium width100">
                <input type="password" placeholder='密码' name="password" v-model="password" autocomplete="current-password" />
            </div>
            <div class="flex width100">
                <a @click.prevent="isShowReset=true" class="font14 link-text-nude-line color-blue">
                    <icon name="Chat-Bubble-Question" />
                    忘记密码
                </a>
            </div>

            <Transition @enter="errorIn">
                <p class="form-label g-label-warn width-fit" v-if="err">{{err}}</p>
            </Transition>
        </div>

        

        <button type="submit" class="button-g-3d button-medium width160" :class="{'button-loading': isLoading}" >
            <div class="ctrl">
                <icon name="Loading-Circle" />            
                登录
            </div>
        </button>
    </form>

    
    <Component :is="MobileModal" :isShow="isShowReset" icon="Key-Password" title="重置密码" :hideOKButton="true" @close="isShowReset=false">
        <Component :is="RequestPasswordReset" :hideHeader="true" />    
    </Component>
    

</template>