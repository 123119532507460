<script setup lang="ts">
import { defineAsyncComponent, onMounted, onUnmounted, ref } from "vue"
import Header from "../comps/Header.vue"
import Chat from "../comps/Chat.vue"
import Tools from "@/comps/Tools.vue"
import Shop from "@/pages/Shop.vue"
const Help = defineAsyncComponent(() => import("@/pages/Help.vue"))
import ChatSideBar from "../comps/ChatSideBar.vue"
import ToolsSideBar from "../comps/ToolsSideBar.vue"
import { computed } from "@vue/reactivity";
import {getChatID, isMobile as commonIsMobile, pathToPage} from "../common2"
import {storeToRefs} from "pinia"
import {useAccount} from "@/store"
import {slideDownIn, slideDownOut} from "@/animation"

import MobileFooter from "@/comps/MobileFooter.vue"


const currentPath = ref("#/")

const currentPage = computed(() => {
    let page = pathToPage(currentPath.value)
    if (page.startsWith("/")) {
        page = page.slice(1)
    }
    return page
})

const currentUser = storeToRefs(useAccount().a)


const isMobile = ref(commonIsMobile())



onMounted(() => {
    currentPath.value = window.location.pathname

    window.addEventListener('resize', onResize)
    window.addEventListener("hashchange", onHashChange)
})
onUnmounted(() => {
    window.removeEventListener('resize', onResize)
    window.removeEventListener("hashchange", onHashChange)
})

function onHashChange(e :any) {
    currentPath.value = window.location.pathname
}

function onResize() {
    isMobile.value = commonIsMobile()
}

</script>



<template>
    
<div class="g-container">
    
    <Component :is="Header" />

    <div class="main-container">

        
        <Component :is="Chat"
            v-if="['chat', ''].indexOf(currentPage) >= 0"    
            :isMobile="isMobile"        
        />
        

        <Component :is="Shop" v-if="currentPage == 'shop'" :currentUser="currentUser" />
        <Component :is="Tools" :isMobile="isMobile" v-if="currentPage.startsWith('tools')" />

        <Component :is="Help" v-if="currentPage == 'help'" />


        <!-- 底部菜单-->
        <Component :is="MobileFooter" />
        
    </div>

</div>

</template> 
