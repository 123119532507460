<script setup lang="ts">
import {ref, watch, onMounted, computed} from "vue"
import {storeToRefs} from "pinia"
import {useLoginForm, useAccount, useInvite} from "@/store"
import {searchParams, apiFetch, gaga, duration2Readable} from "@/common"
import {notifyError, cbCaptureException} from "@/common2"
import {useCookies}  from "vue3-cookies"
import {errorIn} from "@/animation"
import { notify } from "@kyvg/vue3-notification";
import vvCaptcha from "@/libs/vvcaptcha"

const {cookies} = useCookies()


const emit = defineEmits(['close', 'success'])

const {username, password} = storeToRefs(useLoginForm())
const Account = useAccount()
const account = storeToRefs(Account).account



const password2 = ref("")
const inviteCode = ref("")
const error = ref("")
const serverError = ref("")
const isLoading = ref(false)

const vvCaptchaProgress = ref(0)

// 推广码对应的推广信息
const promotion = ref(<any>null)
const isPromotionLoaded = ref(false)


watch(account, (newVal) => {
    console.log("account 数据变化了，新数据是 ", newVal)
})


onMounted(() => {
    const promo = getPromo()
    let fromInviteCode = searchParams().get("r")
    
    loadPromotion()

    try {
        // 发送谷歌事件
        if (promo && promo != '' && promo.slice(0, 4) != '2307') {
            let c = cookies.get("scan_qrcode")
           
            if (c) {
                // 已经设置过 cookies 了，不需要重复发送事件
                console.debug("已经发送过扫码事件，本次不发送", `cookie.promo=${c}, query.promo=${promo}`)
            } else {
                console.debug("发送一个扫码事件")
                cookies.set("scan_qrcode", promo, 86400)
                gaga("event", "scan_qrcode", {promo})
            }
        } 
        
        if (fromInviteCode) {
            let c = cookies.get("open_referral_link")
            let cdata = `${promo}.${fromInviteCode}`

            if (c && c == cdata) {
                // 已经设置过 cookies 了，不需要重复发送事件
            } else {
                cookies.set("open_referral_link", cdata, 3600)
                gaga("event", "open_referral_link", {promo, from: fromInviteCode})
            }
        }
    } catch (e) {
        console.warn(e)
    }
})


function getPromo() :string {
    let promo = searchParams().get("s") || ""

    // 如果 URL 中不存在 promo，则尝试使用 hiref 提供的 promo（即 Hi 页面设置的）
    if (promo == "") {
        promo = cookies.get("hiref")
        if (promo != "") {
            console.debug("当前 URL 未提供 promo，从 hiref 中读取到了预设的 promo", promo)
        }
    }

    return promo
}


async function loadPromotion() {
    try {
        const name = getPromo()
        if (name == "" ){
            return
        }

        const j = await apiFetch(`/goapi/promotion/get/${name}`)
        console.debug("加载到推广码信息", name, j)
        promotion.value = j.promotion
    } catch(e) {
        console.warn("推广码信息加载失败",e)
    } finally {
        isPromotionLoaded.value = true
    }
}

import * as Sentry from "@sentry/vue"

async function vvCaptchaDo(useFor :string, onprogress: (p :number) => void) :Promise<{question :string, answer :string, elapsed :number}> {
    let question :string
    let vvCount, vvSize :number
    try {
        const j = await apiFetch("/goapi/vvcaptcha/create", {
            method: "post",
            body: JSON.stringify({
                UseFor: useFor,
            }),
        })
        question = j.Question
        vvCount = j.Count
        vvSize = j.Size
    } catch(e) {
        notifyError("无法验证你是人类，请联系客服，我们会立即为您解决. " + (e as Error).message)
        throw e
    }

    const stime = Date.now()
    let answer :string
    try {
        answer = await vvCaptcha({
            question: question,
            size: vvSize,
            count: vvCount,
            onprogress: onprogress,
        })
        console.debug("vvCaptcha 结果是", answer)
        console.debug("vvCaptcha 结果长度为", answer.length)
    } finally {
        isLoading.value = false
    }
    const elapsed = Date.now() - stime
    console.debug("vvCaptcha 计算耗时 ", elapsed, "毫秒")

    return {
        question: question,
        answer: answer,
        elapsed: elapsed,
    }
}

async function register() {
    console.log("提交注册请求")

    const promo = getPromo()
    serverError.value = ""



    
    // 检查用户提供的账户名是否是邮箱
    let email = username.value
    if (email == "") {
        serverError.value = "邮箱地址不能为空"
        return
    }

    let regexp = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,})$/
    if (regexp.test(email) == false) {
        serverError.value = "邮箱地址不合法"
        return
    }

    if (password.value.length < 6) {
        serverError.value = "密码至少需要 6 位"
        return
    }

    if (password.value != password2.value) {
        serverError.value = "两次输入的密码不一致"
        return
    }


    let iCode = inviteCode.value
    if (iCode == "") {
        console.log("用户未输入邀请码，尝试使用 URL 中的邀请码")

        let token =  window.location.hash.split("?")
        if (token.length >= 2) {
            let kv = token[1].split("&")
            for (let i = 0; i < kv.length; i++) {
                if (kv[i].split("=")[0] == "r") {
                    iCode = kv[i].split("=")[1]
                    console.log("使用 URL 中提供的邀请码", iCode)
                    break
                }
            }
        }
        console.log("URL 中没有提供邀请码，不会发送邀请码")
    }
    


    // 计算 vvCaptcha 
    let vvResult :any
    try {
        vvCaptchaProgress.value = 1
        isLoading.value = true
        vvResult = await vvCaptchaDo("register", (p :number) => vvCaptchaProgress.value = Math.round(p * 10) / 10)
        console.log("vvCaptcha 结果是", vvResult)
    } catch(e) {
        cbCaptureException(e as Error)
        notifyError((e as Error).message)
        Sentry.captureException(e)
        return
    }finally {
        isLoading.value = false
    }

    
    // 发送注册请求
    try {
        isLoading.value = true
    
        let res = await apiFetch("/goapi/register", {
            method: "post",
            body: JSON.stringify({
                "username": username.value,
                "password": password.value,
                "promo": promo,
                "invite_code": iCode,
                "implicit_invite_code": useInvite().inviteCode,
                "vv_captcha_question": vvResult.question,
                "vv_captcha_answer": vvResult.answer,
                "vv_captcha_elapse_time": vvResult.elapsed / 1000,
            }),
        })

        console.debug("注册成功", res)

        // useAccount().reload() 方法会写入 chatboy_current_user 数据，我们不需要在这里写入了 
        // localStorage.setItem("chatboy_current_user", JSON.stringify(res.user))

        notify({type: "success", text: "注册成功，已自动登录"})
        Account.reload()


        // 注册成功后即删除隐式验证码
        useInvite().setInviteCode("")

        emit('close')
        emit('success')
    } catch (e) {
        const m = (e as Error).message

        serverError.value = m

        // 如果服务端返回 vvCapture 的相关错误，则上报此错误
        if (m.indexOf("E_VVQ") >= 0) {
            cbCaptureException(e as Error)
        }
        
    } finally {
        isLoading.value = false
    }
    
}

const promo = computed(() :string => {
    return getPromo()
})

const isDisplayInviteCodeInput = computed(() :boolean => {
    const fromInviteCode = searchParams().get("r")
    if (fromInviteCode != "" ){
        return false
    }
    
    if (useInvite().inviteCode != "") {
        return false
    }
    return true
})

const isShowTryC = computed(() :boolean => {
    if (promo.value == null) {
        return false
    }

    if (promo.value == '2306' || promo.value == '2306ol') {
        return true
    }
    if ( promo.value.slice(0, 4) == '2307') {
        return true
    }
    return false
})

</script>

<template>
    <form class="flex-col-center flex-gap28" @submit.prevent="register">

        <div class="flex-col-center flex-gap20 width100">
            <div class="form-input-ctrl input-medium width100">
                <input type="text" placeholder='邮箱' name="username" v-model="username"  autocomplete="username" />
            </div>
            <div class="form-input-ctrl input-medium width100">
                <input type="password" placeholder='密码' name="password" v-model="password" autocomplete="current-password" />
            </div>
            <div class="form-input-ctrl input-medium width100">
                <input type="password" placeholder='重复密码' name="password2" v-model="password2" autocomplete="repeat-password" />
            </div>
            <div class="form-input-ctrl input-medium width100" v-if="isDisplayInviteCodeInput">
                <input type="text" placeholder='邀请码（可不填写）' name="invite_code" v-model="inviteCode" />
            </div>
            <p class="form-label g-label-warn width-fit" v-if="error">{{error}}</p>
        </div>

        <Transition name="slideDown">
        <div v-if="isShowTryC" class="form-tip-purple">
            注册后即可免费获得「体验套餐 C」，含 ChatGPT 和 Midjourney 的 7 天使用时长和 20,000 点数。（注册成功后约 1 分钟左右赠送到账户）
        </div>
        <div v-else-if="promo == '2309quest'" class="form-tip-purple">
            注册后即可获得「七天体验券」并自动兑换，包含 ChatGPT 和 Midjourney 访问权限，时长 7 天，含 1,000,000 点数。
        </div>
        
        <div v-else-if="promotion && promotion.Product.ID != 0">
            <!-- <div v-if="promotion.EndTime * 1000 < Date.now()"  class="form-tip-purple">推广码「{{getPromo()}}」已过期</div> -->
            <div v-if="Date.now() < promotion.EndTime * 1000"  class="form-tip-purple">注册后即可获得「{{promotion.Product.Name}}」，包含 ChatGPT、 Midjourney 和 Claude 访问权限，时长 {{duration2Readable(promotion.Product.ChargeTime, 0, true)}}，含 {{promotion.Product.ChargeToken.toLocaleString()}} 点数（约 1 分钟左右自动赠送到账户）</div>
            
        </div>
        </Transition>
        
        <Transition @enter="errorIn">
            <p class="form-label g-label-warn width-fit" v-if="serverError != ''">{{serverError}}</p>
        </Transition>

        <Transition name="slideDown">
            <p class="form-label g-label-sub-gray width-fit font13" v-if="isLoading">正在确认你是人类，请稍等... {{(Math.round(vvCaptchaProgress * 100))}}%</p>
        </Transition>

        <button type="submit" class="button-g-3d button-medium width160" :class="{'button-loading': isLoading}">
            <div class="ctrl">
                <icon name="Loading-Circle" />            
                注册
            </div>
        </button>

        
    </form>
</template>
