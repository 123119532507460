<script setup lang="ts">
const props = defineProps<{
    name: string
}>()
</script>


<template>
    <svg :class="`icon icon-${props.name}`" preserveAspectRatio="xMaxYMid meet">
        <use :xlink:href="`#icon-${props.name}`"></use>
    </svg>
</template> 
