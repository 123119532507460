<script setup  lang="ts">
import { onMounted, provide, computed, ref,  getCurrentInstance, onUnmounted, watch, defineAsyncComponent} from 'vue';
const Icons = defineAsyncComponent(() => import("./comps/Icons.vue"))
import { apiFetch, reloadCurrentUser, wsURL } from './common';
import {getQuery, notifyInfo, notifySuccess, notifyError } from "@/common2"
import {WebSocketClient} from "./WebSocketClient.js"
import {useAccount, useWebPush, usePreference, useDaily} from "@/store"
import {storeToRefs} from "pinia"
import {useRoute} from "vue-router"

import Modals from "@/comps/Modals.vue"

import bus from "@/bus"


const route = useRoute()

const currentUser = ref(<any>null)
const currentAccount = ref(<any>null)

const Account = useAccount()
const account = storeToRefs(Account).a

provide('currentAccount', Account.account)

var ws: WebSocketClient | undefined = undefined

watch(() => Account.a, (newVal, oldVal) => {
    const TAG = "[watch.useAccount().a]"
    // console.log("useAcount（）变化了，新旧值是", newVal, oldVal)
    let newID = newVal?.account?.id || 0
    let oldID = oldVal?.account?.id || 0
    // console.log("监视到 useAccount() 可能的变动，新旧是用户编号是", newID, oldID)

    if (newID > 0 && oldID == 0) { 
        console.debug(TAG, `用户(${newID})登录了`)
        
        useDaily().reload()
        bus.emit(bus.刷新聊天列表)

        if (ws === undefined) {
            startWebsocket()
        } else {
            ws.close()
        }
    } else if (newID == 0 && oldID > 0) {
        console.debug(TAG, `用户(${oldID})退出了登录`)
        ws?.close()
    } else if (newID != oldID) {
        console.debug(TAG, `当前已登录用户信息发生变化 ${oldID} --> ${newID}，将执行 WS 重连等一系列操作`)
        
        ws?.close()
        bus.emit(bus.刷新聊天列表)
    }

})

onMounted(() => {
    // 注册主题监听方法
    registerThemeListener()
    try {
        autoSetTheme(usePreference().theme)
    } catch(e) {
        console.warn(e)
    }

    try {
        document.body.addEventListener("touchstart", () => {})
    } catch(e) {
        console.warn("为 body 注册 touchstart 事件失败（用于修复 iOS 移动端 :active 伪类效果）", e)
    }

    try {
        let d = document.getElementById("cb-loader")
        if (d) {
            d.style.display = "none"
        }
    } catch (e) {
        console.warn("无法隐藏首屏加载动画", e)
    }

    
    // 尝试自动登录
    autoLogin()


    // console.log("开始注册 storage 事件监听器")
    window.addEventListener("storage", (e :any) => {        
        // console.log("发生 storage 事件(key, new, old)", e.key, e.oldValue, e.newValue)
        if (e.key == "chatboy_session_key") {
            if (e.newValue) {
                if (e.newValue != e.oldValue) {
                    console.debug("监听到 localStorage 中用户信息被修改，重新加载用户信息")
                    useAccount().reload()
                }
            } else {
                console.debug("监听到 localStorage 中用户信息被清除，直接退出登录操作")
                // 退出登录
                useAccount().logout()
            }
        }
        
    })


    // 在收到 Preference 更新消息的时候，重新加载 Preference
    bus.on("ws.model.preference.update", (e: any) => {
        usePreference().reload()
    })
    
    
    Account.reload()
    useWebPush().sync()

    bus.on("ws.vapid.delete", (e: any) => {
        console.log("收到了 ws.vapid.delete 总线消息")
        useWebPush().sync(true)
    })


    // setTimeout(startWebsocket, 3000)
})


onUnmounted(() => {
    if (ws !== undefined) {
        ws.close()
        ws = undefined
    }
})




async function startWebsocket() {
    ws = new WebSocketClient(wsURL());

    // 注册监听事件
    ws.on('open', () => {
        console.debug('WebSocket连接已打开');

        // 进行认证
        let key = localStorage.getItem("chatboy_session_key")
        ws?.send(JSON.stringify({
            "Type": "auth", "Data": key,
        }))
    });

    ws.on('message', (data :any) => {
        console.debug('收到来自 WebSocket 的消息:', data);
        try {
            let j = JSON.parse(data)
            bus.emit("ws." + j?.Type, j)
        } catch (e) {
            console.warn("收到的 WebSocket 消息不是合法的 JSON 格式", e, data)
        }
    });

    ws.on('close', () => {
        console.log('WebSocket连接已关闭');
    });

    ws.on('error', (error :any) => {
        console.error('WebSocket发生错误:', error);
    });

    // 连接WebSocket服务器
    ws.connect();

    // 发送消息
    // client.send('Hello World');

    // 关闭连接
    // client.close();
}

// function closeWS() {
//     console.log("将关闭 WS 链接")
//     ws.close()
// }

function registerThemeListener() {
    const theme = storeToRefs(usePreference()).theme
    // setTheme(p.value)
    watch(theme, (t) => {
        console.log("Watch 到主题变化了", t)
        autoSetTheme(t)
    })
    
    // 注册系统黑夜模式变化监听器
    const media = window.matchMedia('(prefers-color-scheme: dark)')
    if (typeof media.addEventListener === 'function') {
        media.addEventListener('change', onDarkChange);
    } else if (typeof media.addListener === 'function') {
        media.addListener(onDarkChange);
    }
}

// 根据当前的 usePreference() 设置，自动设置页面主题
function autoSetTheme(t :"dark" | "light" | "auto") {
    console.debug("尝试根据配置自动设置当前页面主题", t)
    
    if (t == "auto") {
        console.log("主题设置为 auto，将跟随系统配置")
        const media = window.matchMedia('(prefers-color-scheme: dark)')
        if (media.matches) {
            t = "dark"
        } else {
            t = "light"
        }
        setTheme(t)
    } else {
        setTheme(t)
    }    
}

// 系统黑夜模式监听器
function onDarkChange(e :MediaQueryListEvent) {
    // const media = window.matchMedia('(prefers-color-scheme: dark)')
    console.log("黑暗模式改变了", e.matches)
    if (e.matches) {
        console.log("切换到黑暗模式")
        setTheme("dark")
    } else {
        console.log("切回到非黑暗模式")
        setTheme("light")
    }
}

function setTheme(t :"dark" | "light") {
    const dl = document.body.classList
    if (t == "dark") {
        dl.add("theme-dark")
        dl.remove("theme-light")
    } else {
        dl.add("theme-light")
        dl.remove("theme-dark")
    }
}

const routerViewKey = computed(() => {
    let accountID = account.value?.account?.id || 0
    return accountID + ";" + route?.fullPath
})


async function autoLogin() {
    const token = getQuery("auto_login_token")
    if (!token) {
        console.debug("没有设置自动登录参数，不需要执行自动登录")
        return
    }

    const key = localStorage.getItem("chatboy_session_key")
    if (key) {
        console.debug("虽然已经设置了自动登录参数，但本地已经存在 session_key，仍不会执行自动登录")
        return
    }

    notifyInfo("正在自动登录...")
    try {
        await apiFetch("/goapi/auto_login", {
            method: "POST", 
            body: JSON.stringify({
                Token: token,
            })
        })
        await useAccount().reload()
        notifySuccess("自动登录成功")
    } catch(e) {
        notifyError("自动登录失败：" + (e as Error)?.message)
    }
    

}

</script>



<template>
    <Icons />

    <!-- <button @click="closeWS">关闭</button> -->

    <router-view
        :key="routerViewKey"
    ></router-view>
    
    
    <!-- 通知消息-->
    <notifications position="top center" classes="notification-label" :duration="3000" />


    <!-- 各种弹窗 -->
    <Component :is="Modals" />
</template>

