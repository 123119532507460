<script setup lang="ts">
import { onUnmounted, onMounted, watch} from "vue"
import {apiFetch, gaga} from "../common"
import {ref, computed} from "vue"
import { notify } from "@kyvg/vue3-notification"
import {PendingPaymentInfo} from "@/types"
import {useRouter} from "vue-router"
import {useAccount} from "@/store"
import bus from "@/bus"

const router = useRouter()

const prop = defineProps<{
    currentUser: any,
    qty: number,
    productInternalName: string,
    product: any,
    // 应付金额
    amount: number,
}>()

const emit = defineEmits(["reloadCurrentUser", "onPendingPaymentInfo"])

const isLoading = ref(false)
const isButtonLoading = ref(false)
const serverError = ref("")
const orderData = ref(<any>null)
const xddData = ref(<any>null)

// 支付二维码是否已经准备好了
const isQRCodeValid = ref(false)
// 支付二维码是否已经失效了
const isQRCodeExpired = ref(false)
// 支付二维码加载成功的时间
const QRCodeLoadedTime = ref(0)

// 支付倒计时
const countdown = ref(0)

const isPaymentSuccess = ref(false)

var timerId: number = 0
var watchPaymentTimerId: number = 0

onMounted(() => {
    stopWatchPayment()
    bus.on("ws.Charge.Success", onChargeSuccess)
})

onUnmounted(() => {
    clearInterval(timerId)
    stopWatchPayment()
    bus.off("ws.Charge.Success", onChargeSuccess)
})

watch(() => prop.qty, (newVal) => {
    resetPayment()
})
watch(() => prop.productInternalName, (newVal) => {
    resetPayment()
})
watch(isPaymentSuccess, (newVal) => {
    if (newVal == true) {
        console.log("支付成功了，准备跳转", newVal)
        setTimeout(() => {
            // window.location.hash = "#/tools"
            router.push("/tools")
        }, 3000)
    }
})

function onChargeSuccess() {
    console.log("收到 Charge.Success，强制执行一次检查")
    checkPayment()
}


function startWatchPayment() {
    stopWatchPayment()
    watchPaymentTimerId = setTimeout(watchPayment, 1000)
}
function stopWatchPayment() {
    clearTimeout(watchPaymentTimerId)
    watchPaymentTimerId = 0
}

function resetPayment() {
    isQRCodeValid.value = false
    xddData.value = null
    orderData.value = null
    stopWatchPayment()
}

async function watchPayment() {
    refreshCountdown()

    try {
        await checkPayment()
    } catch (e) {
        console.log(e)
    }

    if (watchPaymentTimerId != 0) {
        watchPaymentTimerId = setTimeout(watchPayment, 3000)
    }
}

async function checkPayment() {
    let res = await apiFetch(`/goapi/payment/load?id=${orderData.value.ID}`, {method: "GET"})
    console.log("支付状态检查结果: ", res)
    if (res.order.Status == "success" ){
        isPaymentSuccess.value = true
        stopWatchPayment()

        // emit("reloadCurrentUser")
        useAccount().reload()
        

        gaga("event", "conversion_event_purchase", {})
        gaga("event", "purchase", {
            price: res.order.Price,
            qty: res.order.Qty,
            amount: res.order.Amount,
        })
    }
}

async function makePayment() {
    console.log("提交付款请求")

    isLoading.value = true
    isButtonLoading.value = true
    serverError.value = ""
    isPaymentSuccess.value = false
    serverError.value = ""

    try {
        gaga("event", "begin_checkout", {
            internal_name: prop.productInternalName,
            qty: prop.qty,
        })

        let res = await apiFetch("/goapi/payment/new", {
            method: "post",
            body: JSON.stringify({
                qty: prop.qty,
                product_internal_name: prop.productInternalName,
            }),
        })

        orderData.value = res.order
        xddData.value = JSON.parse(res.order.XDDResponse)  

        isQRCodeValid.value = true
        isQRCodeExpired.value = false

        // QRCodeLoadedTime.value = (new Date()).getTime() / 1000
        QRCodeLoadedTime.value = res.order.CreateTime

        refreshCountdown()

        startWatchPayment()
    } catch (e) {
        serverError.value = (e as Error).message
        notify({
            text: (e as Error).message,
            type: "error"
        })
    } finally {
        isLoading.value = false
        isButtonLoading.value = false
    }
}

function refreshCountdown() {
    let s = QRCodeLoadedTime.value + 4.5 * 60 -  (new Date()).getTime() / 1000 
    if (s < 0) {
        s = 0
        isQRCodeExpired.value = true
    }
    countdown.value = Math.round(s)
}

const countdownStr = computed(() :string => {
    let m = Math.floor(countdown.value / 60)
    let s = Math.round(countdown.value % 60)
    return `${m}分${s}秒`
})

const discountAmount = computed(() => {
    // return "0.01"
    return Math.round((prop.product.Price * prop.qty - (xddData.value?.realmoney ?? prop.amount)) * 100) / 100
})

const pendingPaymentInfo = computed(() :PendingPaymentInfo => {
    let p = new PendingPaymentInfo()
    if (xddData) {
        p.realAmount = xddData.value?.realmoney || 0
    }
    // p.amount = prop.amount
    // p.productName = prop.product.Name

    return p
})

watch(pendingPaymentInfo, () => {
    // console.log("发送一条 pending Info")
    emit('onPendingPaymentInfo', pendingPaymentInfo.value)
})


</script>


<template>

    <!--备份？？
    <div class="bottom-info-section buy-section">
        <icon name="Timer" />
        <p class="">
            待付
            <span v-if="!xddData" class="price color-purple">{{amount}}</span>
            <span v-else class="price color-purple">{{xddData?.realmoney}}</span>
            元 · <span class="color-purple">{{product.Name}}</span>
            （已优惠 {{discountAmount}} 元）
        </p>
    </div>
    -->

    <!-- 点击生成付款二维码 -->
    <!-- <div v-if="!xddData" className="qr-tile">
        <div className="qr-card">
            <div className="overlay-wrap">
                <button @click="makePayment" class="button-type-a   button-generate-qrcode" :class="{'has-icon': isButtonLoading, 'status-loading': isButtonLoading}" :disabled="isButtonLoading">
                    <span class="btn-icon loader-spin" v-if="isButtonLoading"></span>
                </button>
            </div>
        </div>
    </div> -->

    <!-- 二维码图片 -->
    <div class="flex-col-center flex-gap20 qr-box show-qr" v-show="isQRCodeValid && !isQRCodeExpired">
        <div class="tile-card pay-card">
            <img v-if="false && xddData" :src="xddData.qr_img" class="qr" alt="Qr Code" />
            <img v-if="xddData" :src="orderData.InlineQRCodeAlipay" class="qr" alt="Qr Code" />
        </div>
        <div class="qr-label-tx">长按保存二维码到本地</div>
    </div>
    
    <!-- 刷新二维码 -->
    <div class="flex-col-center flex-gap20 qr-box" v-show="isQRCodeExpired">
        <div class="tile-card pay-card">
            <div class="overlay-box">
                <button class="button-g-3d button-normal" :class="{'button-loading': isButtonLoading}"   :disabled="isButtonLoading" @click="makePayment">
                    <div class="ctrl">
                        <icon name="Loading-Circle" v-if="isButtonLoading" />
                        刷新二维码
                    </div>
                </button>
            </div>
            <img src="/qr/fake-qr.png" class="qr disable" alt="Qr Code" />
        </div>
    </div>

    <!-- 支付成功，系统错误信息 -->
    <div v-show="isPaymentSuccess" class="g-tip">
        支付成功，准备跳转到首页...
    </div>
    
    <!-- <div v-if="serverError" class="g-tip">
        {{serverError}}
    </div> -->

    <!-- 待支付信息 -->
    <!-- <div className="pay-info" v-if="!isPaymentSuccess">
        <p className="tx-a">
            <span>待付</span>

            <span v-if="!xddData" className="price">{{amount}}元</span>
            <span v-else className="price">{{xddData?.realmoney}}元</span>
        </p>
        <p className="tx-b">
            <span>（已优惠 {{discountAmount}} 元<span v-show="isQRCodeValid">，请在{{countdownStr}}内完成支付</span>）</span>
        </p>
        <div class="tx-b" style="font-size: small; color: #888;">如果付款后超过 5 分钟仍未到账，请联系管理员（微信号 chatboy666）处理</div>
        <div className="g-label label-small">{{qty}} 个月「{{product.Name}}」</div>
    </div> -->

    
    
    <div v-if="false && xddData && !isPaymentSuccess" class="flex flex-col items-center">
        <div>
            <img :src="xddData.qr_img"  />
        </div>
        <div>金额: ￥{{xddData.realmoney}}</div>
        <div>请使用支付宝扫码付款</div>
        <div class="text-red-500">请在 5 分钟之内付款，否则二维码会失效</div>
        <div class="text-gray-400">支付宝显示的收款人为个人账户，请放心支付</div>

        <div>如果你在手机端支付，请保存二维码图片到本地相册之后，在支付宝扫码页面选择相册图片</div>

        <div>如果付款后超过 5 分钟仍未到账，请联系管理员（微信号 chatboy666）处理</div>
    </div>


    <div class="tile-card pay-card generate-qr" v-if="!xddData">
        <div class="button-g-3d button-normal" :class="{'button-loading': isLoading}" @click="makePayment">
            
            <div class="ctrl">
                <icon name="Loading-Circle" />
                生成付款二维码
            </div>
        </div>
    </div>
    
</template>