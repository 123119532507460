<script setup lang="ts">
// <!-- 我的提现记录 -->
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import {apiFetch, time2Readable} from "../../common"
import {notifyError, notifySuccess} from "@/common2"
import {useRedDot, useAccount} from "@/store"
import {storeToRefs} from "pinia"
import {debounce} from "@/debounce"

const list = ref(<any>[])
const error = ref("")

const TakingList = ref(new Set<number>())
const { pendingBonus} = storeToRefs(useRedDot())

onMounted(() => {
    loadList()
})


async function loadList() {
  try {
    error.value = ""
    let j = await apiFetch("/goapi/bonus/list", {})
    // console.log("载入了 withdrawal 信息", j)
    list.value = j

    resetRedDot()
  } catch (e) {
    console.warn("载入 奖励列表 信息失败", e)
    error.value = (e as Error).message
  }
}


async function resetRedDot() {
    await apiFetch("/goapi/reddot/reset", {
        method: "post",
        body: JSON.stringify({tag: "pending_bonus"})
    })
    pendingBonus.value = 0
}



async function take(id :number, isConfirm = false){ 
    try {
        TakingList.value.add(id)
        let j = await apiFetch(`/goapi/bonus/take/${id}?is_confirm=${isConfirm ? 1 : 0}`, {
            method: "post",
        })

        if (j.is_need_confirm) {
            const ret = confirm("你已经订购了其他套餐，领取这个奖励会覆盖你当前的套餐，是否确认领取？")
            if (ret) {
                await take(id, true)
            } else {
                // 什么都不用做
            }
        } else {
            notifySuccess("领取成功")

            // 在本地修改已经领取的奖励的记录
            for (let i = 0 ; i < list.value.bonus.length ; i++) {
                if (list.value.bonus[i].ID == id) {
                    list.value.bonus[i] = j.bonus
                    break
                }
            }

            debounce("after_bonus_taken", 1000, () => {useAccount().reload()})
        }

    } catch (e) {
        console.warn("领取奖励失败", e)
        notifyError("领取失败. " +  (e as Error).message)
    } finally {
        TakingList.value.delete(id)
    }
}





</script>

<template>
    <div class="list">
        <ul class="list-ctrl">
            
                <li class="row title-row">
                    <p class="tile">奖励来源</p>
                    <p class="tile">奖励内容</p>
                    <p class="tile"></p>
                </li>

                <li class="row" v-for="b in list.bonus">
                    <p class="tile flex-col-left">
                        <span>{{b.Remark}}</span>
                        <span style="color: #888;">{{time2Readable(b.CreateTime)}}</span>
                    </p>
                    <p class="tile flex-col-left">
                        <span>{{b.ChargeToken}} 点数</span>
                        <span>{{b.ChargeTime}} 时长</span>
                    </p>
                    <p class="tile">
                        <div v-if="b.TakeTime == 0 && !TakingList.has(b.ID)" class="button-g button-small button-fill-purple" @click="take(b.ID)">领取</div>
                        <div v-if="TakingList.has(b.ID)" class="button-g button-small" @click="take(b.ID)">
                            正在领取
                        </div>
                        <div v-if="b.TakeTime > 0" class="button-g button-small" @click="take(b.ID)">已领取</div>
                    </p>
                </li>
                
                <!--为空状态-->
                <li class="row" v-if="list?.bonus?.length == 0">
                  <div class="tip-info">
                      <icon name="Info-Circle" />
                      <p class="tx">无记录</p>
                  </div>
                </li>

            </ul>
    </div>
</template>

