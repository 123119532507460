import gsap from "gsap"
import CustomEase from "gsap/CustomEase"

gsap.registerPlugin(CustomEase)

export function modalSlideDownIn(el :any, done :any) {
    gsap.from(el, {
        translateY: '-100%',
        ease: 'expo',
        onComplete: done,
    })
}

export function modalSlideDownOut(el :any, done :any) {
    gsap.to(el, {
        translateY: '-100%',
        ease: 'expo',
        onComplete: done
    })
}

export function slideDownIn(el :any, done :any) {
    try {
        gsap.set(el, {
            height: 0,
            overflow: "hidden",
        })
        gsap.to(el, {
            height: "auto",
            ease: 'expo',
            onComplete: () => {
               gsap.set(el, {
                    overflow: "visible",
                    onComplete: done,
                })
            },
        })
        
    } catch (e) {
        console.log("slideDownIn 动画失败", e)
    }
}

export function slideDownOut(el :any, done :any) {
    gsap.set(el, {
        overflow: "hidden",
    })
    gsap.to(el, {
        height: 0,
        paddingBottom: 0,
        paddingTop: 0,
        marginTop: 0,
        marginBottom: 0,
        ease: 'expo',
        onComplete: done,
    })
    
}

export function slideUpIn(el :any, done :any) {
    gsap.from(el, {
        translateY: '100%',
        ease: 'expo',
        onComplete: done,
    })
}


export function slideLeftIn(el :any, done :any) {
    gsap.fromTo(el, {
        x: "-100%",
    },{
        x: "0",
        ease: 'expo',
        onComplete: done,
    })
}

export function slideLeftOut(el :any, done :any) {
    gsap.to(el, {
        x: "-100%",
        ease: 'expo',
        onComplete: done,
        // duration: 10,
    })
}


export function slideRightIn(el :any, done :any) {
    gsap.from(el, {
        x: "100%",
        ease: 'expo',
        onComplete: done,
    })
}

export function slideRightOut(el :any, done :any) {
    gsap.to(el, {
        x: "100%",
        ease: 'expo',
        onComplete: function() {
            gsap.set(el, {
                display: "none",
            })
            done()
        }
    })
}




export function slideUpOut(el :any, done :any) {
    gsap.to(el, {
        translateY: '100%',
        ease: 'expo',
        onComplete: done
    })
}

export function mobileModalFadeIn(el :any, done :any) {
    gsap.from(el, {
        opacity: 0,
        onComplete: done,
    })
}
export function mobileModalFadeOut(el :any, done :any) {
    gsap.to(el, {
        opacity: 0,
        onComplete: done,
    })
}

export function fadeIn(el :any, done :any) {
    gsap.set(el, { opacity: 1 })
    gsap.from(el, {
        opacity: 0,
        onComplete: done,
    })
}
export function fadeOut(el :any, done :any) {
    gsap.to(el, {
        opacity: 0,
        onComplete: done,
    })
}

export function foldIn(el :any, done :any) {
    gsap.from(el, {
        height: 0,
        onComplete: done,
        duration: 0.3,
    })
}

export function foldOut(el :any, done :any) {
    gsap.to(el, {
        height: 0,
        onComplete: done,
        duration: 0.3,
    })
}


// 删除时的擦除效果
export function eraseOut(el :any, done: any) {
    gsap.to(el, {
        height: 0,
        opacity: 0.1,
        filter: "blur(150px)",
        duration: 0.3,
        onComplete: done,
    })
}

export function toolCardIn(el :any, done :any) {
    // 计算一个随机延迟    
    let delay = el.dataset.index * 0.05
    if (el.dataset.index > 10) {
        delay = Math.random() * 0.1 + 0.5
    }

    gsap.to(el, {
        translateY: "0%",
        duration: Math.random() * 0.5 + 0.1 ,
        ease: 'expo',
        opacity: 1,
        delay,
        onComplete: done,
    })
    
}


export function voiceCancelIn(el :any, done: any) {
    gsap.to(el, {
        translateY: "-6.8rem",
        opacity: 1,
        duration: 0.12,
        ease: CustomEase.create("custom", "M0,0 C0.07,0.551 0.492,1 1,1 "),
    })
}
export function voiceCancelOut(el :any, done: any) {
    gsap.to(el, {
        translateY: "0",
        opacity: 0,
        duration: 0.08,
        ease: CustomEase.create("custom", "M0,0 C0.07,0.551 0.492,1 1,1 "),
        onComplete: done,
    })
}


export function toolCardBeforeIn(el :any) {
    gsap.set(el, {
        translateY: "20%",
        opacity: 0,
    })
}


const errorEase = CustomEase.create("errorEase", "M0,0 C0.012,0 0.025,0.998 0.05,0.998 0.1,0.998 0.1,-0.992 0.15,-0.992 0.2,-0.992 0.2,0.967 0.25,0.967 0.3,0.967 0.3,-0.919 0.35,-0.919 0.399,-0.919 0.399,0.838 0.449,0.838 0.499,0.838 0.499,-0.713 0.549,-0.713 0.6,-0.713 0.599,0.527 0.649,0.527 0.7,0.527 0.699,-0.299 0.749,-0.299 0.799,-0.299 0.799,0.116 0.849,0.116 0.899,0.116 0.899,-0.024 0.949,-0.024 0.974,-0.024 0.974,0 1,0")
export function errorIn(el :any, done: any) {
    gsap.set(el, {
        translateX: "1em",
    })
    gsap.from(el, {
        ease: errorEase,
        translateX: 0,
        duration: 0.5,
        onComplete: done,
    })
}


interface dotBurstOptions {
    radius?: number
    duration?: number
    dotCount?: number
    dotDiameter?: number
}

// 粒子烟花动画
// 目前用在领取每日任务奖励按下领取按钮时
export function dotsBurst(el :HTMLElement | string, opts ?:dotBurstOptions) {
    // 最大爆炸半径
    const radius :number = opts?.radius || 60
    // 爆炸时间（秒）
    const duration: number = opts?.duration || 1.2
    // 粒子数量
    const dotCount: number = opts?.dotCount || 20
    // 粒子直径
    const dotDiameter: number = opts?.dotDiameter || 10

    var target: HTMLElement | null
    if (typeof(el) == typeof("s")) {
        target = document.querySelector(el as string);
    } else {
        target = el as HTMLElement
    }
    if (!target) {
        console.warn("指定的 HTML 对象不存在，无法应用粒子烟花效果", el)
        return
    }

    
    let rect = target.getBoundingClientRect()

    var dom = document.createElement('div');
    dom.style.position = "absolute"
    
    dom.style.left = (rect.x + rect.width / 2) + "px"
    dom.style.top = (rect.y + rect.height / 2) + "px"
    dom.style.zIndex = "999999"
    document.body.appendChild(dom)

    
  
    for (let i = 0; i < dotCount; i++) {
        let theta = Math.random() * 2 * Math.PI
        // let r = 90 * (1 - Math.random() * 0.55)         // 爆炸半径为 0.5~1
        let r = radius * Math.random()
        let x = Math.cos(theta) * r;
        let y = Math.sin(theta) * r;
        let color = "#" + Math.random().toString(16).slice(-6);
        // let toColor = "#" + Math.random().toString(16).slice(-6);
        
        
        let rand = Math.random() * 3

        let el = document.createElement("div")
        el.style.position = "absolute"
        el.style.top = `-${dotDiameter}px`
        el.style.left = `-${dotDiameter}px`
        
        if (rand < 1) {
            // 圆形
            el.style.borderRadius = "50%"
            el.style.border = `${dotDiameter}px solid ${color}`
        } else if (rand < 2) {
            // 方形
            el.style.border = `${dotDiameter}px solid ${color}`
        } else if (rand < 3) {
            // 三角形
            el.style.borderLeft = `${dotDiameter}px solid transparent`
            el.style.borderRight = `${dotDiameter}px solid transparent`
            el.style.borderBottom = `${dotDiameter*2}px solid ${color}`
        }
        
        dom?.append(el)
        gsap.to(el, {
            duration: duration,
            delay: 0.01,
            x: `${x}px`,
            y: `${y}px`,
            // backgroundColor: toColor,
            // opacity: 0,
            scale: 0,
            rotate: Math.random() * 720 + 180,
            ease: "circ.out",
            onComplete: () => {
                dom?.removeChild(el)
            }
        })
        gsap.to(el, {
            opacity: 0,
            duration: duration,
            ease: "power1.out",
        })

        setTimeout(() => {
            dom.remove()
        }, duration * 1000 + 1000)
    }

}



// 每日任务 Emoji 图标旋转缩小，然后弹出
export function animationEmojiSpin(el :any) {
    let duration = 0.3
    let tl = gsap.timeline()
    tl.to(el, {
        rotate: 1080,
        scale: 0.4,
        ease: "power1.out",
    })
    tl.to(el, {
        scale: 1.5,
        ease: "power1.in",
        duration: 0.2,
    })
    tl.to(el, {
        scale: 1,
    })
    tl.set(el, {
        rotate: 0,
    })
}


// AI 画图的垫图删除动画
export function refImageLeave(el :any, done: any) {
    const tl = gsap.timeline({
        onComplete: done,
    })
    tl.to(el, {
        translateY: "-100%",
        duration: 0.2,
    })
    tl.to(el, {
        width: 0,
        duration: 0.2,
    })
}

export function refImageEnter(el :any, done: any) {
    gsap.from(el, {
        width: 0,
        onComplete: done,
    })
}