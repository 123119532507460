import {apiFetch} from "@/common"

export async function getProfile() {
    try {
        let j = await apiFetch("/goapi/current_account")
        return j
    } catch(e) {
        console.warn("加载个人信息出错: ", e)
    } 

    return null
}