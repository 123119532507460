

// 设置本地存储的命名空间的名字
export function setNamespace(name :string) :void {
    // if (!name){ 
    //     console.warn("传入的 namespace 是空的", name)
    //     return
    // }
    localStorage.setItem("account_namespace", name)
}


// 获取本地存储的命名空间名字
export function getNamespace() :string {
    let ret = localStorage.getItem("account_namespace")
    if (!ret) {
        return ""
    } else {
        return ret
    }
}