<script setup lang="ts">
// <!-- 我邀请的用户 -->
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import {apiFetch, time2Readable} from "../../common"
import {mobileModalFadeIn, mobileModalFadeOut, slideDownIn, slideUpIn, slideUpOut} from "@/animation"

const list = ref(<any>[])
const error = ref("")

onMounted(() => {
    loadInvite()
})


async function loadInvite() {
  try {
    error.value = ""
    let j = await apiFetch("/goapi/invite/list", {})
    // console.log("载入了 invite 信息", j)
    list.value = j
  } catch (e) {
    console.warn("载入 invite 信息失败", e)
    error.value = (e as Error).message
  }
}




</script>

<template>
    <div class="list">
        <ul class="list-ctrl">
            <li class="row title-row">
                <p class="tile">订阅时间</p>
                <p class="tile">邀请获利</p>
            </li>
            <li class="row" v-for="log in list.logs">
                <!-- <p>Lv1 会员订阅（一个月）</p> -->
                <p class="tile">{{time2Readable(log.CreateTime)}}</p>
                <p class="tile">{{log.Amount}} 元</p>
            </li>

            <!--为空状态-->
            <li class="row" v-if="list?.logs?.length == 0">
                  <div class="tip-info">
                      <icon name="Info-Circle" />
                      <p class="tx">无记录</p>
                  </div>
            </li>
        </ul>
    </div>

</template>

