<script setup lang="ts">
import {apiFetch} from "../common"
import {ref} from "vue"
import { computed } from "@vue/reactivity"
import {useAccount} from "@/store"
import MobileModal from "@/comps/MobileModal.vue"


const code = ref("")
const error = ref("")
const success = ref("")
const isLoading = ref(false)

const isNeedConfirm = ref(false)
const isConfirm = ref(false)
const currentProduct = ref(<any>{})

async function use() {
    try {
        isLoading.value = true
        error.value = ""
        success.value = ""

        let j = await apiFetch("/goapi/use_code", {
            method: "post",
            body: JSON.stringify({
                "code": code.value,
                "is_confirm": isConfirm.value,
            })
        })

        console.log(j)
        if (j?.NeedConfirm === true) {
            // 需要确认才能使用
            isNeedConfirm.value = true
            currentProduct.value = j.CurrentProduct
            return
        }

        success.value = `兑换码使用成功。${j.code.Product.Name}`
        isNeedConfirm.value = false

        useAccount().reload()

        loadHistory()
    } catch (e) {
        error.value = (e as Error).message 
        isNeedConfirm.value = false
    } finally {
        isLoading.value = false
        isConfirm.value = false
    }
}

async function confirmUse() {
    isConfirm.value = true
    await use()
}

async function loadHistory() {

}

</script>

<template>

    <form class="flex-col-left flex-gap28 popup-redeem" @submit.prevent="use">
        <div class="flex-col-center flex-gap20 padding-top-20 padding-bottom-28 g-gapline width100">
            <div class="form-input-ctrl input-medium width100">
                <input type="text" v-model="code" placeholder='兑换码' :class="{'disable': isNeedConfirm}" :disabled="isNeedConfirm" />
            </div>
            <p class="form-label g-label-warn width-fit" v-if="error">{{error}}</p>
            <p class="form-label g-label-success width-fit" v-if="success">{{success}}</p>
        </div>

        <div class="flex-col-center flex-gap28 width100" :class="{'hidden': isNeedConfirm}">
            <div class="font14">
                ※兑换码使用后，可在「
                <router-link class="link-text-nude-line" to="/shop">商店-购买套餐-购买历史</router-link>
                」页面查看兑换的历史记录。
            </div>
            <button type="submit" class="button-g-3d button-medium width120 button-g-3d-redeem" :class="{'has-icon': isLoading, 'button-loading': isLoading}" v-if="!success">
                <div class="ctrl">
                    <icon name="Loading-Circle" />            
                    提交兑换
                </div>
            </button>
            
        </div>
    </form>

    <form @submit.prevent>
        <div class="g-info-label g-label-warn font14" v-if="isNeedConfirm">
            <p class="tx">注意：您当前已经订阅了「{{currentProduct?.Name}}」会员，如果继续使用此兑换码将会覆盖当前已经订阅的会员（即当前订阅会立即失效），如果确认兑换请点击「提交兑换」。</p>
        </div>

        <div class="flex-col-center flex-gap28 width100" v-if="isNeedConfirm">
            <div class="font16 weight500">真的确认兑换么？</div>
            <div class="flex-row-left flex-gap16">
                <button class="button-g-3d button-medium width120 button-g-3d-redeem button-sp-purple">
                    <div class="ctrl" @click="isNeedConfirm=false">
                        放弃
                    </div>
                </button>
                <button class="button-g-3d button-medium width160 button-g-3d-redeem"
                    :class="{'has-icon': isLoading, 'button-loading': isLoading}"
                    @click="confirmUse"
                >
                    <div class="ctrl">
                        <icon name="Loading-Circle" />            
                        确认兑换
                    </div>
                </button>
            </div>
        </div>
    </form>

   <!-- <Transition name="popup"> -->
   <!-- <Component :is="MobileModal" :isShow="true" :hideOKButton="true" v-if="true || isNeedConfirm" @close="isNeedConfirm=false" title="确认覆盖当前订阅"> -->
        <button @click="confirmUse" class="button-type-a button-confirm-submit-redeemcode" :class="{'has-icon': isLoading}" :disabled="isLoading">
            <span class="btn-icon loader-spin" v-if="isLoading">确认</span>
        </button>
    <!-- </Component> -->
    <!-- </Transition>   -->

</template>
