<script setup lang="ts">
import {onMounted, ref, computed, onUnmounted} from "vue"
import {getProfile} from "@/apis"
import MobileModal from '@/comps/MobileModal.vue'
import InviteInfo from '@/comps/InviteInfo.vue'
import {time2Readable} from "@/common"
import {tokenToReadable} from "@/common2"
import Barn from "barn"
import bus from "@/bus"
import gsap from "gsap"
import {storeToRefs} from "pinia"
import {useAccount, useRedDot} from "@/store"

const isShowInvite = ref(false)
const session = new Barn(sessionStorage)
const {account, cached: cachedAccount} = storeToRefs((useAccount()))

const { pendingBonus} = storeToRefs(useRedDot())

// Token 信息的选择器
const qsToken :string = "cb-leftbottombox-token"


// const profile = ref(<any>null)
const profile = account

// 用于显示的 profile，在 profile 尚未加载成功时，会使用 displayProfile 数据代替
const displayProfile = computed(() =>{ 
    if (profile.value) {
        return profile.value
    } else {
        return cachedAccount.value
    }
})

onMounted(() => {
    bus.on("ws.token_consume", onTokenConsume)
    // profile.value = session.get("cb.profile")
    // loadProfile()

    // setInterval(() => onTokenConsume({TokenConsume: 234}), 300)
})

onUnmounted(() => {
    bus.off("ws.token_consume", onTokenConsume)
})


function onTokenConsume(data :any) {
    console.log("处理 ws.token_consume", data)

    let d = data.Data

    let dom = document.getElementById(qsToken)
    if (!dom) {
        return
    }

    try {
        let tc :string = ""
        let className :string = "token-delta-popup"

        if (!d.TokenDelta) {
            return
        }
        if (d?.TokenDelta > 0) {
            tc = "+" + d.TokenDelta.toString()
            className += " incr"
        } else {
            tc = d.TokenDelta.toString()
            className += " decr"
        }
        
        
        let rect = dom.getBoundingClientRect()
        let pop = document.createElement("span")
        pop.style.left = (rect.x + rect.width / 2) + "px"
        pop.style.top = rect.y + "px"
        pop.setAttribute("class", className)
        pop.innerText = tc
        document.body.append(pop)

        gsap.to(pop, {
            translateY: "-3em",
            opacity: 0,
            duration: 1,
            onComplete: () => {
                pop.remove()
            }
        })

        if (profile.value) {
            profile.value.account.token_used = d.TokenUsed
            profile.value.account.token_limit = d.TokenLimit
        }

        // console.log("测量结果", dom.offsetTop, dom.offsetLeft)
    }catch (e) {
        console.warn(e)
    }
}

// async function loadProfile() {
//     // 如果正在加载中，则跳过
//     if (isProfileLoading.value) {
//         return
//     }

//     // // 如果已经加载过，则不重新加载
//     // if (profile.value) {
//     //     return 
//     // }

//     isProfileLoading.value = true
//     try {
//         profile.value = await getProfile()
//         // console.log("profile 的值是", profile.value)
//         session.set("cb.profile", profile.value)
//     } catch  (e) {
//     } finally {
//         isProfileLoading.value = false
//     }
// }


const remainSeconds = computed(() :number => {
    let expireTime = 0
    if (profile.value) {
        expireTime = profile.value?.account?.expire_time ?? 0
    }
    let ts = expireTime - (new Date()).getTime() / 1000 

    if (ts < 0) {
        ts = 0
    }

    return ts
})

const remainDays = computed(() :number => {
    return remainSeconds.value / 86400
})

const tokenUsedPercent = computed(() :number => {
    let ret = 0

    ret = displayProfile.value?.account?.token_used / displayProfile.value?.account?.token_limit

    if (isNaN(ret)) {
        return 0
    }

    return ret
})

</script>

<template>
<div>
    <!-- <button @click="onTokenConsume">测量</button> -->

    <!-- 文件箱和邀请奖励-->
    <div class="section section-tools">
        <div class="section-sub">
            <!--隐藏文件箱功能
            <a href="" class="sub-tab-d">
                <div class="info-main">
                    <icon name="Box-Open" />
                    <p class="title">文件箱</p>
                </div>
                <div class="info-sub">
                </div>
            </a>
            -->
            <a class="sub-tab-e" @click.prevent="isShowInvite = true">
                <div class="info-main" >
                    <icon name="User-More" />
                    <p class="title" :class="{'reddot': pendingBonus}" >邀请奖励</p>
                </div>
                <div class="info-sub">
                </div>
            </a>
        </div>
    </div>

    <!-- 订阅信息 -->
    <div class="section section-subscription">
        <!-- 有订阅信息的情况 -->
        <div class="section-subscription-info" v-if="displayProfile?.current_product?.ID">
            
            <div>
                <p class="font14">🌟 {{displayProfile.current_product?.Name}}</p>
                <router-link v-if="displayProfile.current_product?.Name != ''" to="/shop" class="font13 link-text-nude-line">续费</router-link>
                <router-link v-else to="/shop" class="font13 link-text-nude-line">购买套餐</router-link>
            </div>

            <div class="progress-bar-sub">
                <div class="bar" :style="{'width': (tokenUsedPercent * 100) + '%'}"></div>
            </div>

            <div class="font13 color-black60"
                :title="'到期时间：' + time2Readable(displayProfile.account.expire_time, '无')"
            >

                <p v-if="remainDays <= 0">已到期</p>
                <p v-else-if="remainDays<1">今天到期</p>
                <p v-else-if="remainDays<1000">{{remainDays.toFixed(0)}}天后到期</p>
                <p v-else>∞</p>

                <p class="flex flex-gap4" :title="'点数已使用 ' + (tokenUsedPercent * 100).toFixed(0) + '%'" :id="qsToken">
                    <span>{{tokenToReadable(displayProfile.account.token_used)}}</span> 
                    <span>/</span>
                    <span>{{tokenToReadable(remainDays <= 0 ? 0 : displayProfile.account.token_limit)}}</span>
                    <span>点</span>
                </p>
            </div>
        </div>

        <!-- 无订阅信息的情况 -->
        <div class="section-subscription-info" v-if="!displayProfile?.current_product?.ID">
            <div>
                <p class="font14">👀 当前无订阅</p>
                <router-link to="/shop" class="font13 link-text-nude-line">去购买</router-link>
            </div>
            <div class="progress-bar-sub"></div>
            <div class="font13 color-black60">
                <p>全年订阅特惠中</p>
                <p class="flex">
                    <span>最大18% OFF</span>
                </p>
            </div>
        </div>
    </div>



    <!-- 底部弹窗，邀请奖励 -->
    <Transition name="modal">
    <Component :is="MobileModal" :isShow="isShowInvite" @close="isShowInvite = false" :hideOKButton="true"
        title="邀请奖励" icon="User-More"
    >
        <Component :is="InviteInfo" @close="isShowInvite=false" :profile="profile" />
    </Component>
    </Transition>

        

</div>
</template>

<style>
.token-delta-popup {
    position: absolute;
    font-weight: bold;
    font-family: monospace;
    /* background-color: ; */
    /* font-size: large; */
}
.token-delta-popup.incr {
    color: blue;
}
.token-delta-popup.decr {
    color: red;
}
</style>