<script setup lang="ts">
import {useModalState} from "@/store"
import {storeToRefs} from "pinia"

const {isShowMobileMenu} = storeToRefs(useModalState())

const props = defineProps<{
    title? :string
}>()

</script>
<template>

    <!--移动端的Page Header-->
    <div class="mobile-pager-header"> 
        <div class="left-part">
            <router-link to="/chat" class="nav-tile">
                <icon name="Conversation" />
            </router-link>
        </div>
        <div class="center-part">
            <h2 class="title">{{props.title}}</h2>
        </div>
        <div class="right-part">
            <a class="nav-tile" @click.prevent="isShowMobileMenu=true">
                <icon name="Menu-Change" />
            </a>
        </div>
    </div>


</template>