import {getAutoIncrement, safeIncr} from "./common2"
import type {Chat, ChatMessage, ChatMeta, ChatMetaMap} from "@/types"
import {NewChat} from "@/types"
import {CBStore} from "@/cbstore"
import {getNamespace} from "@/namespace"



// 删除指定的消息
export function deleteMessage(chat :Chat, msgID :number) :Chat {
    for (let i = 0; i < chat.messages.length; i++) {
        if (chat.messages[i].id == msgID) {
            chat.messages.splice(i, 1)
            chat.sync_version = safeIncr(chat.sync_version)
            break
        }
    }

    return chat
}


const CBStoreMap = new Map<string, CBStore>()
// const foo = new CBStore("1")
function store() :CBStore {
    // return foo
    const ns = getNamespace()
    if (CBStoreMap.has(ns)) {
        return CBStoreMap.get(ns) as CBStore
    } else {
        const s = new CBStore(ns)
        CBStoreMap.set(ns, s)
        return s
    }
}


class ChatStorage {
    constructor() {
    }

    // 将聊天 id 转换为 localStorage 存储时使用的 key
    id2Key(id :number) :string {
        return "chat." + id.toString()
    }

    create() :Chat {
        let chat = NewChat()
        chat.id = (new Date()).getTime()
        chat.subject = "新的对话 #" + getAutoIncrement("local_chat").toString()
        return chat
        
        // return {
        //     "id": (new Date()).getTime(),
        //     // "model": "REPEATER",
        //     "subject": "新的对话 #" + getAutoIncrement("local_chat").toString(),
        //     "messages": [],
        //     "preset_id": 0,

        //     "is_synced": false,
        //     "sync_version": 0,

        //     "is_subject_maked": false,
        // } 
    }


    // 获取当前会话列表
    async listAll() :Promise<number[]> {
        let raw = await store().get("chat.index")
        if (!raw) {
            raw = []
        }
        let j = raw as number[]

        // 过滤无效的内容
        j = j.filter(x => x)

        return new Promise((y, n) => y(j))
    }

    // 将一个会话添加到会话列表中
    async listAdd(id :number) {
        let j = await this.listAll()

        if (j.indexOf(id) >= 0) {
            return
        }

        j.push(id)
        j.sort((a, b) => a - b)

        // 过滤无效的内容
        j = j.filter(x => x)

        await store().set("chat.index", j)
    }

    // 从会话列表中删除一个会话
    async listRemove(id :number) {
        let j = await this.listAll()
        // if (j.indexOf(id) >= 0) {
        //     return
        // }


        j = j.filter(x => {
            return x !== id
        })     

        await store().set("chat.index", j)

        console.debug("从会话列表中删除了一个会话", id)
    }

}

export class LocalChatStorage extends ChatStorage {
    // async append(id :number, message :ChatMessage) {
    //     let m = this.get(id)
    //     if (!m) {
    //         m = this.create()
    //     }

    //     m.messages.push(message)
        
    //     await store().set(this.id2Key(id), m)
    //     this.listAdd(id)
    // }

    async get(id :number) :Promise<Chat | undefined> {
        let raw = await store().get("chat." + id.toString())
        if (!raw) {
            // console.warn("未能取到 chat 数据, chat.id = ", id)
            return new Promise((y, n) => y(undefined))
        }

        // console.log("取到 raw 数据 ", raw)

        return new Promise((resolve, reject) => resolve(raw))
    }

    // 保存一个会话数据
    async save(chat :Chat) {
        console.debug("[chat_storage]保存对话数据到本地", chat.id)

        // 修复历史遗留问题：更改了聊天结构之后，会缺少部分字段，为了避免这些情况，我们需要重建这些数据
        chat = this.fixChat(chat)

        console.log("[chat_storage]保存会话数据", chat)
        await store().set("chat." + chat.id, chat)
        await this.saveMeta(<ChatMeta>{id: chat.id, subject: chat.subject})
        console.log("[chat_storage]保存后读取数据是", await store().get("chat." + chat.id))
    }

    // 根据会话编号删除一个会话
    async delete(chatID :number) {
        const s = store()

        // 1. 删除会话本体
        await s.remove("chat." + chatID)

        // 2. 从列表中删除该会话
        await this.listRemove(chatID)

        // 3. 删除元信息
        await this.removeMeta(chatID)
    }


    // # ChatMeta 相关数据开始
    // ChatMeta 是一个大的 Map 结构，用来缓存需要在聊天列表中展示的消息
    // 保存 meta 数据
    async saveMeta(chat :ChatMeta, extra :any = {}) {
        let m :ChatMetaMap = await this.getMetas()

        m[chat.id] = {
            id :chat.id,
            subject :chat.subject,
            ordering: chat?.ordering || chat.id,
        }

        await store().set("chat.metas", m)
    }

    // 删除一个 meta 数据
    async removeMeta(chatID :number) {
        let m :ChatMetaMap = await this.getMetas()

        delete m[chatID]

        await store().set("chat.metas", m)
    }

    // 获取 Meta 数据
    async getMetas() :Promise<ChatMetaMap> {
        let m :ChatMetaMap = await store().get("chat.metas")
        if (!m) {
            m = {} as ChatMetaMap
        }

        return m
    }



    // 修复历史遗留问题：更改了聊天结构之后，会缺少部分字段，为了避免这些情况，我们需要重建这些数据
    fixChat(chat :Chat) :Chat {
        for (let i = 0; i < chat.messages.length; i++) {
            let v = chat.messages[i]
            if (!v.id) {
                chat.messages[i].id = getAutoIncrement("message")
            }
        }

        return chat
    }
}
