<script setup lang="ts">
import {ref, computed, onMounted, watch} from "vue"
import {apiFetch, writeClipboard, apiURL} from "@/common"
// import {writeClipboard} from "@/common"
import {notify} from "@kyvg/vue3-notification"
import {storeToRefs} from "pinia"
import {dotsBurst, animationEmojiSpin, slideDownIn, slideDownOut} from "@/animation"
import {useDaily, useAccount, useModalState} from "@/store"

const {d, points} = storeToRefs(useDaily())
const daily = d

const isShowRule = ref(false)

// 是否正在兑换点数
const isChanging = ref(false)

const isShowShareLink = storeToRefs(useModalState()).isShowDailyShareLink

const shareLink = ref("")

onMounted(() => {
    loadDaily()
    loadShareLink()
})

function onClickEmoji(e :any) {
    animationEmojiSpin(e.target)
}


function toEmoji(d :string) {
    if (d == "签到") {
        return "🛎️"
    } else if (d == "分享链接") {
        return "🔗"
    } else if (d == "分享链接被点击") {
        return "👏"
    } else if (d == "每日任务") {
        return "💖"
    } else {
        return "🎯"
    }
}

// 用户点击“去分享链接”
function goShare() {
    // isShowShareLink.value = true
    copy()
}


async function loadShareLink() {
    try {
        let j = await apiFetch("/goapi/daily/share_link")
        if (j.RelativeURL) {
            shareLink.value = j.RelativeURL
        } else {
            console.warn("API 返回的数据是", j)
            throw Error("API 返回的数据不正确")
        }
    } catch(e) {
        notify({type: "error", text: "获取分享链接失败"})
        console.warn(e)
    }    
}

function copy() {
    writeClipboard(shareText.value)
    notify({type: "success", text: "分享链接已复制到剪贴板", duration: 5 * 1000})
    emitShareLinkAction()
}

const shareText = computed(() => {
    if (shareLink.value == "") {
        return ""
    } else {
        return `我正在用 ChatBoy Ai，你也来试试吧 ` + apiURL(shareLink.value) + " "
    }
})


watch(isShowShareLink, (newVal, oldVal) => {
    if (newVal == false && oldVal == true) {
        console.debug("分享链接窗口由显示变为关闭")
        onCloseShare()
    }

})

// 关闭分享链接窗口后，认为链接已分享出去了
function onCloseShare() {
    emitShareLinkAction()
}
function emitShareLinkAction() {
    // 对于分享链接的 action, 服务端会延迟 10s 入库
    apiFetch("/goapi/daily/action", {
        method: "post",
        body: JSON.stringify({
            Action: "分享链接",
        })
    }).finally(() => {
        loadDaily()
    })
}


async function loadDaily() {
    useDaily().reload()
}

async function takeDaily(k :number) {
    if (!daily.value[k]) {
        return
    }

    daily.value[k].DailyLogID = -1    
    
    dotsBurst(`.daily-item:nth-child(${k+1}) .daily-item-action-button`)
    setTimeout(() => {
        animationEmojiSpin(`.daily-item:nth-child(${k+1}) .title-emoji`)
    }, 500)

    let j = apiFetch("/goapi/daily/take", {
        method: "post",
        body: JSON.stringify({
            ID: daily.value[k].Daily.ID,
        })
    }).then(() => {
        // notify({type: "success", text: "领取成功"})
    }).catch((e) => {
        console.warn(e)
        notify({type: "error", text: "领取失败，请刷新页面后重试"})
    }).finally(() => {
        loadDaily()
    })
}


async function changePoints() {
    try {
        isChanging.value = true
        let j = await apiFetch("/goapi/point/change", {
            method: "post",
        })
        notify({type: "success", text: `成功用积分兑换了 ${j.ChargeToken} 点数`, duration: 5 * 1000})
        points.value.Balance = 0    // 预先将积分数据设置为 0，避免网络延迟以至于本地仍显示旧的积分数据
        loadDaily()
        useAccount().reload()
    } catch(e) {
        console.warn("兑换积分失败", e)
    }finally {
        isChanging.value = false
    }
}

</script>
<template>
<div>
    <div v-if="!useAccount().a" style="text-align: center;">登录后才可以进行每日任务</div>
    <div v-else class="daily-task-list">
        <div class="info">
            <div class="list">

                <ul class="list-ctrl">
                    <li class="row daily-item" v-for="(d, k) in daily">
                        <div class="left-part flex-row-left flex-gap16">
                            <div class="title-emoji" >{{toEmoji(d.Daily.UniqueID)}}</div>
                            <div>
                                <div class="title">{{d.Daily.Name}}</div>
                                <div class="sub-title color-black60">{{d.Daily.Description}} / {{d.Daily.PointCount}} 积分</div>
                            </div>
                        </div>

                        <div class="right-part flex-row-right flex-gap12">

                            <!-- 操作按钮：例如：【去分享链接】-->
                            <div>
                                <div v-if="d.Daily.Name=='分享链接'" class="button-g button-small button-fill-black" :class="{'button-loading': shareLink==''}" @click="goShare">
                                    <icon name="Loading-Circle" />
                                    <icon name="Share-Arrow" />
                                    去分享
                                </div>
                            </div>

                            <div class="flex-row-left flex-gap8 daily-item-action-button">
                                <div class="button-g button-small button-no-ux" v-if="d.IsFinish == false">
                                    <icon name="Emoji-Puzzled" />
                                    未完成
                                </div>
                                <div class="button-g button-small button-fill-purple" v-if="d.IsFinish == true && d.DailyLogID == 0" @click="takeDaily(k)">
                                    <icon name="Gift" />
                                    领取奖励
                                </div>
                                <div class="button-g button-small button-disabled" v-if="d.IsFinish == true && d.DailyLogID != 0">已领取</div>
                            </div>

                        </div>

                    </li>


                    <li class="row">
                        <div class="left-part">

                            <div class="daily-point-info-sec">
                                <p>
                                    ※ 当前共有
                                    <span class="weight500 color-purple font-konnect font18">{{points.Balance?.toLocaleString() || 0}}</span>
                                    积分，可兑换为等额点数，查看
                                    <span><router-link to="/help?t=qa#HelpQA-81" class="color-black60 link-text-nude" @click="$emit('close')" >《兑换规则》</router-link></span>
                                </p>
                            </div>

                            <Transition @enter="slideDownIn" @leave="slideDownOut">
                            <div style="margin: 1em; color: #888;" v-if="isShowRule">
                                <ul style="margin:0;padding:0;">
                                    
                                </ul>
                            </div>   
                            </Transition> 
                        </div>

                        <div class="right-part">

                            <!-- 有积分时 -->
                            <div v-if="points.Balance>0" @click="changePoints" class="button-g button-small button-fill-purple" :class="{'button-loading': isChanging}">
                                <icon name="Loading-Circle" />
                                <span class="font-konnect weight500">Yes</span>
                                立即兑换
                            </div>
                            <!-- 无积分时 -->
                            <div v-else class="right-part">
                                <div class="button-g button-small button-disabled">无可用积分</div>
                            </div>
                        </div>
                    </li>


                </ul>

            </div>
        </div>
    </div>
</div>
</template>




<style scoped>
</style>