<script setup lang="ts">
import { onMounted, ref, defineEmits , onUpdated, computed, onUnmounted} from "vue"
import {getChatID, getQuery, newChat, deleteChat, notifyError, notifySuccess} from "../common2"
import DailyBonus from "@/comps/DailyBonus.vue"
import {LocalChatStorage} from "../chat_storage"
import type {ChatMeta} from "@/types"
import MobileHeader from "./MobileHeader.vue"
import LeftBottomBox from "@/comps/LeftBottomBox.vue"
import {getNamespace} from "@/namespace"
import debounce from "debounce"
import {useChatSideBar, useAccount} from "@/store"
import {storeToRefs} from "pinia"

import { apiFetch } from "@/common"


import {NewChat} from "@/types"

import {useRouter} from "vue-router"

import bus from "@/bus"

const emit = defineEmits(['selectChat', 'showSideBar', 'afterUpdate'])

// 魔法数字！如果 ordering 大于此数字，则认为这是置顶对话，否则是普通对话
// 将一个对话设为置顶，只需要将其 ordering + PIN_SPLIT 即可
// 同样，将一个对话取消置顶，将其 ordering 减去 PIN_SPLIT 即可
const PIN_SPLIT = Math.pow(10, 14) * 7

const chatList = ref(<ChatMeta[]>[])
const chatID = ref(0)

const chatListEl = ref()

const {isLoggedIn} = storeToRefs(useAccount())

const lcs = new LocalChatStorage()

const router = useRouter()

function createChat() {    
    newChat(router, "", 0)
}


// 是否处于调整对话顺序模式
const {isOrdering} = storeToRefs(useChatSideBar())


const orderingChangeSet = new Map<number, number>()


// 确认删除会话。当此变量非 0 时，表示该对话正在等待删除，需要显示一个“等待删除”的界面
const confirmDeleteChat = ref(0)


onMounted(async () => {
    bus.on(bus.聊天标题更新, onChatSubjectUpdate)
    bus.on(bus.刷新聊天列表, updateList)
    
    updateList()
    await mergeListFromCloud()
})
onUnmounted(async () => {
    bus.off(bus.聊天标题更新 , onChatSubjectUpdate)
    bus.off(bus.刷新聊天列表 , updateList)
    
})

onUpdated(() => {
    // 如果目前处于编辑模式下，则不需要自动滚动
    if (isOrdering.value) {
        return
    }

    scrollToActive()
})


// 保存当前的排序
async function saveOrder() {
    console.debug("将保存新的对话顺序", orderingChangeSet)
}


function onChatSubjectUpdate(d: any) {
    for (let c of chatList.value) {
        if (c.id == d.id) {
            c.subject = d.subject
        }
    }
    // console.log("触发聊天标题更新", d, chatList.value, chatList.value[d.id])
    // if (chatList.value[d.id]) {
    //     chatList.value[d.id].subject = d.subject
    // }
}

async function updateList() {
    console.debug("执行一次刷新聊天会话列表的操作")

    chatID.value = await getChatID()

    let ret = await lcs.listAll()
    // console.debug("取到的本地列表数据", ret)

    // console.time("加载聊天记录")
    let chats = <ChatMeta[]>[]

    // for (let i in ret) {
    //     let id = ret[i]
    //     let c = await lcs.get(id)
    //     if (!c) {
    //         console.warn(`聊天记录 ${id} 不存在，将其从列表中删除`)
    //         lcs.listRemove(id)
    //         continue
    //     }
    //     // console.timeLog("加载聊天记录")
    //     chats.push(c)
    // }
    // let metas = Object.values((await lcs.getMetas()))
    let metas = await lcs.getMetas()
    console.log("获得对话列表的 metas  ", getNamespace(), metas[1716877735897])

    // console.timeEnd("加载聊天记录")
    // console.log("AA 对话记录加载完成")

    chats = []
    for (let i = 0; i < ret.length; i++) {
        let id = ret[i]

        chats.push({
            subject: metas[id]?.subject || "无标题",
            ordering: metas[id]?.ordering || id,
            id: id,
        })
    }

    chats.sort((a, b) => a.ordering - b.ordering )

    // console.log("最后结果", metas, chats)
    chatList.value = chats

    emit('afterUpdate')
}

async function mergeListFromCloud() {
    let isMerged = false

    try {
        let j = await apiFetch(`/goapi/chat_sync/list`, {})
        // console.debug("获得的云端聊天列表是:", j)
        
        const metas = await lcs.getMetas()

        // 合并列表
        for (let i = 0; i < j.list.length; i++) {
            let dup = await lcs.get(j.list[i].ChatID)
            if (!dup) {
                console.debug("从云端获得了一个新的聊天", j.list[i].ChatID, j.list[i].Subject)
                let nc = NewChat()
                nc.id = j.list[i].ChatID
                nc.subject = j.list[i].Subject
                nc.preset_id = j.list[i].PresetID
                nc.is_synced = true
                nc.sync_version = 0
                
                await lcs.save(nc)
                await lcs.listAdd(nc.id)

                isMerged = true
                
                console.debug(`根据云端数据增加了一个本地对话 id=${nc.id}, subject=${nc.subject}`)
            } else {
                const chat_id = dup.id

                // 如果本地对话已经存在，则根据远程数据更新本地的标题和排序
                let ordering = j.list[i]?.Ordering || j.list[i].chat_id
                const subject :string = j.list[i].Subject || ""

                if (subject != metas[chat_id]?.subject) {
                    console.debug("根据云端数据更新本地聊天的标题", dup.subject, " -->", subject)

                    if (subject != "") {
                        dup.subject = subject
                    }    

                    isMerged = true
                }

                if (j.list[i].Ordering != metas[chat_id]?.ordering) {
                    console.debug("根据云端数据更新本地聊天", dup.subject, "的排序", metas[chat_id]?.ordering, " --> ", j.list[i].Ordering)

                    ordering = j.list[i].Ordering
                    isMerged = true
                }

                lcs.saveMeta(<ChatMeta>{id: dup.id, subject: dup.subject, ordering})
            }
        }

    } catch (e) {
        console.warn("获取云端聊天列表失败", e)
    } finally {
    }

    if (isMerged) {
        console.debug("由于从云端获取到了新的聊天列表，将执行一次刷新列表操作")
        await updateList()
    }
}

// 将活动的对话滚动到屏幕中
function scrollToActive() {
    let dom = chatListEl.value
    if (dom) {
        let d = dom.querySelector(".active")
        if (d) {
            let oldBehavior = dom.style.scrollBehavior
            dom.style.scrollBehavior = "auto"
            d.scrollIntoView({
                behavior: "auto",
                block: "center",
            })
            dom.style.scrollBehavior = oldBehavior
        }
    }
}

async function doDeleteChat() {
    const id = confirmDeleteChat.value
    confirmDeleteChat.value = 0

    try {
        await deleteChat(id)
        notifySuccess("对话已删除")
    } catch(e) {
        notifyError("对话删除失败:" + (e as Error).message)
    }

    updateList()
}

async function moveChatToTop(chat_id :number) {
    return await moveChatTo(chat_id, true)
}

async function moveChatToFront(chat_id :number) {
    return await moveChatTo(chat_id, false)
}

async function moveChatTo(chat_id :number, to_top :boolean = false) {
    let cs = (<ChatMeta[]>[])
    cs.push(...chatList.value)

    let newOrdering = 0

    if (cs.length == 0) {
        console.debug("当前聊天列表长度为 0，不可排序")
        return
    }

    const metas = await lcs.getMetas()
    const changeSet = new Map<number, number>()

    // 如果当前要移动的对话是一个置顶对话，则可以在所有对话范围内移动
    // 否则，只能在非置顶对话的范围内移动
    const isPin :boolean = metas[chat_id]?.ordering > PIN_SPLIT
    if (isPin == false) {
        cs = cs.filter(v => v.ordering < PIN_SPLIT)
    }


    // 查找将要被替换的聊天
    let replacedChat = null
    if (to_top) {
        // 如果是移动到顶部，则直接取当前最大的聊天的 ordering+1
        replacedChat = cs.slice(-1)[0]
        newOrdering = replacedChat.ordering + 1

        changeSet.set(chat_id, newOrdering)
    } else {
        // 如果是向上移动，则交换此聊天和上一个聊天的 ordering
        for (let i = 0; i < cs.length; i++) {
            if (cs[i].id == chat_id) {
                if (i >= cs.length - 1) {
                    // 指定的对话已经在最前面了，什么都不用做
                    console.debug("指定的对话已经在最前面了，什么都不用做")
                    return
                } else {
                    replacedChat = cs[i+1]
                }
            }
        }

        if (replacedChat) {
            // 设置当前对话的 ordering
            newOrdering = replacedChat.ordering
            changeSet.set(chat_id, newOrdering)
            console.debug("设置当前对话的 ordering", chat_id, newOrdering)

            // 设置被替换的对话的 ordering
            const replacedOrdering = metas[chat_id].ordering
            changeSet.set(replacedChat.id, replacedOrdering)
            console.debug("设置被替换的对话的 ordering", replacedChat.id, replacedOrdering)
        }
    }
    
    const keys = changeSet.keys()
    console.log("当前临时修改集合是", changeSet.size, changeSet)
    for (let i = 0; i < changeSet.size; i++) {
        // debugger
        const chat_id = keys.next().value
        const ordering :number = changeSet.get(chat_id) || 0

        if (ordering == 0) {
            console.warn(`对话列表修改集中找不到对话 ${chat_id} 的 ordering`)
            continue
        }

        console.log("更改排序: (chat_id => ordering) ", chat_id, ordering)
        let m = metas[chat_id]
        m.ordering = ordering
        await lcs.saveMeta(m)

        for (let j = 0; j < chatList.value.length; j++) {
            if (chat_id == chatList.value[j].id) {
                chatList.value[j].ordering = ordering
            }
        }
    }

    // 合并修改集，并准备云端同步
    changeSet.forEach((v, k) => {
        orderingChangeSet.set(k, v)
    })
    console.log("修改后的全局修改集合是", orderingChangeSet.values())

    // 将更改上传到云端
    
    debounceSaveOrdering()

    updateList()
}

async function pinChat(chat_id :number) {
    return await doPinChat(chat_id, true)
}
async function unpinChat(chat_id :number) {
    return await doPinChat(chat_id, false)
}
async function togglePin(chat_id :number) {
    const metas = await lcs.getMetas()
    const oldOrdering :number = metas[chat_id]?.ordering || -1
    if (oldOrdering < 0) {
        console.warn(`meta 中找不到对话 ${chat_id} 的数据`)
        return
    }
    if (oldOrdering > PIN_SPLIT) {
        await unpinChat(chat_id)
    } else {
        await pinChat(chat_id)
    }
}

// 对对话执行置顶/取消置顶操作
async function doPinChat(chat_id :number, is_pin :boolean) {
    const metas = await lcs.getMetas()
    const oldOrdering :number = metas[chat_id]?.ordering || -1
    if (oldOrdering < 0) {
        console.warn(`meta 中找不到对话 ${chat_id} 的数据`)
        return
    }

    let newOrdering :number
    if (is_pin) {
        // 置顶操作
        if (oldOrdering > PIN_SPLIT) {
            console.log(`对话 ${chat_id} 已经处于置顶状态了`)
            return
        }

        newOrdering = oldOrdering + PIN_SPLIT
    } else {
        // 取消置顶操作
        if (oldOrdering < PIN_SPLIT) {
            console.log(`对话 ${chat_id} 并未处于置顶状态，无需操作`)
            return
        }

        newOrdering = oldOrdering - PIN_SPLIT
    }

    metas[chat_id].ordering = newOrdering
    await lcs.saveMeta(metas[chat_id])

    orderingChangeSet.set(chat_id, newOrdering)

    debounceSaveOrdering()
    updateList()
}


const debounceSaveOrdering = debounce(saveOrdering, 2 * 1000)

// 将本地的排序变动修改集上传到服务器
async function saveOrdering() {
    const s = <any>[]
    orderingChangeSet.forEach((ordering, chat_id) => {
        s.push({ChatID: chat_id, Ordering: ordering})
    })
    orderingChangeSet.clear()

    try {
        const j =await apiFetch("/goapi/chat_sync/ordering", {
            method: "POST",
            body: JSON.stringify(s),
        })
    } catch(e) {
        notifyError("同步对话排序失败: " + (e as Error).message)
    }
}

const isShowOrderingButton = computed(() :boolean => {
    // 对于未登录的用户，总是不显示排序按钮
    if (isLoggedIn.value == false) {
        return false
    }

    if (isOrdering.value == false) {
        return true
    } else {
        return false
    }
})

defineExpose({
    updateList
})


</script>


<template>
<div class="main-sidebar chat-sidebar">

    <Component :is="MobileHeader" />
    
    <div class="section section-chat">

        <Component :is="DailyBonus" />
       
        <!-- 对话列表 -->
        <div class="shadow-style-wrap">
            <div class="section-sub section-sub-chat active-chat-edit" ref="chatListEl" >
                <TransitionGroup name="chatlist">
                <router-link v-for="(chat, k) in [...(chatList as any)].reverse()" :key="chat.id"  :to="`/chat?id=${chat.id}`" class="sub-tab-d"
                    @click="emit('selectChat')"
                    >

                    <div class="ctrl" :class="{'active': chatID == chat.id}">
                        <div class="info-main">
                            <p class="title">

                                <!-- 置顶标记 -->
                                <span class="" v-show="chat.ordering > PIN_SPLIT"><icon name="Fill-Pin" /></span>

                                {{chat.subject ?? "新建对话"}}</p>
                            <div class="sub">
                                <icon name="Menu-Change" />
                                <p class="msg-count">12</p>
                            </div>
                        </div>
                        <div class="info-sub">
                            <icon name="Arrow-Right" />
                        </div>
                    </div>
                    
                    <div class="chat-edit-zone" v-show="isOrdering">
                        <!-- 排序对话的控制 -->
                        <div v-show="chat.id!=confirmDeleteChat" class="zone-ctrl">
                            <div class="chat-action-tile"  @click.prevent="togglePin(chat.id)" >
                                <icon name="Pin" v-show="chat.ordering < PIN_SPLIT" />
                                <icon name="Pin-Off"  v-show="chat.ordering > PIN_SPLIT"  />
                            </div>

                            <div class="chat-action-tile" @click.prevent="moveChatToTop(chat.id)">
                                <icon name="Edit-SetTop" />
                            </div>
                            <div class="chat-action-tile" @click.prevent="moveChatToFront(chat.id)">
                                <icon name="Arrow-Up" />
                            </div>

                            <div class="chat-action-tile"  @click.prevent="confirmDeleteChat=chat.id">
                                <icon name="Trash-Can-Delete2" />
                            </div>
                        </div>

                        <!-- 删除对话的控制 -->
                        <div v-show="confirmDeleteChat!=0 && chat.id==confirmDeleteChat" class="zone-ctrl">
                            <p class="font13 color-black60">删除？</p>

                            <div class="chat-action-tile" @click.prevent="doDeleteChat">
                                <icon name="OK-Tick" class="ok-delete" />
                            </div>

                            <div class="chat-action-tile" @click.prevent="confirmDeleteChat=0">
                                <icon name="Cross-Close" />
                            </div>
                            
                        </div>
                    </div>
                </router-link>
                </TransitionGroup>
            </div>
        </div>

        <div class="section-sub section-sub-new-chat flex-row-center important flex-gap10">
            
            <a href="#" class="sub-tab-c flex-1" @click.prevent="createChat" v-if="!isOrdering">
                <div class="info-main">
                    <icon name="Add-Plus" />
                    <p class="title">新对话</p>
                </div>
                <div class="info-sub">
                </div>
            </a>

            <a href="" class="sub-tab-chat-edit" v-if="isShowOrderingButton" @click.prevent="isOrdering=true">
                <icon name="Chat-Edit" />
            </a>
           

            <a href="" class="sub-tab-chat-edit-cancel" @click.prevent="isOrdering=false" v-if="isOrdering">
                <icon name="Cross-Close" />
                退出编辑状态
            </a>

        </div>


    </div>

    <Component :is="LeftBottomBox" />


</div>
</template>

<style scoped>
.chatlist-move, /* 对移动中的元素应用的过渡 */
.chatlist-enter-active,
.chatlist-leave-active {
  transition: all 1.5s ease;
}

/* .chatlist-enter-from,
.chatlist-leave-to {
  opacity: 0;
  transform: translateX(30px);
} */

/* 确保将离开的元素从布局流中删除
  以便能够正确地计算移动的动画。 */
.chatlist-leave-active {
  position: absolute;
}
</style>