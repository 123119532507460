import dayjs from 'dayjs'
import {useCookies} from "vue3-cookies"
import {useRouter} from "vue-router"
const {cookies} = useCookies()
import { getCurrentInstance } from 'vue'
import {LocalChatStorage} from "./chat_storage"
import {apiFetch, apiURL, getSessionKey, getTrackID} from "./common"


// 传入一个参数，如果是 undefined，则返回 “--”
export function L(val :any) {    
    if (val === undefined) {
        return "--"
    } else if (isNaN(val) && typeof val === typeof 123) {
        return "--"
    } else {
        return val
    }
}



// 当前设备是否是 iPhone
export function isIPhone() :boolean {
    const ua = navigator?.userAgent || ""
    return /iPhone/.test(ua)
}

export function isIPhonePWA() : boolean {
    if (!isIPhone()) {
        return false
    }

    //window.navigator.standalone 是 IOS 上专有的属性
    if ((window.navigator as any)?.standalone || false) {
        // PWA 模式
        return true
    }
    if (window.matchMedia('(display-mode: standalone)').matches) {
        return true
    }

    return false
}




export function base64ToUint8Array (base64String :string) :Uint8Array {
    let padding = '='.repeat((4 - base64String.length % 4) % 4)
    let base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/')
    let rawData = atob(base64)
    let outputArray = new Uint8Array(rawData.length)
  
    for (let i = 0; i < rawData.length; i++) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }



export async function getChatID() :Promise<number> {
    try {
        // let idstr :string = (new URLSearchParams(window.location.hash.split("?")[1])).get("id") as string
        let idstr :string = (new URLSearchParams(window.location.search.split("?")[1])).get("id") as string
        let id = parseInt(idstr)
        
        let preset_id = parseInt(getQuery("preset_id"))
        if (isNaN(preset_id)) {
            preset_id = 0
        }

        if (!id && !preset_id) {
            console.debug("URL 中未提供 id 和 preset_id 参数，我们尝试使用本地最新的对话作为参数")
            let ids = await (new LocalChatStorage()).listAll()    

            if (ids.length > 0) {
                id = ids.pop() || 0
            }
        }

        return id
    } catch {
        return 0
    }
}

export function getQuery(name :string) :string {
    try {
        let v = (new URLSearchParams(window.location.search.split("?")[1])).get(name)
        if (v === null) {
            return ""
        } else {
            return (v as string)
        }
    } catch {
        return ""
    }
}



// 当前是否是手机屏幕
export function isMobile() :boolean {
    
    try {
        let w = window.innerWidth
        let h = window.innerHeight
        if (w <= 768 && w < h) {
            return true
        } else {
            return false
        }
    } catch {
        return false
    }
}

// 获取一个自增的编号
export function getAutoIncrement(name :string) :number {
    let key = `auto_increment.${name}`

    let ns:string = localStorage.getItem(key) || ""
    
    let n = parseInt(ns)
    if (isNaN(n)) {
        n = 1
    }

    localStorage.setItem(key, (n + 1).toString())

    return n
}



// 创建一个新的对话，但只返回对话编号
export function newChatID(subject :string = "新的对话", preset_id :number = 0) :number {
    let lcs = new LocalChatStorage()
    let c = lcs.create()

    if (subject != "") {
        c.subject = c.subject.replace("新的对话", subject)
    }

    if (preset_id > 0) {
        c.preset_id = preset_id
    }

    lcs.save(c)

    return c.id
}

// 创建一个新的对话，并跳转
export function newChat(router: any, subject :string = "新的对话", preset_id :number = 0) { 
    // window.location.hash = "#/chat?id=" + newChatID(subject, preset_id)
    // window.location.href = "chat?id=" + newChatID(subject, preset_id)
    router.push("chat?id=" + newChatID(subject, preset_id))
}


// 从本地和服务器上删除一个对话
export async function deleteChat(chat_id :number) {
    let j = await apiFetch("/goapi/chat_sync/delete", {
        method: "post",
        body: JSON.stringify({
            ID: chat_id,
        })
    })

    await (new LocalChatStorage()).delete(chat_id)
}

// 获取 ChatPreset 列表
export async function getPresets(opts :any = {}) {
    let m = <any>{}

    let url = "/goapi/chat/preset/list"
    let qsList = []
    if (opts.isTool !== undefined) {
        qsList.push( "is_tool=" + opts.isTool)
    }
    if (opts.isAll !== undefined) {
        qsList.push( "is_all=" + opts.isAll)
    }

    if (qsList.length > 0) {
        url += "?" + qsList.join("&")
    }

    if (Object.keys(m).length == 0) {
        console.debug("尚未加载 ChatPresets,尝试加载")
        let res = await apiFetch(url, {})
        for (let v of res.presets) {
            m[v.ID] = v
        }
    }

    return m
}


// 获取指定的 ChatPreset
export async function getPreset(id :number) {
    let m :any = await getPresets({isAll: 1})
    
    if (m[id]) {
        return m[id]
    } else {
        return undefined
    }
}

// 根据传入的 hash (window.location.hash)，获取当前页面，当前页面可能是 index.html, tools, chat 等
export function hashToPage(h :string) :string {
    let tokens = h.split("/")
    if (tokens.length == 1) {
        return "index.html"
    } else {
        return tokens[1].split("?")[0]
    }
}
export function pathToPage(h :string) :string {
    if (h == "/") {
        return "index.html"
    } else {
        return h.split("?")[0]
    }
}

export function getCurrentPage() :string {
    return pathToPage(window.location.pathname)
}

// 输入当前页码和总页数，返回需要渲染的页码数字
export function getDisplayPages(currentPage :number, totalPage :number) :number[] {
    let ret: number[] = [currentPage]

    // 从当前的页面开始，向两边生成页面序号
    for (let i = 1; i <= 3; i++) {
        let p = currentPage - i;
        if (p <= 0) {
            break
        }
        ret.unshift(p)
    }

    
    for (let i = 1; i <= 3; i++) {
        let p = currentPage + i;
        if (p > totalPage) {
            break
        }
        ret.push(p)
    }

    return ret
}

// 安全地自增一个数字，如果 n 是 NaN，则返回 1
export function safeIncr(n :number) :number {
    let m :number = n += 1

    if (isNaN(m)) {
        console.warn("传入的参数 n 自增后是 NaN, n=", n)
        return 1
    } else {
        return m
    }
}


// 将点数转换成可读的样子：
// 小于 10000 点：直接显示
// 超过 10000 点：例如，12340000 点显示成 1,234w 
export function tokenToReadable(s :number) :string {
    if (s < 10000) {
        return s.toString()
    } else {
        let w = Math.floor(s / 10000)
        return w.toLocaleString() + "w"
    }
}

// 将聊天消息的时间转换成较短的格式
// 如果是 24 小时之内的时间，只显示时分秒
// 如果是今年以内的时间，只显示月日时分秒
// 其他均显示完成的年月日时分秒
export function chatMessageTimeToReadable(ts :number) :string {
    let ct = (new Date())
    let cts = ct.getTime() / 1000

    if (ts <= 0) {
        return "-:-"
    }

    let elapsed = cts - ts
    if (elapsed < 86400) {
        // 24 小时以内
        return dayjs.unix(ts).format("HH:mm:ss").toString()
    } else if (ct.getFullYear() == (new Date(ts * 1000)).getFullYear()) {
        // 今年以内
        return dayjs.unix(ts).format("M月D日 HH:mm:ss").toString()
    } else {
        return dayjs.unix(ts).format("YYYY年M月D日 HH:mm:ss").toString()
    }
}


// 计算字符串的字数。一个汉字计算 1 个,一个英文单词计算 1 个
export function wordCount(str :string) :number {
    // 定义中文的正则表达式
    const chineseRegex = /[\u4e00-\u9fa5]/g;
    // 定义英文的正则表达式
    const englishRegex = /\b\w+\b/g;
  
    // 获取中文的匹配结果
    const chineseMatches = str.match(chineseRegex);
    // 获取英文的匹配结果
    const englishMatches = str.match(englishRegex);
  
    // 计算中文字符的数量
    const chineseCharCount = chineseMatches ? chineseMatches.length : 0;
    // 计算英文单词的数量
    const englishWordCount = englishMatches ? englishMatches.length : 0;
  
    // 返回中文字符和英文单词的数量之和
    return chineseCharCount + englishWordCount;
}


// 解析 HTTP Streaming 类型的消息（以 data: 开头的消息）
export function parseStreamData(s :string) :string {
    // console.log("解析 StreamData,输入内容", s)

    let ret :string   = ""  
    // let lines = s.split("\n\n")                  // 使用 GPT3.5 时，这行可用
    let lines = s.split(/[\r\n]|[\n\r]|[\n\n]/g)    // 使用 Claude 2.1 时，这行可用
    

    

    for (let l of lines) {
        
        if (l === "") {
            continue
        }

        if (l.startsWith("data:")) {
            ret += l.slice(5)
        }
    }

    // console.log("解析 StreamData,输出内容", ret)

    return ret
}


export function wwwURL(p :string) :string {
    if (p.startsWith("/")) {
        p = p.slice(1);
    }
    return `https://${window.location.host}/${p}`
}

export function inviteURL(inviteCode :string) :string {
    return wwwURL("/hi/" + inviteCode)
}

// 输入一个数字，将这个数字修复
// 例如，(0.1 + 0.2) === 0.30000000000004，这时候我们可以把这个数字修复为 3
export function fixNumber(n :any, decimal :number = 0) :string {
    if (n === null || n === undefined) {
        return n
    } else if (n === "") {
        return n
    }

    let nn = parseFloat(n)
    if (isNaN(nn)) {
        return n
    } else {
        return nn.toFixed(decimal)
    }
}


import {notify} from "@kyvg/vue3-notification"
function notifyDuration(msg :string) :number {
    // 据说大多数人阅读速度为 300～500字/分钟，我们取 400 字/分钟，即 0.15s 一个字
    // 考虑到提示信息需要阅读理解，我们将这个速度减半，即使用 0.3s/字 的速度显示
    // 定一个最小值，即 3s
    let duration :number = wordCount(msg) * 300
    if (duration < 3000) {
        duration = 3000
    }
    return duration
}
export function notifyError(msg :string) {
    let duration = notifyDuration(msg)
    notify({type: "error", text: msg, duration: duration})
    console.debug(`【error 通知消息，时长 ${duration} 毫秒【`, msg)
}
export function notifySuccess(msg :string) {
    let duration = notifyDuration(msg)
    notify({type: "success", text: msg, duration: duration})
    console.debug(`【success 通知消息，时长 ${duration} 毫秒】`, msg)
}
export function notifyInfo(msg :string) {
    let duration = notifyDuration(msg)
    notify({type: "info", text: msg, duration: duration})
    console.debug(`【info 通知消息，时为 ${duration} 毫秒】`, msg)
}

// 在触发事件的对象上，增加或删除指定的 CSS class 
export function toggleClass(event :any, name :string) {
    try {
        event.target.classList.toggle(name)
    } catch (e) {
        console.warn("切换 class 失败", e, event, name)
    }
}


// 如果 val 是 undefined 或者 null，则返回 defaultVal，否则返回 val
export function validOr(val :any, defaultVal: any) :any {
    if (val === undefined || val === null) {
        return defaultVal
    } else {
        return val
    }
}

export function isDev() :boolean {
    try {
        if (localStorage.getItem("chatboy.dev")) {
            console.log("由于本地存储（LocalStorage）中设置了 chatboy.dev 键值，将认为当前处于调试模式")
            console.log(`若要退出此状态，请执行 localStorage.removeItem("chatboy.dev")`)
            return true
        }

        return !import.meta.env.PROD
    } catch (e){ 
        console.warn("未能获取 import.meta.env.PROD 变量，认为当前处于非开发模式")
        return false
    }   
}


export async function apiUploadRaw(url :string, options? :any) :Promise<XMLHttpRequest> {
    if (options === undefined) {
        options = {}
    }

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open(options.method || "POST", apiURL(url))
        xhr.setRequestHeader("x-chatboy-session-key", getSessionKey())
        xhr.setRequestHeader("x-chatboy-track-id", getTrackID())
        if (options.headers) {
            Object.keys(options.headers).forEach(function (key) {
                xhr.setRequestHeader(key, options.headers[key])
            })
        }
        
    
        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr)
            } else {
                reject(new Error(xhr.statusText))
            }
        }
        xhr.onerror = function (e) {
            reject(new Error("网络错误？"))
        }

        if (options.onprogress) {
            xhr.upload.onprogress = options.onprogress
        }

        xhr.send(options.body)
    })
}

export interface UploaderOptions {
    url? :string
    data? : {[key :string]:any}
}

export class Uploader {
    protected apiEndpoint :string
    public onprogress :CallableFunction | null = null

    // 开始通过网络上传图片时调用
    public onsubmit :CallableFunction = () => {}

    protected data :{[key :string]:any} = {}

    constructor(opts :UploaderOptions) {
        if (opts.url) {
            this.apiEndpoint = opts.url
        } else {
            this.apiEndpoint = "/goapi/misc/upload"
        }

        if (opts.data) {
            this.data = opts.data
        }
    }


    async upload() :Promise<any> {      
        const input = document.createElement("input")
        input.type = "file"
        input.name = "file"
        input.accept = "image/*"

        const that0 = this

        return new Promise((resolve, reject) => {
            const that = that0
            const rejectE = (s :string) => reject(new Error(s))

            input.oncancel = function() {
                rejectE("取消上传")
            }
    

            input.onchange = async function() {
                if (!input.files) {
                    rejectE("没有选择文件")
                    return
                }

                const file = input.files[0]
                if (!file) {
                    rejectE("未选择文件")
                    return
                }

                console.log("准备上传文件", file)
                if ((file.size || 0) > 16 * 1024 * 1024) {
                    rejectE("文件大小不能超过 16 MiB")
                    return
                }
            
                try {
                    const fd = new FormData()
                    for (let i in that.data) {
                        fd.append(i, that.data[i])
                    }
                    fd.append("file", file)

                    
                    that0.onsubmit()
                    
                    const resp = await apiUploadRaw(that.apiEndpoint, {
                        method: "post",
                        body: fd,
                        // headers: {
                        //     "Content-Type": "multipart/form-data",
                        // },
                        onprogress: (e :any) => {
                            const p = e.loaded / e.total
                            if (that.onprogress) {
                                that.onprogress(p)
                            }
                        },
                    })
                    const j = JSON.parse(resp.response)

                    console.log("上传结果", j)
                    if (j.code == 0) {
                        resolve(j.data)
                    } else {
                        rejectE(j.message)
                    }
                } catch(e) {
                    reject(e)
                }
            }

            input.click()

        })
    }
}
    



export async function cbReportEvent(evt: string, extra?: any) {
    if (!extra) {
        extra = ""
    } else if (typeof extra === "string") {
        // 什么都不用做
    } else {
        extra = JSON.stringify(extra)
    }
    await apiFetch("/goapi/event/new", {
        method: "POST",
        body: JSON.stringify({
            ExtraData: extra,
            Event: evt,
            TrackID: getTrackID(),
        })
    })
}

export async function cbCaptureException(e :Error) {
    try {
        const extra = (e.toString()) + "\n" + e.stack
        await cbReportEvent("capture_exception", extra)
    } catch(e) {
        console.log("上报错误失败", e)
    }
}

export function encodeURIComponentChinese(str :string) {
    return str.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, function(char :string) {
      return encodeURIComponent(char)
    });
  }
  
