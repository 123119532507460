<!-- 
文本输入框
如果指定了 step 参数，则显示为 number，且提供上下按钮
 -->
<script setup lang="ts">
import {computed} from "vue"
const emit = defineEmits(['update:modelValue', 'change'])
const props = withDefaults(defineProps<{
    modelValue: any,
    step? :number
    max? :number | "",
    min? :number | "",
    disabled? :boolean,
}>(), {
    step: 1,
    max: "",
    min: "",
    disabled: false,
})


function onBlur() {
    console.log("blur了")
}

const val = computed({
    get() {
        let value = props.modelValue
        if (!isNaN(value)) {
            if (props.max !== "" && parseFloat(value) > props.max) {
                return props.max
            }
            if (props.min !== "" && parseFloat(value) < props.min) {
                return props.min
            }
        }
        

        return props.modelValue
    },
    set(value) {
        if (!isNaN(value)) {
            if (props.max !== "" && parseFloat(value) > props.max) {
                value = props.max
            }
            if (props.min !== "" && parseFloat(value) < props.min) {
                value = props.min
            }
        }

        emit('update:modelValue', value)
    }
})

function onWheel(e :any) {
    // number 类型的 input 似乎默认就有鼠标滚轮事件，我们不需要手动处理了
    let v = parseFloat(val.value)
    if (isNaN(v)) {
        return
    }

    try {
        if (e.deltaY > 0) {
            val.value = v - 1
        } else if (e.deltaY < 0) {
            val.value = v + 1
        }
    } catch(e){}
}

function incr() {
    if (props.disabled) {
        return
    }

    val.value = Math.round((1000 * parseFloat(val.value)) + (1000 * props.step)) / 1000
}
function dec() {
    if (props.disabled){ 
        return
    }

    val.value = Math.round((1000 * parseFloat(val.value)) - (1000 * props.step)) / 1000
}


</script>

<template>
    <div class="form-input-ctrl input-small" :class="{disabled: props.disabled}" >

        <!-- <input @blur="onBlur" v-if="step>0" type="number" v-model="val" :disabled="val === undefined || props.disabled" @wheel="onWheel" :max="props.max" :min="props.min" :step="props.step" /> -->
        <input @blur="onBlur" v-if="step>0" type="number" v-model="val" :disabled="val === undefined || props.disabled"  :max="props.max" :min="props.min" :step="props.step" />
        <input v-else type="text" v-model="val" :disabled="val === undefined || props.disabled"  />

        <div class="ctrl" v-if="props.step>0">
            <div class="tabs">
                <div class="tab-tile" @click="incr">
                    <icon name="Arrow-Up" />            
                </div> 
                <div class="tab-tile" @click="dec">
                    <icon name="Arrow-Down" />            
                </div> 
            </div>         
        </div>
    </div>

</template>


<style scoped>
.disabled {
    filter:contrast(0.62);
    opacity: 0.62;
}
</style>

