import {isReactive} from "vue"
import localforage from "localforage"

var instances :Map<string, LocalForage> = new Map()

export class CBStore {
    private s :LocalForage
    private namespace :string = ""

    constructor(private ns :string = "") {
        if (ns != "") {
            ns = `chatboy.${ns}`
        } else {
            ns = "chatboy."
        }

        this.namespace = ns

        if (!instances.has(ns)) {
            let s = localforage.createInstance({
                name: ns,
            })
            instances.set(ns, s)
        } 
        this.s = instances.get(ns) as LocalForage
    }

    // 存储一个值
    async set(key :string, val :any) :Promise<any> {
        return await this.s.setItem(key, JSON.stringify(val))
    }

    // 获取一个存储的值，如果值不存在，则返回 undefined
    async get(key :string) :Promise<any | undefined> {
        let r = await this.s.getItem(key)
        if (r === null) {
            return new Promise((y, n) => y(undefined))
        } else{ 
            return new Promise((y, n) => y(JSON.parse(r as string)))
        }
    }

    // 删除一个值
    async remove(key :string) {
        return await this.s.removeItem(key)
    }

    // 删除所有值
    async clear() {
        return await this.s.clear()
    }
}