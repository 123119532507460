<script setup lang="ts">
import {useModalState} from "@/store"
import {storeToRefs} from "pinia"
import {modalSlideDownIn, modalSlideDownOut} from "@/animation"

const {isShowInvite, isShowMobileMenu, isShowRedeemCode} = storeToRefs(useModalState())

</script>
<template>

<Transition @enter="modalSlideDownIn" @leave="modalSlideDownOut">
<div class="header-menu" v-if="isShowMobileMenu">

    <!-- 菜单按钮 -->
    <div class="info">
        <div class="triggers">
            <a class="trigger-hamburger" @click="isShowMobileMenu = !isShowMobileMenu">
                <icon name="Menu-Change" />
            </a>
        </div>
    </div>

    <!-- 菜单内容 -->
    <div class="menu-box" >
        <div class="menu-section" @click="isShowMobileMenu=false">
            <router-link to="/chat" class="menu-tile">
                <icon name="Conversation" />
                <p class="tx">对话/工具</p>
            </router-link>
            <router-link to="/shop" class="menu-tile">
                <icon name="Shop" />
                <p class="tx">商店</p>
            </router-link>
            <span class="gapline"></span>
            <!--暂时隐藏
            <router-link href="" class="menu-tile">
                <icon name="Box-Open" />
                <p class="tx">文件箱</p>
            </router-link>
            -->
            <a class="menu-tile" @click="isShowInvite = true; isShowMobileMenu=false;">
                <icon name="User-More" />
                <p class="tx">邀请奖励</p>
            </a>
            <span class="gapline"></span>
            <a class="menu-tile" @click="isShowRedeemCode = true; isShowMobileMenu = false">
                <p class="tx">兑换码</p>
            </a>
            <router-link class="menu-tile" to="/config/chat">
                <p class="tx">设置</p>
            </router-link>
            <router-link to="/help" class="menu-tile">
                <p class="tx">帮助</p>
            </router-link>
            <router-link to="/hi" class="menu-tile">
                <p class="tx">新功能</p>
            </router-link>
            
            <!--暂时隐藏：测试用
            <router-link to="/assets" class="menu-tile">
                <p class="tx">Assets</p>
            </router-link>
            -->
        </div>
    </div>

    <!-- 背景板，用于在菜单出现的时候，覆盖主页面内容，避免点击到主页面上的内容 -->
    <div class="g-overlay-trans-menu" @click="isShowMobileMenu=false"></div>

</div>
</Transition>
</template>