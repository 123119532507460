var timeLog = new Map()
var timerIDs = new Map()

// 避免多次同时调用
// 使用点1：MJImage 列表中，每次 ws 消息下来后都应该触发一次 loadList 操作
//      但是画图开始的时候，会有一波 ws 消息，这时候如果每次都触发的话会导致大量 loadList 请求同时发送
//      debounce("mjimageLoadList", 3000, loadList)
//      这样可以保证在收到重复的 ws 消息时，至少等待 3s 后再去执行，且不会执行多次
export function debounce(key: string, delay :number, f: CallableFunction) {
    // 1. 无论如何都应该清除定时器
    clearTimeout(timerIDs.get(key) || 0)

    // 2. 检查在 delay 毫秒内是否已经执行过，若是，则等待一段时间后再执行
    let ts = (new Date()).getTime()
    let elapsed: number = ts - (timeLog.get(key) || 0)
    if (elapsed >= delay) {
        // 已经超过最大延迟，直接执行
        console.debug(`[${key}] 已经超过最大延迟，直接执行. remains=${elapsed}`)
        timeLog.set(key, ts)
        f()
    } else {
        // 尚未超过最大延迟，设置定时器
        let remains = delay - elapsed
        // console.debug(`[${key}] 尚未超过最大延迟，等待 ${remains}ms 后执行`)
        let tid = setTimeout(function() {
            console.debug(`执行一次 debounce [${key}] 对应的操作`)
            f()
            timeLog.set(key, (new Date()).getTime())
        }, remains)
        timerIDs.set(key, tid)
    }
}