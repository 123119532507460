<script setup lang="ts">
import {ref} from "vue"
import {storeToRefs} from "pinia"
import {apiFetch} from "@/common"
import {notifyError, notifySuccess} from "@/common2"
import {useModalState} from "@/store"
import MobileModal from "@/comps/MobileModal.vue"

const { isShowSuggestion } = storeToRefs(useModalState())


const suggestionContact = ref("")
const suggestionContent = ref("")
const isSuggestionSending = ref(false)

async function sendSuggestion() {
    suggestionContent.value = suggestionContent.value.trim()
    suggestionContact.value = suggestionContact.value.trim()

    
    if (suggestionContact.value == "") {
        notifyError("必须填写联系方式")
        return
    }
    if (suggestionContent.value == "") {
        notifyError("必须填写建议内容")
        return
    }

    try {
        isSuggestionSending.value = true
        const j = await apiFetch("/goapi/suggestion/create", {
            method: "post",
            body: JSON.stringify({
                Contact: suggestionContact.value,
                Content: suggestionContent.value,
            }),
        })
        
        notifySuccess("感谢你的建议，我们会尽快阅读")
        isShowSuggestion.value = false
        suggestionContent.value = ""
    } catch(e) {
        notifyError((e as Error).message)
     } finally {
        isSuggestionSending.value = false
    }
}
</script>

<template>

    <!-- 提建议弹窗 -->
    <Transition name="modal">
        <Component :is="MobileModal" :isShow="isShowSuggestion" @close="isShowSuggestion=false" :hideOKButton="true" title="有什么想和我们说的呢" icon="Feather-Pen-Edit" class="popup">
            <form class="flex-col-left flex-gap28 popup-redeem" @submit.prevent="sendSuggestion">
                <div class="flex-col-center flex-gap20 padding-top-20 padding-bottom-28 g-gapline width100">
                    <div class="form-input-ctrl input-medium width100">
                        <input type="text" placeholder='(必填) 电话/微信/邮箱' v-model="suggestionContact" />
                    </div>
                    <div class="form-input-ctrl input-medium width100">
                        <input type="text" placeholder='(必填) 建议或者留言' v-model="suggestionContent" />
                    </div>
                </div>
    
                <div class="flex-col-center flex-gap28 width100">
                    <div class="font14">
                        ※ 认真倾听每一个用户的留言！如果你的留言被采纳用于改进产品，我们将会通过上方联系方式发出官方福利哦。
                    </div>
                    <button type="submit" class="button-g-3d button-medium width120 button-g-3d-redeem" :class="{'button-loading': isSuggestionSending}">
                        <div class="ctrl">
                            <icon name="Loading-Circle" />            
                            提交建议
                        </div>
                    </button>
                    
                </div>
            </form>
        </Component>
        </Transition>
</template>