<script setup lang="ts">
import {ref, inject, watch, computed, onMounted} from "vue"
// import DemoLogin from "@/comps/DemoLogin.vue"
import RedeemCode from "@/comps/RedeemCode.vue"
import MobileModal from "@/comps/MobileModal.vue"
import LoginForm from "@/comps/LoginForm.vue"
import RegisterForm from "@/comps/RegisterForm.vue"
import TabModal from "@/comps/TabModal.vue"

import {L, notifySuccess, notifyError} from "@/common2"
import { duration2Readable , apiURL, apiFetch} from "@/common"
import {useAccount, useModalState, usePreference} from "@/store"
import {storeToRefs} from "pinia"
import {useRouter} from "vue-router"

const router = useRouter()

const Account = useAccount()
const {a: currentAccount, cached: cachedAccount, whatsNew} = storeToRefs(Account)
const account = currentAccount
const Preference = usePreference()

const {isShowRedeemCode, isShowConfig, isShowLogin, isShowSuggestion} = storeToRefs(useModalState())

const isShowWhatsNew = ref(false)

onMounted(() => {
    // console.log("账户是", currentAccount.value)
    showWhatsNew()
})

function showWhatsNew() {
    console.log("当前 Account 的 WhatsNew 是", whatsNew.value)

    const n = "240518"
    
    if (whatsNew.value.indexOf(n) < 0) {
        return
    }

    // 1. 请求服务器关闭 WhatsNew

    apiFetch(`/goapi/misc/whatsnew/${n}/touch`, {
        method: "POST",
    }).then(j => {
        useAccount().reload()
        // 2. 显示 WhatsNew
        isShowWhatsNew.value = true
    })
}

function isPage(name :string | string[]) :boolean {
    let page = ""
    try {
        page = window.location.pathname.split("/")[1]
    } catch(e) {}

    // console.log("页面是", page)
    if (typeof name === typeof "") {
        if (page.indexOf(name as string) === 0) {
            return true;
        } else {
            return false;
        }
    } else if (typeof name === typeof <string[]>{}) {
        for (let k in (name as string[])) {
            if (name[k] === "") {
                if (page == "") {
                    return true
                }
                continue
            }

            if (page.indexOf(name[k]) === 0) {
                return true
            }
        }
        return false
    } else {
        return false
    }
}

const remainingDays = computed(() => {
    return useAccount().remainingDays
})

const isInSubscribe = computed(() :boolean => {
    if (cachedAccount.value?.account?.expire_time < new Date().getTime() / 1000){
        return false
    }
    if (cachedAccount.value?.current_product?.Name != "") {
        return true
    } else {
        return true
    }
})

function doLogout() {
    Account.logout()
    // window.location.reload()
    notifySuccess("已退出登录")
    router.push("/login")
}



</script>


<template>
    <div class="header"> 
        <div class="main-info">
            <a href="/" class="logo">
                <icon name="Logo-Chatboy" class="avatar" />
                <h1>ChatBoy.io</h1>
            </a>
        </div>
        <div class="navtab">
            <router-link to="/chat" :class="{'active' : isPage(['', 'chat'])}">对话</router-link>
            <router-link to="/tools" :class="{'active' : isPage('tools')}">工具</router-link>
            <router-link to="/shop" :class="{'active' : isPage('shop')}">商店</router-link>
        </div>
        <div class="info">
            <div class="triggers">
                <a href="" class="trigger-chat-active">
                    <icon name="Conversation-Acitve-Fill" />
                </a>
                <a href="" class="trigger-hamburger">
                    <icon name="Menu-Change" />
                </a>
            </div> 
 
            <div class="links">
                <!-- <a @click.prevent="isShowUpdateInfo = true">测试</a> -->
                <a @click.prevent="isShowSuggestion = true">提建议</a>
                <!-- <a @click.prevent="isShowConfig = true">设置</a> -->
                <router-link to="/config/chat" v-show="Account.cached">设置</router-link>
                <a @click.prevent="isShowRedeemCode = true"  v-show="Account.cached">兑换码</a>
                <!-- <a @click.prevent="isShowLogin = true" v-if="!Account.a?.account">登录</a> -->
                <!-- <router-link to="/login" v-if="!Account.a?.account">登录</router-link> -->
                <router-link to="/login" v-if="!Account.cached">登录</router-link>
                <router-link to="/help?t=qa">帮助</router-link>
                <router-link to="/hi">新功能</router-link>
            </div>
            <router-link to="/config/personal" class="avatar">
                <img :src="Account.avatar(true)" alt="头像">
            </router-link>
            

            <!--移动端：个人用户信息弹窗：已登录-->    
            <div class="popup-user">
                
                <div class="title-part flex-row-between-top width100">
                    <div class="flex-col-left">
                        <p>{{L(cachedAccount?.account?.username)}}</p>
                        <p class="">{{L(cachedAccount?.account?.display_name)}}</p>
                    </div>

                    <div class="flex-row-left flex-gap8">
                        <!-- 存在 .active 的时候，显示第二个子元素（在这里就是 Sun 元素）-->
                        <div class="button-g button-xsmall animation-slide-switch" 
                            :class="{'active': Preference.computedTheme() == 'dark'}"
                            @click="Preference.switchTheme"
                        >
                            <div class="slide-ctrl">
                                <icon name="Moon" />
                            </div>
                            <div class="slide-ctrl">
                                <icon name="Sun" />
                            </div>
                        </div>

                        <router-link to="/config/personal">
                        <div class="button-g button-xsmall">
                            <icon name="Pencil-Edit" />
                        </div>
                        </router-link>
                    </div>
                </div>
                <div class="flex-col-left flex-gap4 width100">
                    <div class="flex-row-between width100 padding-bottom-4">
                        <div class="flex flex-gap12">
                            <p class="label">当前订阅</p>
                            <p v-if="isInSubscribe">{{L(cachedAccount?.current_product?.Name)}}</p>
                            <p v-else>无</p>
                        </div>
                        <div class="button-g button-xsmall button-fill-nude button-border-black">
                            <router-link to="/shop">{{isInSubscribe ? "续费" : "购买"}}</router-link>
                        </div>
                    </div>
                    <div class="flex flex-gap12">
                        <p class="label">已用点数</p>
                        <p v-if="cachedAccount?.account">
                            {{L(cachedAccount?.account?.token_used.toLocaleString())}}
                            / {{L(cachedAccount?.account?.token_limit.toLocaleString())}} 点
                            <!-- 桌面端左下角已有时间信息，在这里就不显示了 -->
                            <!-- <span>(剩余{{remainingDays}})</span>  -->
                        </p>
                        <p v-else>
                            <span>--</span>
                        </p>
                    </div>

                    <!-- 暂时还没有积分，先隐藏了 -->
                    <!-- <div class="flex flex-gap12">
                        <p class="label">积分</p>
                        <p>18,200</p>
                    </div> -->
                </div>
                <div class="flex">
                    <div class="button-g button-small" v-show="Account.a?.account" @click.prevent="doLogout">
                        <a>登出</a>
                    </div>
                </div>
            </div>


        </div>
    </div>

    <!-- 一次性新功能更新提示弹窗 -->
    <Transition name="modal">
        <Component :is="MobileModal" :isShow="isShowWhatsNew" :hideOKButton="false" @close="isShowWhatsNew = false" title="ChatBoy 更新啦 2024/5/18" icon="Versions" class="popup">
            <div class="flex-col-left flex-gap28 popup-redeem">
                <p class="font14">
                    <span class="font15 weight500 color-purple">✦ Ai绘图：「保留提示词」与「保留垫图」</span>
                    <br/>
                    新增快捷高级设置，勾选之后，提示词和垫图会保留亿方便继续修改再次绘图。
                </p>
                <div class="flex">
                    <img src="/pic/update/2024-05-15.png" alt="截图-产品更新" class="width-max500">
                </div>
            </div>
        </Component>
    </Transition>

</template>