import mitt from "mitt"

const bus = mitt()

export default {
    emit: (e :string, d ?:any) => bus.emit(e, d),
    on: (e :string, h :any) => bus.on(e, h),
    off: (e :string, h :any) => bus.off(e, h),

    聊天标题更新 : "聊天标题更新",
    聊天设置更新 : "聊天设置更新",
    刷新聊天列表 : "刷新聊天列表",

    面具更新 : "面具更新",
}

