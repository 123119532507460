<script setup lang="ts">
import {L} from "@/common2"
import {getProfile} from "@/apis"
import {apiFetch, writeClipboard}  from "@/common"
import {inviteURL, fixNumber} from "@/common2"
import { onMounted , ref, watch} from "vue"
import {useAccount} from "@/store"
import {notify} from "@kyvg/vue3-notification"

import ReferralList from "@/comps/ReferralList.vue"

const props = defineProps<{
    profile? :any
}>()

const profile = ref<any>()


onMounted(async () => {
    profile.value = props.profile
    console.log("当前获得的 profile 是", profile.value)

    if (!profile.value) {
        profile.value = await getProfile()
    }
})

async function copyShareLink() {
    let a = useAccount().a
    if (!a) {
        return
    }

    try {
        let link = inviteURL(a.referral_code)
        await writeClipboard(link)
        notify({type: "success", text: "邀请链接已复制到剪贴板"})
    } catch (e) {
        console.warn(e)
    }
}


</script>


<template>
    
<!-- <div class="popup-ctrl" > -->
    <div>

        <!-- 邀请奖励页头 -->
    <!-- <div class="popup-title-section">
        <div class="left-part">
            <div class="icon-ctrl">
                <icon name="User-More" />
            </div>
            <h3 class="title">邀请奖励</h3>
        </div>
        <div class="right-part">
            <a @click="$emit('close')" class="button-g-icon button-size-36 button-fill-nude">
                <icon name="Cross-Close" />
            </a>
        </div>
    </div> -->


    <!-- 邀请奖励详情 -->
    <div class="invite-info">
        <div class="card-tile">
            <p class="title">待提现</p>
            <div class="card-info">
                <p class="tx-c">{{L(profile?.invite_share_remains)}}元</p>
            </div>
            <div class="card-op">
                <a href="/config/withdrawal" class="tx-a link-text-nude-line">&gt; 申请提现</a>
            </div>
        </div>
        <div class="card-tile">
            <p class="title">我的邀请链接</p>
            <div class="card-info">
                <div class="invite-code">
                    <!-- <p> {{L(profile?.referral_link)}}</p> -->
                    <p>{{inviteURL(useAccount().a?.referral_code)}}</p>
                    <icon name="Copy" @click="copyShareLink" />
                </div>
            </div>
            <div class="card-op">
                <router-link to="/help?t=qa#qa-invite-prize" class="tx-a link-text-nude-line">
                    &gt; 查看邀请规则
                    <span class="weight500 color-purple">(20%返利)</span>
                </router-link>
            </div>
        </div>
        <div class="card-tile">
            <p class="title">已邀请</p>
            <div class="card-info">
                <p class="tx-c">{{L(profile?.invited_user_count)}}人</p>
            </div>
            <div class="card-op">
                <p class="tx-b">历史总获得奖励{{L(fixNumber(profile?.invite?.invite_amount, 2))}}元，已提现{{L(fixNumber(profile?.withdrawal_total, 2))}}元。</p>
            </div>
        </div>
    </div>

    
    <Component :is="ReferralList" />



</div>

</template>