import {defineStore} from "pinia"
import {ref, computed} from "vue"
import {apiFetch, apiURL, duration2Readable, apiFetchRaw} from "@/common"
import {base64ToUint8Array} from "@/common2"
import {setNamespace} from "@/namespace"
import {CBStore} from "@/cbstore"
import type { VAPIDUnregisterOptions } from "@/types"
import { set } from "nprogress"
import {notify} from "@kyvg/vue3-notification"

export const useLoginForm = defineStore('loginForm', {
    state: () => ({
        username: '',
        password: '',
    })
})



// 在全局范围内记录用户使用的邀请码
// 在 Hi 页面会自动持久性地保存邀请码，用户即使关闭了页面，也能追踪到
interface IUseInvite {
    inviteCode :string
    expireTimeMs :number
}
export const useInvite = defineStore("invite", () => {
    const KEY = "chatboy_referral_code"

    const inviteCode = computed(() :string => {
        let c = localStorage.getItem(KEY)
        if (c === undefined || c === null) {
            return ""
        } else {
            let j :IUseInvite = {inviteCode: "", expireTimeMs: 0}
            try {
                j = JSON.parse(c)
            } catch(e) {
                // localStorage.setItem(KEY, JSON.stringify(<IUseInvite>{}))
                console.warn(`本地存储中的 ${KEY} 不是合法的 JSON`)
            }
            if (j.expireTimeMs > new Date().getTime()) {
                return j.inviteCode
            } else {
                return ""
            }
        }
    })

    function setInviteCode(code: string) {
        let j :IUseInvite = {
            inviteCode: code,
            expireTimeMs: new Date().getTime() + 7 * 86400 * 1000   // 七日后过期
        }
        let jstr :string = JSON.stringify(j)
        localStorage.setItem(KEY, jstr)
    }

    return {inviteCode, setInviteCode}
})


export const useAccount = defineStore('account', () => {
    const a = ref(<any>undefined) 

    // 本地缓存的用户信息（用户可能尚未登录）
    // 如果存在 a 数据，则直接使用此数据，否则返回 localStorage 中的 chatboy_current_user 数据
    // localStorage 中的 chatboy_current_user 在以下几种情况会被清除：
    //  - 在执行退出操作后
    //  - 在 reload 加载到空的用户信息时
    const cached = computed(() :any | undefined => {
        if (a.value) {
            return a.value
        }
        let l = localStorage.getItem("chatboy_account_cached")
        if (l) {
            try {
                return JSON.parse(l)
            } catch(e) {
                return undefined
            }
        }
        return undefined
    })
    
    // 是否已经从服务器加载了用户信息（无论是已登录还是未登录）
    // 但服务器返回错误的情况下，仍认为未加载用户信息（除非服务器明确表示用户未登录，即 code == -1）
    const isLoaded = ref(false)

    const isLoggedIn = computed(() :boolean => {
        if (cached.value === undefined) {
            return false
        } else {
            return true
        }
    })

    // 向 API 重新请求已登录用户的数据。如果用户已经退出登录，则会清空登录用户信息
    async function reload() {
        // console.log("useAccount().reload() 开始执行", a.value)

        try {
            let resp = await apiFetchRaw("/goapi/current_account", {})
            let rawj = await resp.json()

            

            if (rawj.code == 0) {
                // 服务器正常返回
                isLoaded.value = true
            } else if (rawj.code == -1) {
                // 用户未登录
                isLoaded.value = true

                console.log("服务器明确表示此用户尚未登录，删除本地用户数据（通过 logout() 方法删除）")
                logout()
                return
            } else if (rawj.code == -4) {
                // 账户被封禁
                notify({type: "error", text: rawj.message, duration: 99999 * 1000})
            } else {
                // 其他错误代码，我们不认为已经加载了用户信息
                console.warn("服务器返回错误代码，我们认为用户信息未能成功加载", rawj)
                return
            }

            let j = rawj.data
            if (j) {
                a.value = j

                console.debug("更新了一次已登录用户信息", j)
                localStorage.setItem("chatboy_current_user", JSON.stringify(j.account))
                localStorage.setItem("chatboy_account_cached", JSON.stringify(j))
                setNamespace(j.account.id)

                // 刷新红点数据
                // console.log("设置红点数据", j?.red_dots || {})
                useRedDot().raw = j?.red_dots || {}
                // console.log("设置红点数据完成", useRedDot().raw.value)
            }
        } catch(e) {
            console.debug("未能从远程加载用户信息", e, a.value)
        }
    }

    function avatar(useCached :boolean = false) :string {
        
        const account = useCached ? cached : a
        if (account.value?.account) {
            if (account.value?.account.avatar_hash != "") {
                return apiURL("/goapi/avatar/hash/" + account.value?.account.avatar_hash)
                // return apiURL("/goapi/avatar/id/" + a.value.account.id)
            } else {
                return apiURL("/goapi/avatar/id/" + account.value.account.id)
            }
        } else {
            return "/demo/avatar-guest.png"
        }
    }

    const remainingDays = computed(() => {
        if (!a.value?.account) {
            return "--"
        }

        let ts = new Date().getTime() / 1000
        // console.log("currentaccount 是", currentAccount)
        let ret = a.value?.account?.expire_time
        let duration = ret - ts
        if (duration < 0) {
            return 0
        }

        if (duration > 86400 * 365 * 5 ) {
            return "∞"
        }

        if (duration > 10) {
            return duration2Readable(duration, 0)
        } else {
            return duration2Readable(duration, 1)
        }
    })

    const whatsNew = computed(() :string[] => {
        if (!a.value) { 
            return []
        }
        if (!a.value?.whats_new) {
            return []
        } else {
            return a.value.whats_new
        }
    })

    function logout() {
        console.log("执行退出操作")
        
        localStorage.removeItem("chatboy_current_user")
        localStorage.removeItem("chatboy_account_cached")
        localStorage.removeItem("chatboy_session_key")
        

        // 切换本地聊天记录命名空间（用户退出之后就不显示本地聊天数据）
        setNamespace("")

        a.value = undefined
        isLoaded.value = false
    }

    return {account: a, a, cached, reload, avatar, logout, remainingDays, isLoggedIn, isLoaded, whatsNew}
})


export type ThemeType = "auto" | "light" | "dark"
export type SystemThemeType = "light" | "dark"

export const usePreference = defineStore('preference', () => {
    const p = ref(<any>undefined)
    let isLoaded = false

    // 这个变量只用来触发 subscribe 监听事件而已，没有其他作用
    const _theme = ref(<ThemeType|undefined>undefined)

    // 用户选择的主题模式（白天 或 黑夜）
    // 保存在 localStorage 里面
    const theme = computed({
        get: () :ThemeType => {
            if (_theme.value === undefined) {
                // 从 localStorage 中加载
                let t = localStorage.getItem("chatboy.preference.theme")
                if (t == "auto" || t == "light" || t == "dark") {
                    console.log("加载到 t 是", t)
                    _theme.value = t
                } else {
                    _theme.value = "auto"
                }
                return _theme.value
            } else {
                return _theme.value
            }
        },
        set: (val :ThemeType) => {
            console.log("设置了 t 是", val)
            localStorage.setItem("chatboy.preference.theme", val)
            _theme.value = val
        }
    })

    // 用户当前系统设置的模式（是否是黑夜模式）
    function systemTheme() :SystemThemeType {
        try {
            const media = window.matchMedia('(prefers-color-scheme: dark)')
            if (media.matches) {
                return "dark"
            } else {
                return "light"
            }
        }catch(e) {
            console.warn("无法获取当前系统黑夜模式设置信息")
        }

        return "light"
     }

     // 当前应该显示的模式（light 或 dark）
     // 根据用户设置（auto | light | dark）以及系统当前黑夜模式的设置，计算得到的最终应该使用的模式
     function computedTheme() :SystemThemeType {
        if (theme.value == "auto") {
            return systemTheme() 
        } else {
            return theme.value
        }
     }

     // 切换主题（切换到日间或夜间）
     // 自动根据当前的 computedTheme，切换到相反的主题
     function switchTheme() {
        console.log("切换主题")
        if (computedTheme() == "dark") {
            theme.value = "light"
        } else {
            theme.value = "dark"
        }
     }

    function update(name :string, val :any) :Promise<any> {
        return new Promise((resolve, reject) => {
            apiFetch("/goapi/preference/update", {
                method: "post",
                body: JSON.stringify({
                    Name: name,
                    Value: val,
                })
            }).then(j => {
                p.value[name] = val
                resolve(j)
            }).catch(e => {
                reject(e)
            })
        })
    }

    async function reload() {
        try {
            let j = await apiFetch("/goapi/preference/read", {})
            // p.patch(j.Preference)
            if (j.Preference.ChatFontSize == "") {
                j.Preference.ChatFontSize = "12px"
            }
            p.value = j.Preference
            isLoaded = true
            // console.debug("成功载入设置信息", p.value)
        } catch(e) {
            console.warn("载入设置信息失败", e)
        }
    }

    // 如果目前还没有成功加载配置信息，则进行一次加载
    async function ensure() {
        if (isLoaded == false) {
            await reload()
        }
    }

    // 返回 SendMessageKey 属性
    // 默认情况下（未加载数据时），使用 Ctrl+Enter 发送（SendMessageKey == 3）
    function sendMessageKey() :number {
        if (p.value?.SendMessageKey === 1) {
            return 1
        } else {
            return 3
        }
    }

    return {p, update, reload, ensure, sendMessageKey, theme, _theme, systemTheme, computedTheme, switchTheme}
})



export const useModalState = defineStore("modalState", () => {
    const isShowRedeemCode = ref(false)
    const isShowLogin = ref(false)
    const isShowConfig = ref(false)
    const isShowMobileMenu = ref(false)
    const isShowInvite = ref(false)
    const isShowPersonal = ref(false)
    const isShowDaily = ref(false)
    const isShowDailyShareLink = ref(false)
    const isShowSuggestion = ref(false)

    return {isShowRedeemCode, isShowLogin, isShowConfig,
        isShowMobileMenu, isShowInvite, isShowPersonal,
        isShowDaily, isShowDailyShareLink, isShowSuggestion }
})



export const useDaily = defineStore("daily", () => {
    const d = ref(<any>[])  // daily 的缩写
    const points = ref(<any>{})
    
    const total = computed(() :number => {
        return d.value.length;
    })
    const finishCount = computed(() :number => {
        let f :number = 0
        for (let i = 0; i < d.value.length; i++) {
            if (d.value[i].IsFinish) {
                f++
            }
        }
        return f
    }) 

    async function reload() {
        try {
            let j = await apiFetch("/goapi/daily/today", {})
            d.value = j.daily
            points.value = j.points
        } catch(e) {
            console.warn("载入每日任务信息失败", e)
            throw e
        }

    }

    return {d, points, reload, finishCount, total}
})

export const useRedDot = defineStore("redDot", () => {
    const raw = ref(<any>{})

    const pendingBonus = computed({
        get: () :number => {
            if (raw.value?.pending_bonus) {
                return raw.value.pending_bonus
            } else {
                return 0
            }
        },
        set: (val :number) => {
            raw.value.pending_bonus = val
        }
    })

    return {pendingBonus, raw}
})



export const useTokenRequirement = defineStore("tokenRequirement", () => {
    type TokenRequirementType = {
        ModelMultiplier: any,
        MJImage: any,
    }

    const r = ref(<TokenRequirementType|undefined>{})
    const isLoaded = ref(false)
    

    async function get() :Promise<TokenRequirementType | undefined>{
        if (isLoaded.value == false) {
            try {
                let j = await apiFetch("/goapi/token_requirement", {})
                r.value = j
                isLoaded.value = true
            } catch (e) {
                console.warn("加载点数消耗规则失败", e)
            } 
        }

        return r.value
    }

    return {get}
})

export const useWebPush = defineStore("notification", () => {
    const SERVICE_WORKER = "/webpush.js"

    var serviceWorkerRegistration :ServiceWorkerRegistration

    // 是否正在注册/注销推送通知
    const isLoading = ref(false)


    // 是否支持推送
    const isSupport = computed(() => {
        if (!navigator.serviceWorker) {
            return false
        }
        if (!window.PushManager) {
            return false
        }
        return true
    })


    async function getServiceWorker() {
        console.debug("准备注册 WebPush 的 SERVICE_WORKER")
        serviceWorkerRegistration = await navigator.serviceWorker.register(SERVICE_WORKER)
        console.debug("WebPush 的 ServiceWorker 注册成功", serviceWorkerRegistration)
        return serviceWorkerRegistration
    }

    async function getVAPIDPublicKey() {
        let pubkey = localStorage.getItem("chatboy.vapid.public_key")
        if (!pubkey) {
            let j = await apiFetch("/goapi/vapid/public_key", {})
            if (j.PublicKey) {
                localStorage.setItem("chatboy.vapid.public_key", j.PublicKey)
                return j.PublicKey
            } else {
                return ""
            }
        }

        return pubkey
    }

    async function getSubscription() {
        let sw = await getServiceWorker()
        let sub = await sw.pushManager.getSubscription()
        if (!sub) {
            sub = await sw.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: base64ToUint8Array(await getVAPIDPublicKey()),
            })
        }

        return sub
    }

    async function register() {
        try {
            isLoading.value = false

            // 1. 请求权限
            console.debug("准备请求 WebPush 订阅权限")
            let sub = await getSubscription()

            // 2. 注册到服务器
            console.debug("准备将 WebPush 注册信息提交到服务器")
            let j = apiFetch("/goapi/vapid/register", {
                method: "post",
                body: JSON.stringify({
                    Subscription: sub,
                }),
            })

            // 3. 写入本地缓存
            console.debug("准备将 WebPush 订阅信息写入本地缓存")
            subscription.value = JSON.stringify(sub)
        } catch(e) {
            console.warn("注册推送通知失败", e)
            throw e
        } finally {
            isLoading.value = false
        }
        
    }


    async function unregister(opts?: VAPIDUnregisterOptions) {
        try {
            isLoading.value = true

            // 1. 删除权限
            console.debug("准备注销 WebPush")
            let sw = await getServiceWorker()
            let sub = await sw.pushManager.getSubscription()

            if (!sub) {
                console.debug("目前没有 WebPush 注册，不需要再次撤销注册, sub = ", sub)
                subscription.value = ""
                return
            }

            await sw.unregister()

            // 2. 删除本地缓存
            console.debug("准备删除本地 WebPush 订阅缓存")
            subscription.value = ""

            // 3. 从服务器删除
            await apiFetch("/goapi/vapid/unregister", {
                method: "post",
                body: JSON.stringify({
                    Subscription: sub,
                    Reason: opts?.reason || "",
                    UnregisterAll: opts?.all || false,
                }),
            })

            
        } catch(e) {
            console.warn("撤销注册推送通知失败", e)
            throw e
        } finally {
            isLoading.value = false
        }
    }

    // 使用远程数据同步本地数据，包括以下操作：
    // * 如果本地存在注册信息，则向服务器查询此注册信息是否存在，若不存在，则删除本地注册信息
    const isSynced = ref(false)
    async function sync(force :boolean = false) {
        if (isSynced.value && !force) {
            console.debug("已经调用过 useWebPush().sync()，不会重新调用")
            return
        }

        if (!subscription.value){ 
            console.debug("目前本地没有 WebPush 注册信息，不需要 sync()")
            return
        }

        if (subscription.value) {
            console.debug("本地存在 WebPush 注册信息，向服务器查询此信息是否有效", subscription.value)
            try {
                isLoading.value = true

                const j =JSON.parse(subscription.value)
                if (j.keys?.auth) {
                    let res = await apiFetch(`/goapi/vapid/valid/${j.keys.auth}`)
                    if (res.IsValid === false) {
                        const reason = `服务器明确返回此 WebPush (${j.keys?.auth}) 信息是无效/过期/已删除的，删除本地注册`
                        console.debug(reason)
                        await unregister({reason: reason, all: false})
                    }
                }
            } catch(e) {
                console.warn(e)
            } finally {
                isLoading.value = false
                isSynced.value = true
            }
        }
    }

    const _sub = ref(<string | undefined>undefined)
    const subscription = computed({
        set: (val :string) => {
            _sub.value = val
            if (val) {
                console.log("设置 subscription")
                localStorage.setItem("chatboy.webpush.subscription", val)
            } else {
                console.log("删除 subscription")
                localStorage.removeItem("chatboy.webpush.subscription")
            }
        }, 
        get: () :string => {
            if (_sub.value !== undefined) {
                return _sub.value
            }

            const val = localStorage.getItem("chatboy.webpush.subscription")
            if (!val) {
                _sub.value = ""
                return _sub.value
            }
            try {
                _sub.value = val
                return _sub.value || ""
            } catch(e) {
                console.warn("本地保存的 chatboy.webpush.subscription 数据不正确")
                return ""
            }
        },
    })
    
    return {register, unregister, sync, isLoading, subscription}
})


export const useChatSideBar = defineStore("useChatSideBar", () => {
    // 是否正处于对话排序状态
    const isOrdering = ref(false)

    return {isOrdering}
})