<script setup lang="ts">

import {useAccount} from "@/store"
import {usePreference} from "@/store"
import {useRouter} from "vue-router"

import {L, notifySuccess} from "@/common2"

const emit = defineEmits(['close'])

const Preference = usePreference()
const router = useRouter()

function doLogout() {
    useAccount().logout()

    emit('close')

    // window.location.reload()
    notifySuccess("已退出登录")
    router.push("/login")
}


</script>
<template>
<div>
    <!-- 已登录 -->
    <div class="flex-col-left flex-gap12 width100" v-if="useAccount().cached">
        <div v-if="useAccount().cached?.current_plan_name" class="flex-row-between width100 padding-bottom-12 g-gapline-pale">
            <div class="flex flex-gap12 font14">
                <p class="label">
                    <span>{{useAccount().cached?.account.display_name}}</span> 
                    的订阅
                </p>
                <p>{{useAccount().cached.current_product?.Name}}</p>
            </div>
            <div class="button-g button-xsmall button-fill-nude button-border-black">
                <router-link to="/shop" @click="$emit('close')">续费</router-link>
                
            </div>
        </div>
        <div v-else class="flex-row-between width100 padding-bottom-12 g-gapline-pale">
            <div class="flex flex-gap12 font14">
                <p class="label">
                    目前没有订阅
                </p>
            </div>
            <div class="button-g button-xsmall button-fill-nude button-border-black">
                <router-link to="/shop"  @click="$emit('close')">去订阅</router-link>
            </div>
        </div>

        <div class="flex flex-gap12 font14 padding-bottom-16">
            <p class="label">点数用量</p>
            <p>
                {{L(useAccount().cached?.account?.token_used.toLocaleString())}}
                / {{L(useAccount().cached?.account?.token_limit.toLocaleString())}} 点
                <span>({{useAccount().remainingDays}}后到期)</span> 
            </p>
        </div>
        
        <div class="flex-row-between flex-gap16 width100">
            <div class="flex-row-left flex-gap8">
                <div class="button-g animation-slide-switch"
                    :class="{'active': Preference.computedTheme() == 'dark'}"
                    @click="Preference.switchTheme"
                >
                    <div class="slide-ctrl" >
                        <icon name="Moon" />
                    </div>
                    <div class="slide-ctrl active">
                        <icon name="Sun" />
                    </div>
                </div>
                <div class="button-g" @click="$router.push('/config/personal'); $emit('close')">
                    <icon name="Pencil-Edit" />
                    编辑个人资料
                </div>
            </div>

            <div v-if="useAccount().cached" class="button-g" @click="doLogout">
                <icon name="Logout" />
                登出
            </div>
    <!--         
            <div v-else class="button-g flex-1" @click="isShowLogin=true; $emit('close')">
                <icon name="Login" />
                登录
            </div> -->
            
            <div v-else class="button-g flex-1" @click="$router.push('/login'); $emit('close')">
                <icon name="Login" />
                登录账户
            </div>
        </div>
    </div>

    <!-- 未登录 -->
    <div v-else class="flex-col-left flex-gap12 width100">
        <div class="flex flex-gap16 width100">
            <div class="button-g flex-1" @click="$router.push('/login'); $emit('close')">
                去登录
            </div>
        </div>
    </div>


</div>
</template>