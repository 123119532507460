<script setup lang="ts">
import { onUnmounted, onMounted, watch} from "vue"
import {apiFetch} from "../common"
import {ref, computed} from "vue"
import {mobileModalFadeIn, mobileModalFadeOut, slideDownIn, slideUpIn, slideUpOut, fadeOut, fadeIn, slideDownOut} from "@/animation"

// 不引入 Icon 的话似乎 <icon> 就无法正确显示出来
import Icon from "@/comps/Icon.vue"

const shouldShow = ref(false)

const emit = defineEmits(["close"])
 
const props = withDefaults(defineProps<{
    isShow :boolean,
    position?: 'top' | 'bottom' | "center",
    title? :string,
    icon? :string,
    hideIcon? :boolean,
    hideOKButton? :boolean,     // 是否隐藏底部的确认按钮
    hideHeader? :boolean,       // 是否隐藏头部（图标、标题、标签烂、关闭按钮）
    tabs? :string[],
    
}>(), {
    isShow: false,
    position: 'bottom',
    title: "",         
    icon: "Brush",    //  默认使用 Brush 图标
    hideIcon: false,
    hideOKButton: true,
    hideHeader: false,
    // tabs: [],
})


const activeTab = ref("")
if (props.tabs && props.tabs.length > 0) {
    activeTab.value = props.tabs[0]
}


onMounted(() => {
    shouldShow.value = props.isShow
})

function onEnter(el :any, done: any) {
    if (props.position == 'top') {
        slideDownIn(el, done)
    } else if (props.position == 'bottom') {
        slideUpIn(el, done)
    } else {
        fadeIn(el, done)
    }
}
function onLeave(el :any, done: any) {
    if (props.position == 'top') {
        slideUpIn(el, done)   
    } else if (props.position == 'bottom') {
        slideUpOut(el, done)
    } else {
      fadeOut(el, done)  
    }
}

const tabs = computed(() :string[] => {
    if (props.tabs) {
        return props.tabs
    } else {
        return []
    }
})



watch(props, (newVal) => {
    shouldShow.value = newVal.isShow
})
</script>



<template>
    <Transition @enter="fadeIn" @leave="fadeOut">
    <div class="popup" @click.self="emit('close')" v-show="shouldShow">
        <!--
        <div class="overlay-bg" @click="emit('close')" ></div>
        -->

        <Transition @enter="onEnter" @leave="onLeave">
        <div class="popup-ctrl" v-if="shouldShow"> 

            <div class="popup-title-section" v-if="!props.hideHeader">
                <div class="left-part">
                    <div class="icon-ctrl" v-if="!props.hideIcon">
                        <icon :name="props.icon" />
                    </div>
                    <h3 class="title">{{props.title}}</h3>
                </div>


                <div class="center-part" v-if="tabs.length > 0">
                    <div class="g-nav-tab-popup-top">
                        <a
                            v-for="tab in tabs"
                            :class="{'active': activeTab == tab}"
                            @click="activeTab = tab"
                        >{{tab}}</a>
                        <!-- <a href="">注册用户</a> -->
                    </div>  
                </div>


                <div class="right-part">
                    <a @click.prevent="emit('close')" class="button-g-icon button-size-36 button-fill-nude">
                        <icon name="Cross-Close" />
                    </a>
                </div>
            </div>



            
            <!-- 默认插槽 -->
            <div class="popup-content-section" v-if="tabs.length == 0">
                <slot />
            </div>


            <!-- Tab 插槽 -->
            <TransitionGroup @enter="fadeIn" leave-active-class="leave-active">
            <div v-for="tab in tabs" class="popup-content-section" v-show="activeTab == tab" :key="tab">
                <slot :name="tab"/>
            </div>
            </TransitionGroup>

            <div class="popup-content-bottom" v-if="!props.hideOKButton">
                <a href="" class="button-g-3d button-medium" @click.prevent="emit('close')">
                    <div class="ctrl padding-h-40">知道啦</div>
                </a>   
            </div>                 
            
        </div>
        </Transition>

    </div>
    </Transition>

     <!--备份
        <div className="popup-overlay" @click.self="emit('close')">
            
            <div className="popup-wrap">
                <div className="popup-type-a">
                <div className="title-box">
                    <p className="popup-title">{{title}}</p>
                    <a className="button-type-a button-close btn-xsmall" @click.prevent="emit('close')"></a>
                </div>
                <div className="info">
                    <slot />
                    <a v-if="!hideOKButton" href="" className="button-type-a button-gotit" @click.prevent="emit('close')"></a>
                </div>
                </div>
            </div>
        </div>
        -->
</template>


<style scoped>
.leave-active {
    display: none;
}
</style>