export class CBWebSocket {
    onConnectHandlers = <Function[]>[]
    onDisconnectHandlers = <Function[]>[]
    onMessageHandlers = <Function[]>[]

    eventListeners = <{name:string, f:any}[]>[]

    endpoint :string

    ws: WebSocket | null = null

    retryInterval: number = 1000

    CID: number = 0

    constructor(endpoint: string) {
        this.endpoint = endpoint
        // this.connect()
    }

    send(msg :any) {
        if (this.ws && this.ws.readyState !== this.ws.OPEN) {
            console.warn("WS 连接已经断开，将重连, readyState=", this.ws?.readyState)
            this.connect()
            return
        }

        try {
            this.ws?.send(msg)
        } catch (e) {
            console.warn("发送 WS 消息失败，将重连")
            this.connect()
        }
    }

    addEventListener(name: string, f :any) {
        // if (name == "connect") {
        //     this.onConnectHandlers.push(f)
        // } else if (name == "disconnect") {
        //     this.onDisconnectHandlers.push(f)
        // } else if (name == "message") {
        //     this.onMessageHandlers.push(f)
        // } else {
        //     console.warn(`无法监听事件，事件名 '${name}' 不存在`)
        // }
        this.ws?.addEventListener(name, f)
        this.eventListeners.push({name, f})
    }

    removeEventListener(name: string, f: any) {
        // let hs: Function[] | null = null

        // if (name == "connect") {
        //     hs = this.onConnectHandlers
        // } else if (name == "disconnect") {
        //     hs = this.onDisconnectHandlers
        // } else if (name == "message") {
        //     hs = this.onMessageHandlers
        // } else {
        //     console.warn(`无法移除监听事件，事件名 '${name}' 不存在`)
        // }

        // if (hs) {
        //     hs = hs.filter(v => v != f)
        // }
        this.ws?.removeEventListener(name, f)
        this.eventListeners = this.eventListeners.filter(v => {
            return v.name != name || v.f != f
        })
    }

    connect() {
        if (this.endpoint == "") {
            return
        }

        try {
            console.debug("因请求 connect()，将关闭现有的连接（若有）", this.ws)
            this.close()
        } catch (e) {
            console.warn(e)
        }
        
 
        try {
            let ep = this.endpoint + "?cwscid=" + (++this.CID)
            console.debug("WS 尝试连接到 ", ep)
            this.ws = new WebSocket(ep)
            console.debug("初始化了 WebSocket 对象，WS 当前状态为", this.ws)
            this.ws.addEventListener("error", this.onError.bind(this))
            this.ws.addEventListener("close", this.onClose.bind(this))
            this.eventListeners.forEach(v => {
                this.ws?.addEventListener(v.name, v.f)
            })

            // 添加我们自己的 JSON 消息处理器
            this.ws.addEventListener("message", this.onMessage.bind(this))

        } catch (e) {
            console.warn("初始化 WebSocket 失败", e)
        }
    }

    onError() {
        console.debug("触发了 onError 事件", this.ws)
        this.ws.onclose = null
        // this.retryInterval = this.retryInterval + 1000
        // this.retryInterval = Math.min(this.retryInterval, 120 * 1000)
        // console.log("当前秒", this.retryInterval, this)
        // console.log(`WebSocket 连接出错，将在 ${this.retryInterval / 1000} 秒后尝试重连`, (new Date()))
        // setTimeout(this.connect.bind(this), this.retryInterval)
    }

    onClose() {
        console.debug("触发了 onClose 事件，状态比较", this.ws?.readyState, this.ws?.OPEN, this.ws)
        if (this.ws?.readyState == this.ws?.OPEN) {
            console.debug("主动关闭 WS 连接，不需要重连")
            return
        }

        this.retryInterval = this.retryInterval + 1000
        this.retryInterval = Math.min(this.retryInterval, 120 * 1000)
        console.log(`WebSocket 连接关闭了，将在 ${this.retryInterval / 1000} 秒后尝试重连`)

        
        setTimeout(this.connect.bind(this), this.retryInterval)
    }
    
    onMessage(m :any) {
        try {
            let j = JSON.parse(m.data)
            if (j) {
                this.eventListeners.forEach(v => {
                    if (v.name == "json") {
                        v.f(j)
                    }
                })
            }
        } catch (e) {
            console.warn(e)
        }
    }

    resetRetryInterval() {
        // console.debug("重置 retryInterval 为 1s")
        this.retryInterval = 1000
    }

    close() {
        if (this.ws == null) {
            return
        }

        try {
            this.ws.onclose = null  // 避免 close 操作时重复调用 onClose 方法
            this.ws.removeEventListener("close", this.onClose.bind(this))   
            console.debug("准备关闭一个 ws 连接", this.ws)
            this.ws.close()
        } catch (e) {
            console.warn(e)
        }
    }

}