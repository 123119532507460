<script setup lang="ts">
import { onMounted, ref, defineEmits } from "vue"
import {getChatID, getQuery, newChat} from "../common2"
import {LocalChatStorage} from "../chat_storage"
import LRU from "../lru"
import DailyBonus from "@/comps/DailyBonus.vue"
import MobileHeader from "../comps/MobileHeader.vue"
import LeftBottomBox from "@/comps/LeftBottomBox.vue"

const lru = new LRU("recent_used_tools_v2")

</script>


<template>
<div class="main-sidebar">


    <div class="section section-chat">

        <Component :is="DailyBonus" />

        <div class="section-sub section-sub-chat">
            <router-link to="/tools?t=home" class="sub-tab-b active">
                <div class="info-main">
                    <p class="title">全部工具</p>
                    <div class="sub">
                        <icon name="Menu-Change" />
                        <p class="msg-count">12</p>
                    </div>
                </div>
                <div class="info-sub">
                    <icon name="Arrow-Right" />
                </div>
            </router-link>
            <div class="sub-tab-title-section padding-top-20 padding-bottom-12">
                <icon name="Clock-History-Time" />
                <p class="title">最近使用</p>
            </div>

            
            <router-link :to="v.value?.link || '/'" class="sub-tab-b"
            v-for="v in lru.list()"
            @click="lru.get(v.key)"
            >
            

                <div class="info-main">
                    <p class="title" v-html="v.value.name"></p>
                    <div class="sub">
                        <icon name="Menu-Change" />
                        <p class="msg-count">12</p>
                    </div>
                </div>
                <div class="info-sub">
                    <icon name="Arrow-Right" />
                </div>
            </router-link>
<!-- 
            <a href="#/tools?t=aimage" class="sub-tab-b">
                <div class="info-main">
                    <p class="title">Ai 绘画</p>
                    <div class="sub">
                        <icon name="Menu-Change" />
                        <p class="msg-count">12</p>
                    </div>
                </div>
                <div class="info-sub">
                    <icon name="Arrow-Right" />
                </div>
            </a> -->
        </div>

    </div>

    <Component :is="LeftBottomBox" />

</div>
</template>