<script setup lang="ts">
import MobileHeader from '@/comps/MobileHeader.vue'
import Header from "@/comps/Header.vue"
import LoginRequired from "@/comps/LoginRequired.vue"
import {useAccount} from "@/store"
import {onBeforeMount, computed, ref} from "vue"
import {useRouter, useRoute} from "vue-router"

// const isShow = ref(false)

// const isShow = computed(() => {
//     return useAccount().a
//     if (useAccount().a) {
//         isShow.value = true
//     }
// })

const router = useRouter()

const page = computed(() => {
    return router.currentRoute.value.path.split("/").slice(-1)[0]
})

</script>

<template>


<div class="g-container">
    
    <Component :is="Header" />
    <Component :is="MobileHeader" /> 

    <div class="main-container">
                
        <!-- 侧边栏 -->
        <router-view></router-view>

        <!-- 内页 -->
        <router-view name="innerPage" v-if="useAccount().a"></router-view>
        <Component v-else :is="LoginRequired" />
    </div>



    <!-- 移动端：底部导航 -->
    <div class="mobile-navtab">
        <router-link to="/config/chat" class="nav-tile" :class="{'active': page=='chat'}">
            <icon name="Conversation" />
            <p class="tx">对话设置</p>
        </router-link>
        <router-link to="/config/advance" class="nav-tile"  :class="{'active': page=='advance'}">
            <icon name="Puzzle" />
            <p class="tx">高级设置</p>
        </router-link>
        <router-link to="/config/personal" class="nav-tile" :class="{'active': page=='personal'}">
            <icon name="Setting-User" />
            <p class="tx">个人资料</p>
        </router-link>
        <router-link to="/config/withdrawal" class="nav-tile" :class="{'active': page=='withdrawal'}">
            <icon name="Help-Risehand" />
            <p class="tx">申请提现</p>
        </router-link>
    </div>

</div>

</template>