<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import {apiFetch, writeClipboard} from "@/common"
import {apiURL} from "@/common"
import {notify} from "@kyvg/vue3-notification"

const url = ref("")

onMounted(async () =>  {
    try {
        let j = await apiFetch("/goapi/daily/share_link")
        if (j.RelativeURL) {
            url.value = j.RelativeURL
        } else {
            console.warn("API 返回的数据是", j)
            throw Error("API 返回的数据不正确")
        }
    } catch(e) {
        notify({type: "error", text: "获取分享链接失败"})
        console.warn(e)
    }    
})

function copy() {
    writeClipboard(shareText.value)
    notify({type: "success", text: "分享链接已复制到剪贴板"})
}

const shareText = computed(() => {
    if (url.value == "") {
        return ""
    } else {
        return `我正在用 ChatBoy Ai，你也来试试吧 ` + apiURL(url.value)
    }
})

</script>

<template>
<div>
    <div v-if="shareText != ''">{{shareText}}</div>
    <div v-else>正在加载...</div>

    <div v-if="shareText != ''" class="button-g" @click="copy">复制链接</div>

    

</div>

</template>