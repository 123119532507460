<script setup lang="ts">
import "../../src/assets/page.scss"
import Icon from "../comps/Icon.vue"
import { useRoute} from "vue-router"
import {onMounted, computed, ref} from "vue"
import {apiFetch, writeClipboard, apiURL} from "@/common"
import {inviteURL} from "@/common2"
import {useAccount, useInvite} from "@/store"
import {notify} from "@kyvg/vue3-notification"
import {useCookies} from "vue3-cookies"

const Account = useAccount()
const {cookies} = useCookies()

const theInviter = ref({})


onMounted(() => {
    loadInviter()

    // 注意，当页面为 /hidaily/:inviteCode 时，inviteCode 参数实际上是每日任务的 LinkName
    let code = useRoute().params.inviteCode
    if (code) {
        useInvite().setInviteCode(code as string)
    }

    // 访问 Hi 页面后，我们设置一个名为 hiref 的饼干
    // 注册页面会检查是否存在 hiref 饼干，若存在，则使用此饼干的值作为邀请码（例如 2307ref）
    cookies.set("hiref", "2307refv2", 86400)
})

// 判断当前页面是否是一个每日任务链接
function isDaily() :boolean {
    if (window.location.href.indexOf("/hidaily/") >= 0) {
        console.debug("当前页面是一个每日任务链接")
        return true
    } else {
        console.debug("当前页面不是一个每日任务链接")
        return false
    }

}

const inviter = computed(() => {
    let ret = <any>{
        ID: 0,
        AvatarHash: "",
        DisplayName: "",
    }
    return Object.assign(ret, theInviter.value)
})

async function loadInviter() {
    let url = "/goapi/account/inviter?invite_code=" + useRoute().params.inviteCode
    if (isDaily()) {
        url = "/goapi/account/inviter?daily_link_name=" + useRoute().params.inviteCode
    }
    try {
        let j = await apiFetch(url, {})
        theInviter.value = j.Inviter
        console.log("获得 ", j.Inviter)
    } catch (e) {
        console.log(e)
    }
}

const avatarURL = computed(() =>{
    if (inviter.value.ID > 0) {
        return apiURL("/goapi/avatar/id/" + inviter.value.ID)
    } else {
        return "/demo/avatar-guest.png"
    }
})

async function copy(s :string) {
    try {
        await writeClipboard(s)
        notify({type: "success", "text": "邀请链接已复制到剪贴板"})
    } catch (e){ 
        console.warn(e)
    }
}

async function copyLink() {
    copy(inviteURL(Account.a.referral_code))
}

</script>


<template>
<div>

    <div class="header"> 
        <div class="main-info">
            <router-link to="/" class="logo">
                <icon name="Logo-Chatboy" class="avatar" />
                <h1>ChatBoy.io</h1>
            </router-link>
        </div>
    </div>


    <div class="hi-page">

        <!-- 底部邀请信息浮窗 -->
        <div class="fix-bottom-section">
            <div class="sec-ctrl">
                <div class="invite-info">
                    <div class="avatar" :style="`background-image: url('${avatarURL}');`"></div>
                    <p class="user-info">
                        <span class="name">{{inviter.DisplayName}}</span> 
                        <span class="tx">邀请你免费体验 ChatBoy</span>
                    </p>
                </div>

                <!-- 分享给其他朋友 -->
                <div class="share-button" v-if="Account.a?.account">
                    <div class="ctrl" @click="copyLink">
                        <icon name="Share-Arrow" />
                        分享给其他朋友
                    </div>
                </div>
            </div>
        </div>

        <div class="page-sec hero-sec">
            <img src="/logo-avatar-withbg.svg" alt="ChatBoy Icon" class="logo" />

            <div class="hero-title">
                <!--测试，先注释
                <div class="bg-blend-wrap">
                    <video loop autoplay muted playsinline style="background-image:url(https://assets-global.website-files.com/61f62d1cc76f20840570a980/61fd8414cb72a82c11b509f0_refrac_animation_AM_01-poster-00001.jpg)" >
                    <source src="https://assets-global.website-files.com/61f62d1cc76f20840570a980/61fd8414cb72a82c11b509f0_refrac_animation_AM_01-transcode.mp4" data-wf-ignore="true">
                    <source src="https://assets-global.website-files.com/61f62d1cc76f20840570a980/61fd8414cb72a82c11b509f0_refrac_animation_AM_01-transcode.webm" data-wf-ignore="true">
                    </video>
                </div>
                -->

                <h1>ChatBoy.io</h1>
                <icon name="Splash-Line-G1" class="tape-splash" />
            </div>

            <p class="tx-a text-align-center">
                做个效率超人
                <br/>
                试试让人欲罢不能的 Ai 工具
            </p>
            <p class="tx-b padding-bottom-24">打开手机就可以玩，Ai 没有那么难的啦</p>
            <router-link to="/chat">
                <div class="button-g-3d button-medium width200">
                    <div class="ctrl">
                        开始免费体验
                    </div>
                </div>
            </router-link>
        </div>

        <div class="page-sec intro-sec">
            <div class="info-main">
                <div class="title">
                    <icon name="Feather-Pen-Edit" />
                    <div class="sub">
                        <h3>Ai 写作搭载最强模型</h3>
                        <icon name="Splash-Line-S-G1" class="tape-splash-small" />
                    </div>
                </div>
                <p class="tip">玩过都说好，日常生产力UP，家居摸鱼必备</p>
            </div>
            <div class="info-sub">
                <img src="/demo/cutie-1.png" alt="Ai Graphic" class="cutie kid">
                <img src="/demo/page1.png" alt="ChatBoy Screenshot" class="bg-screenshot">
            </div>
        </div>

        <div class="page-sec intro-sec">
            <div class="info-main">
                <div class="title">
                    <icon name="Brush" />
                    <div class="sub">
                        <h3>Ai 绘画释放无限想象力</h3>
                        <icon name="Splash-Line-S-G2" class="tape-splash-small" />
                    </div>
                </div>
                <p class="tip">小白也敢搞创作，下班时间变身灵感大师</p>
            </div>
            <div class="info-sub">
                <img src="/demo/cutie-2.png" alt="Ai Graphic" class="cutie girl">
                <img src="/demo/page2.png" alt="ChatBoy Screenshot" class="bg-screenshot">
            </div>
        </div>

        <div class="page-sec intro-sec">
            <div class="info-main">
                <div class="title">
                    <icon name="Game-Arcade" />
                    <div class="sub">
                        <h3>更多好玩「工具」等你发掘</h3>
                        <icon name="Splash-Line-S-G3" class="tape-splash-small" />
                    </div>
                </div>
                <p class="tip">无限更新的工具库，随时玩耍最新鲜的 Ai 内容</p>
            </div>
            <div class="info-sub">
                <img src="/demo/cutie-3.png" alt="Ai Graphic" class="cutie boy">
                <img src="/demo/page3.png" alt="ChatBoy Screenshot" class="bg-screenshot">
            </div>
        </div>

        <div class="page-sec">
            <div class="info-main">
                <div class="title">
                    <icon name="Conversation" />
                    <div class="sub">
                        <h3>看看大家怎么用</h3>
                    </div>
                </div>
                <icon name="Emoji-Gogogo"  class="emoji-gogogo"/>
            </div>
            <div class="info-sub memo">
                <div class="col">
                    <div class="memo-card">
                        <div class="user">
                            <div class="info">
                                <div class="avatar" style="background-image: url('/demo/avatar-01.png');"></div>
                                <p class="name">Devin</p>
                            </div>
                            <p class="info-tx">ChatBoy Team</p>
                        </div>
                        <div class="memo">
                            <icon name="Quote" />
                            <p class="tx">已经离不开日常生活了！我日常遇到看不懂的单词我一定会来ChatBoy查，遇到一些比如买什么打印机比较适合设计师我也会查ChatBoy，现在做网页和设计需要用到的占位图我一定会用Ai绘画生成，甚至是3D图标哦。比起一般用户，我自己感受是更加离不开ChatBoy了。</p>
                        </div>
                    </div>
                    <div class="memo-card">
                        <div class="user">
                            <div class="info">
                                <div class="avatar" style="background-image: url('/demo/avatar-cj.jpg');"></div>
                                <p class="name">CJ</p>
                            </div>
                            <p class="info-tx">自由插画师/设计师</p>
                        </div>
                        <div class="memo">
                            <icon name="Quote" />
                            <p class="tx">作为一名设计师，文案一直是自己的弱项。最近在ChatBoy的帮助下，在提交方案的时候文案力提升了不少。重点是随时随地都可以快速解决问题，不需要科学上网，大家懂的。</p>
                        </div>
                    </div>
                    <div class="memo-card">
                        <div class="user">
                            <div class="info">
                                <div class="avatar" style="background-image: url('/demo/avatar-aether.jpg');"></div>
                                <p class="name">Aether</p>
                            </div>
                            <p class="info-tx">手写程序爱好者</p>
                        </div>
                        <div class="memo">
                            <icon name="Quote" />
                            <p class="tx">使用稳定可靠，价格实惠量大。代码格式化样式好评一次出码。测试题：请问以下选项哪一个为真：1.  使用 Chatboy 以后我不会写程序了； 2. 离开 Chatboy 以后我不会写程序了； 3. 以上结论都对。</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="memo-card">
                        <div class="user">
                            <div class="info">
                                <div class="avatar" style="background-image: url('/demo/avatar-gs.png');"></div>
                                <p class="name">GS</p>
                            </div>
                            <p class="info-tx">ChatBoy Team</p>
                        </div>
                        <div class="memo">
                            <icon name="Quote" />
                            <p class="tx">几乎什么事情都可以问 Ai，从到文字校对到编程问题，感觉这 Ai 简直无所不能。我已经问了它好多编程问题了，有些问题谷歌甚至都搜不到结果，Ai 却能一针见血地给出答案。</p>
                        </div>
                    </div>
                    <div class="memo-card">
                        <div class="user">
                            <div class="info">
                                <div class="avatar" style="background-image: url('/demo/avatar-tang.jpg');"></div>
                                <p class="name">Tang</p>
                            </div>
                            <p class="info-tx">Ai探索者</p>
                        </div>
                        <div class="memo">
                            <icon name="Quote" />
                            <p class="tx">遇事不决问AI，它未必可以百分百满足你，但绝对可以帮你高效打开思路。从工作汇报到生活决策，它有问必答，24小时响应。而且chatboy是轻量级存在，不用下载很多app，它集合了目前AI文字和AI绘图的天花板应用，打开手机想用就用，chatboy比哪个boy都靠谱。</p>
                        </div>
                    </div>
                    <div class="memo-card">
                        <div class="user">
                            <div class="info">
                                <div class="avatar" style="background-image: url('/demo/avatar-feng.jpg');"></div>
                                <p class="name">老冯</p>
                            </div>
                            <p class="info-tx">咖啡烘焙师</p>
                        </div>
                        <div class="memo">
                            <icon name="Quote" />
                            <p class="tx">AIGC绘画真好玩！一些设计上的东西还超脱了,思维调整一下都能得到自己一些好东西，特别一些漫画主题都描绘的栩栩如生。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-sec">
            <div class="info-main">
                <div class="title">
                    <icon name="Puzzle" />
                    <div class="sub">
                        <h3>ChatBoy功能一览</h3>
                    </div>
                </div>
                <p class="tip">速速了解最新功能</p>
            </div>
            <div class="info-sub">
                <ul class="update-list">
                    <li>
                        <div class="tile">
                            <p>ChatBoy V2.0 内测</p>
                            <p class="info-tip">2023年12月12日</p>    
                        </div>
                    </li>
                    <!--
                    <li>
                        <div class="tile">
                            <p>Ai 对话功能</p>
                        </div>
                        <div class="tile">
                            <p>Ai 绘图功能</p>
                            <p class="info-tip">2023年10月2日</p>    
                        </div>
                    </li>
                    -->
                </ul>
            </div>
            <div class="button-g-3d button-medium width200">
                
                <div class="ctrl">
                    <router-link to="/chat">开始免费体验</router-link>
                </div>
            </div>
        </div>

        <div class="page-sec cp-sec">

            <div class="cp">
                <p class="domain">ChatBoy.io</p>
                <p class="sub">@2023</p>
            </div>

            <!--暂时隐藏
            <div class="wechat-info">
                <div class="title">
                    <icon name="Brand-Wechat" />
                    <p class="sub">微信用户群</p>
                </div>
                <img src="/demo/qr-demo.png" alt="Wechat Group QR" class="qr">
            </div>
            -->

            <router-link to="/help?t=qa" class="button-jump-doc">
                <icon name="Arrow-Tail-TR-Circle" />
                <p>帮助文档</p>
            </router-link>

        </div>

    </div>






    <icon name="Splash-Line-S-G1" class="tape-splash-small" />
    <icon name="Splash-Line-S-G2" class="tape-splash-small" />
    <icon name="Splash-Line-S-G3" class="tape-splash-small" />


</div>
</template>