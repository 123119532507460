type vvCaptchaOptions = {
    // 服务端返回的问题
    question: string
    // 每一次要计算的散列数据的大小
    size?: number
    // 要计算的散列值数量
    count?: number

    onprogress? : (progress: number) => void
}



export default async function vvCaptcha(opts: vvCaptchaOptions) {
    // 1. 数据合法性检查
    if (opts.size === undefined) {
        opts.size = 1 * 1024
    }
    if (opts.count === undefined) {
        opts.count = 10000
    }

    if (opts.size <= 0) {
        throw new Error("size must be a positive number")
    }
    if (opts.count <= 0) {
        throw new Error("count must be a positive number")
    }

    // 2. 计算散列值
    const result = new Uint8Array(opts.count * 3)
    const textEncoder = new TextEncoder()
    for (let i = 0; i < opts.count; i++) {
        const dataChunk = opts.question + i.toString()
        let data = dataChunk.repeat(Math.ceil(opts.size / dataChunk.length))
        data = data.slice(0, opts.size)

        const hashRaw = await crypto.subtle.digest('SHA-256', stringToUint8Array(data))
        const hashArray = new Uint8Array(hashRaw)
        result[i*3+0] = hashArray[0]
        result[i*3+1] = hashArray[1]
        result[i*3+2] = hashArray[2]

        if (i % (Math.round(opts.count / 100) + 1) == 0) {
            if (opts.onprogress !== undefined) {
                opts.onprogress(i / opts.count)
            }
        }
    }

    // 将结果编码为 Base64
    const b64 = btoa(String.fromCharCode.apply(null, [...result]))

    return b64
}



function stringToUint8Array(str :string) {
    const uint8Array = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
      uint8Array[i] = str.charCodeAt(i);
    }
    return uint8Array;
  }
  