export class WebSocketClient {
    private url :string
    private socket :WebSocket | null
    private reconnectInterval :number
    private listeners :any
    private pingTimer :number
    
    constructor(url :string) {
      this.url = url;
      this.socket = null;
      this.reconnectInterval = 1000; // 重连间隔时间，默认为1秒
      this.listeners = {}; // 事件监听器
      this.pingTimer = 0;
    }
  
    connect() {
      this.socket = new WebSocket(this.url);
      this.socket.onopen = () => {
        this.emit('open', null);
        this.reconnectInterval = 1000;

        this.startKeepalive()
      };
      this.socket.onmessage = (event) => {
        this.emit('message', event.data);
      };
      this.socket.onclose = () => {
        // console.debug("onclose 被触发")
        this.emit('close', null);
        this.reconnect();
      };
      this.socket.onerror = (error) => {
        // console.debug("onerror 被触发")
        this.emit('error', error);
      };
    }
  
    reconnect() {
      this.reconnectInterval = Math.min(15 * 1000, this.reconnectInterval + 1000)
      // console.log("重连间隔", this.reconnectInterval)

      setTimeout(() => {
        this.connect();
      }, this.reconnectInterval);
    }
  
    emit(event :any, data :any) {
      if (this.listeners[event]) {
        this.listeners[event].forEach((listener :any) => {
          listener(data);
        });
      }
    }
  
    on(event :any, callback :CallableFunction) {
      if (!this.listeners[event]) {
        this.listeners[event] = [];
      }
      this.listeners[event].push(callback);
    }
  
    send(data :any) {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(data);
      } else {
        throw new Error('WebSocket连接未建立或已断开');
      }
    }
  
    close() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.close();
      }
    }


    startKeepalive() {
      if (this.pingTimer) {
        return
      }

      this.pingTimer = setInterval(() => {
        if (this.socket?.readyState === WebSocket.OPEN) {
          this.socket.send(JSON.stringify({Type: "ping"}));
        }
      }, 10 * 1000); // 每隔10秒发送一次ping消息
    }
  
    stopKeepalive() {
      clearInterval(this.pingTimer);
      this.pingTimer = 0;
    }
  }