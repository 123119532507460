<script setup lang="ts">
import {ref, onMounted, onUnmounted} from "vue"
import {useDaily, useModalState} from "@/store"
import {storeToRefs} from "pinia"
import bus from "@/bus"
import MobileModal from "@/comps/MobileModal.vue";
import Daily from "@/comps/Daily.vue";


import {notify} from "@kyvg/vue3-notification"

const isShowDaily = storeToRefs(useModalState()).isShowDaily

onMounted(() => {
    bus.on("ws.Accounting.PointLog", onPointLog)
    bus.on("ws.DailyAction.Create", onDailyAction)  // 目前应该只有在 Action分享链接被点击 和 Action分享链接 事件才会收到这个消息
    useDaily().reload()
})
onUnmounted(() => {
    bus.off("ws.Accounting.PointLog", onPointLog)
    bus.off("ws.DailyAction.Create", onDailyAction)
})

function onPointLog(e :any) {
    console.log(e)
    if (e.Data.Delta > 0) {
        notify({type: "bonus", text: `获得 <b>${e.Data.Delta}</b> 积分`})
    }
}

function onDailyAction() {
    useDaily().reload()
}


</script>

<template>

    <div class="section-sub">
        <a @click.prevent="isShowDaily=true" class="sub-tab-a mission-tab">
            <div class="info-main">
                <!--
                <icon name="Star" />
                -->
                <p class="title">
                    <span class="mission-emoji">☕️</span>
                    <span class="tx">每日积分任务</span>
                </p>
                <div class="sub">
                    <p class="msg-count">
                        <span class="count-done">{{useDaily().finishCount}}</span>
                        <span>/{{useDaily().total}}</span>
                    </p>
                </div>
            </div>
            <div class="info-sub">
                <p class="mission-count">
                    <span class="count-done">{{useDaily().finishCount}}</span>
                    <span>/{{useDaily().total}}</span>
                </p>
                <icon name="Arrow-Right" />
            </div>
        </a>
    </div>

</template>
