<script setup lang="ts">
import { notify } from "@kyvg/vue3-notification"
import {apiFetch, formatCode, duration2Readable, time2Readable, imageProxy, MJJobType2Readable} from "../common"
import {onMounted, onUnmounted, computed, ref, watch} from "vue"
import RAW from "../assets/mjprompt.raw.json"

const props = withDefaults(defineProps<{
    prompt?: string
}>(), {
    prompt: "",
})


const emit = defineEmits(["add", "remove", "close"])

const presets = ref(<any>{})
const selectedClass = ref("")
const selectedPrompts = ref(<string[]>[])

const presetClasses = <any>{
    "templates": "模板",
    "styles": "风格",
    "colors": "色彩",
    "materials": "材质",
    "artists": "艺术家",
    "scene": "场景",
    "lighting": "灯光",
    "camera": "镜头",
}

// function translateName(name :string) :string {
//     let m = presetClasses

//     if (m[name]) {
//         return m[name]
//     }
//     return name
// }

onMounted(async () => {
    let raw: any = RAW
    for (let k in raw) {
        raw[k] = (raw[k] as any).sort((a :any, b :any) => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1)
    }

    presets.value = raw

    for (let k in presets.value) {
        selectedClass.value = k
        break
    }

    updateByPrompt(props.prompt)
})

function togglePrompt(label :string) {
    // 在当前已选定的提示词中查找指定提示词，如果找到了，说明这是一个删除操作
    let isRemove = false
    selectedPrompts.value = selectedPrompts.value.filter(v => {
        if (v == label) {
            console.log("删除预设 ", label)
            isRemove = true
            emit("remove", label)
            return false
        } else {
            return true
        }
    })
    

    if (isRemove) {
        return
    }

    // 由于当前选定的预设词中没有指定的词，所以这是一个添加提示词操作
    
    console.log("添加预设 ", label)
    selectedPrompts.value.push(label)
    emit("add", label)
}

const classes = computed(() => {
    return Object.keys(presets.value)
})

function isPromptSelected(label :string) :boolean {
    for (let i in selectedPrompts.value) {
        if (selectedPrompts.value[i] == label) {
            return true
        }
    }
    return false
}

watch(() => props.prompt, (newVal, oldVal) => {
   updateByPrompt(newVal)
})

// 根据原始提示词，更新已选中的标签
function updateByPrompt(p :string) {
 let map = <any>{}
    for (let i in presets.value) {
        for (let k in presets.value[i]) {
            map[presets.value[i][k].label.toLowerCase()] = presets.value[i][k].label
        }
    }
    console.log(map)

    let s = <string[]>[]
    let val:string = p.toString()
    val = val.split("，").join(",")
    val = val.split("　").join(" ")
    val = val.split(" --").join(", --")
    let tokens = val.split(",")
    console.log("tokens=", tokens)
    tokens.forEach(v => {
        v = v.trim().toLowerCase()
        if (map[v]) {
            s.push(map[v])
        }
    })

    console.log("s=", s)

    selectedPrompts.value = s
}

// 取消所有选定
function reset() {
    selectedPrompts.value.forEach(v => {
        togglePrompt(v)
    })
}

// 判断一个分类下面是否有选中的提示词
const hasSelected = computed(() => (className: string) :boolean => {
    if (!className) {
        return false
    }
    if (!presets.value[className]) {
        return false;
    }

    try {
        for (let i in presets.value[className]) {
            // console.log("判断是否选中", className, presets.value[className][i].label)
            if (isPromptSelected(presets.value[className][i].label)) {
                return true
            }
        }
    } catch (e) {
        console.warn(e)
    }
    
    return false
})


</script>

<template>

    
<!-- 
    
    <div class="cb-prompt-container">

        <div class="prompt-ctrl">
            <div class="tile-list">
                <span
                    v-for="(zh, en) in presetClasses" @click="selectedClass=en.toString()"
                    :class="{'active': selectedClass==en.toString(), 'selected': hasSelected(en.toString())}"
                >{{zh}}</span>
            </div>
            <div class="tile-tool">
                <button @click.prevent="reset" class="button-reset">重置</button>
            </div>
        </div>

        <div class="prompt-list">
            <span
                v-for="c in presets[selectedClass]"
                @click="togglePrompt(c.label)"
                :class="{'active': isPromptSelected(c.label)}"
            >{{c.label}} {{c.chinese}}</span>
            
        </div>
    </div> -->


    <div class="prompt-helper">
        <div class="prompt-ctrl">
            <div class="tile-list-box">
                <div class="tile-list">
                    <span
                    v-for="(zh, en) in presetClasses" @click="selectedClass=en.toString()"
                    :class="{'active': selectedClass==en.toString(), 'selected': hasSelected(en.toString())}"
                >{{zh}}</span>
                </div>
            </div>
            <div class="tile-tool">
                <button @click.prevent="reset" class="button-g button-xsmall">重置</button>
            </div>
        </div>
        <div class="prompt-list">
            <span
                v-for="c in presets[selectedClass]"
                @click="togglePrompt(c.label)"
                :class="{'active': isPromptSelected(c.label)}"
            >{{c.label}} {{c.chinese}}</span>                      
        </div>
        <div class="prompt-tool">
            <div class="button-g button-xsmall button-border-black" @click="$emit('close')">
                <icon name="Arrow-Up" />
                收起
            </div>
        </div>
    </div>

</template>
