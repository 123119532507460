<script setup lang="ts">
import { onBeforeUnmount, computed, onMounted, ref, watch } from 'vue'
import {mobileModalFadeIn, mobileModalFadeOut, slideUpIn, slideRightIn, slideRightOut, slideLeftIn, slideLeftOut} from "@/animation"
import {useRedDot} from "@/store"
import {storeToRefs} from "pinia"
import MyInvite from "./list/MyInvite.vue"
import Withdrawal from "./list/Withdrawal.vue"
import Bonus from "./list/Bonus.vue"

const tab = ref('invite')

const {pendingBonus} = storeToRefs(useRedDot())



</script>

<template>
    <div class="list-info invite-list">
        <div class="info">
            <div class="nav-tab">
                <a :class="{'active' : (tab == 'invite')}" @click="tab='invite'">我的邀请</a>
                <a :class="{'active' : (tab == 'withdrawal')}" @click="tab='withdrawal'">我的提现</a>
                <a :class="{'active' : (tab == 'pending_bonus'), 'reddot': pendingBonus}" @click="tab='pending_bonus'">邀请奖励</a>
            </div>
        

            <!-- 我的“”邀请 -->
            <Transition @enter="slideLeftIn"  mode="out-in">
                <Component :is="MyInvite" v-if="tab=='invite'" />
            </Transition>

            <!-- 提现历史 -->
            <Transition @enter="slideUpIn" mode="out-in">
                <Component :is="Withdrawal" v-if="tab=='withdrawal'" />
            </Transition>
            
            <!-- 待领取奖励列表 -->
            <Transition @enter="slideRightIn" mode="out-in">
                <Component :is="Bonus" v-if="tab=='pending_bonus'" />
            </Transition>


        </div>
    </div>

</template>

<style scoped>
</style>