<template>

    <div class="header"> 
        <div class="main-info">
            <a href="/" class="logo">
                <icon name="Logo-Chatboy" class="avatar" />
                <h1>ChatBoy.io</h1>
            </a>
        </div>
    </div>
    <div class="tip-page">
        <div class="solo-page-content tip-content tip-content-center tip-content-404">
            <div class="content-ctrl">
                <h2 class="title">Hey, 你跑错地方啦</h2>
                <img src="/icon-404-boy.svg" alt="404 Icon" class="avatar-icon">
                <a href="/" class="button-g-3d button-medium">
                    <div class="ctrl">
                        (⸝⸝› з ‹⸝⸝) 去首页看看
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
