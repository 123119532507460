// import {lexer, lexerResults} from "js-lexer"

// const rules = [
//     {
//         name: "URL",
//         tester: (w :any) => {
//             // 从一段文字中匹配一个完整的 URL
//             const regex = /^https?:\/\/[^\s]+$/
//             if (regex.test(w)) {
//                 return lexerResults.exact
//             }
//             if (w.indexOf(" ") !== -1) {
//                 return lexerResults.none
//             }
//             return lexerResults.possible
//         }
//     },
//     {
//         name: "DELIMITER",
//         tester: (w :any) => {
//             if (w === "," || w === "，") {
//                 return lexerResults.exact
//             } else {
//                 return lexerResults.none
//             }
//         },
//     },
//     {
//         name: "SPACE",
//         tester: (w :any) => {
//             if (/^[ ]+$/.test(w)) return lexerResults.exact
//             return lexerResults.none
//         }
//     },
//     {
//         name: "PARAM",
//         tester: (w :any) => {
//             const cnt = w.split(/[ ]+/).length
//             if (cnt > 2) return lexerResults.none
//             if (w.indexOf(",") !== -1) return lexerResults.none
//             if (w.startsWith("-")) return lexerResults.possible
            
//             if ( w.startsWith("--")) {
//                 if (cnt < 2) {
//                     return lexerResults.possible
//                 } else if (cnt == 2) {
//                     return lexerResults.possible
//                 } else {
//                     return lexerResults.none
//                 }
//             } else {
//                 return lexerResults.none
//             }
//         },
//     },
    
//     {
//         name: "WORD",
//         tester: (w :any) => {
//             if (w.indexOf(",") !== -1) return lexerResults.none
//             if (w.indexOf("，") !== -1) return lexerResults.none
//             if (w.startsWith("--")) return lexerResults.none
//             if (w.startsWith(" ")) return lexerResults.none
//             if (/^https?:\/\//.test(w)) return lexerResults.none
//             return lexerResults.possible
//         }
//     },
// ]

// export function parse(prmopt: string) :any {
//     return lexer(prmopt, rules)
// }


interface Token {
    type: string
    value: string
}

export class PromptParser  {
    protected state : "EMPTY" | "URL" | "WORD" | "PARAM"
    protected tokens : Token[]
    protected i :number
    protected prompt :string = ""

    // 在解析 TOKEN 时，保存目前已经读取的字符
    protected buffer :string

    constructor() {
        this.state = "EMPTY"
        this.tokens = []
        this.buffer = ""
        this.i = 0
    }

    parse(prompt: string) :Token[] {
        this.prompt = prompt
        this.i = 0
        while (this.i < prompt.length) {
            const c = prompt[this.i]
            const fn = this.transfers[this.state]
            if (!fn) {
                throw new Error(`没有 ${this.state} 对应的状态转移处理函数`)
            }

            fn(c)

            this.i++
        }

        if (this.state != "EMPTY") {
            this.addToken(this.state)
        }

        return this.tokens
    }

    protected addToken(type: string) {
        this.tokens.push({type, value: this.buffer})
        this.buffer = ""
        this.state = "EMPTY"
    }

    // 返回目前还未匹配的内容
    protected unmatched() :string {
        return this.prompt.slice(this.i + 1)
    }

    protected transfers = {
        EMPTY: (c: string) => {
            if (/^[\s]$/.test(c)) {
                // 空白字符，跳过，不需要处理
                return
            }
            if (c === "," || c === "，") {
                // 跳过，不需要处理
                return
            }

            // 进入 PARAM 状态
            // console.log(`PARAM 匹配`, c + this.unmatched(), (c + this.unmatched()).match("^--"))
            if ((c + this.unmatched()).match("^--")) {
                this.state = "PARAM"
                this.buffer += c
                return
            }

            
            // 其他情况，直接进入 WORD 状态
            this.state = "WORD"
            this.buffer += c
        },

        WORD: (c: string) => {
            // 遇到逗号则结束
            if (c === "," || c === "，") {
                this.addToken("WORD")
                return
            }

            // 从当前位置开始匹配，查看后面是否跟随参数。若是，则当前 WORD 结束
            if (this.unmatched().match(/^[ ]+--/)) {
                this.buffer += c
                this.addToken("WORD")
                return
            }

            // 如果这是一个 URL,则直接转状态为 URL 
            if (/^https?:\/\//.test(this.buffer)) {
                console.log("转换为 URL")
                this.state = "URL"
            }

            this.buffer += c
            return
        },

        URL: (c: string) => {
            // 遇到空格则结束
            if (c === " ") {
                this.addToken("URL")
                return
            }

            this.buffer += c
        },



        PARAM: (c: string) => {
            // 遇到空格，则视情况结束
            if (c === " ") {
                if (this.buffer.indexOf(" ") >= 0) {
                    this.addToken("PARAM")
                    return
                }
                // niji 是一个不带参数的参数
                // if (this.buffer.startsWith("--niji")) {
                //     this.addToken("PARAM")
                //     return
                // }
            } 

            this.buffer += c
            return
        },

    }




}


export interface NormalizeOptions {
    style?: "cute" | "scenic" | "original" | "expressive" | "raw"
    stylize? :number
    weird? :number
    chaos? :number
    ar? :string
    refImages? :RefImage[]
    cRefWeight? :number
    sRefWeight? :number
    basicRefWeight? :number

}

export const ParamDefaults :NormalizeOptions = {
    stylize: 100,
    weird: 0,
    chaos: 0,
    ar: "1:1",
    refImages: [],
    cRefWeight: 100,
    sRefWeight: 100,
    basicRefWeight: 0.25,
}

export interface RefImage  {
    key: string,
    url: string,
    isBasicRef: boolean, // 是否是普通的垫图（即 URL 放在最开头的垫图类型）
    isCRef: boolean,
    isSRef: boolean,
}

// 将用户输入的提示词，根据当前设定的参数，进行标准化
export function normalize(prompt: string, opts :NormalizeOptions) :string {
    const tokens = (new PromptParser()).parse(prompt)

    const hasParam = (name :string) :boolean => {
        for (let i = 0; i < tokens.length; i++) {
            if (tokens[i].type !== "PARAM") {
                continue
            }
            const p =tokens[i].value.trim()
            if (p.startsWith(`--${name} `) || p == `--${name}`) {
                return true
            }
        }

        return false
    }

    if (opts?.style) {    // 如果存在 style 参数
        // if (opts.style != ParamDefaults.style) {  // 且此参数不是默认值 
            if (!hasParam("style")) {   // 且用户没有指定此参数
                // 则将此参数添加到 tokens 中
                tokens.push({type: "PARAM", value: `--style ${opts.style}`})
            }
        // }
    }
    
    if (opts?.stylize) {    // 如果存在 stylize 参数
        if (opts.stylize != ParamDefaults.stylize) {  // 且此参数不是默认值 
            if (!hasParam("style") &&  !hasParam("s")) {   // 且用户没有指定此参数
                // 则将此参数添加到 tokens 中
                tokens.push({type: "PARAM", value: `--s ${opts.stylize}`})
            }
        }
    }

    if (opts?.weird) {    // 如果存在 weird 参数
        if (opts.weird != ParamDefaults.weird) {
            if (!hasParam("weird")) {   // 且用户没有指定此参数
                // 则将此参数添加到 tokens 中
                tokens.push({type: "PARAM", value: `--w ${opts.weird}`})
            }
        }
    }

    if (opts?.chaos) {    // 如果存在 chaos 参数
        if (opts.chaos != ParamDefaults.chaos) {
            if (!hasParam("chaos")) {   // 且用户没有指定此参数
                // 则将此参数添加到 tokens 中
                tokens.push({type: "PARAM", value: `--c ${opts.chaos}`})
            }       
        }
    }

    if (opts?.ar) {    // 如果存在 ar 参数
        if (opts.ar != ParamDefaults.ar) {
            if (!hasParam("ar")) {   // 且用户没有指定此参数
                // 则将此参数添加到 tokens 中
                tokens.push({type: "PARAM", value: `--ar ${opts.ar}`})
            }       
        }
    }


    let params = <any>[]
    let words = <any>[]

    tokens.map(v => {
        if (v.type === "PARAM") {
            params.push(v.value)
        } else if (v.type === "WORD" || v.type === "URL") {
            words.push(v.value)
        }
    })

    let ret = words.join(" , ")
    ret += " " + params.join(" ")
    ret = ret.trim()

    //  处理 ref_images 参数
    if (opts?.refImages && opts.refImages.length > 0) { 
        ret = normalizeRefImages(ret, opts)

    }


    return ret
    
}

function normalizeRefImages(p: string,  opts :NormalizeOptions) :string {
    const refImages = opts?.refImages
    if (!refImages) {
        return p
    }

    // 1. 删除 sw, cw, iw 参数
    p = p.replace(/--sw[ ]+([1-9][0-9]+)*/, "")
    p = p.replace(/--cw[ ]+([1-9][0-9]+)*/, "")
    p = p.replace(/--iw[ ]+([1-9][0-9]+)*/, "")

    // 2. 删除 --sref 和 --cref 参数，这两个参数后面应该是一个 URL，应该以非空格和非逗号结尾
    p = p.replace(/--sref[ ]+[^\s,]+/, "")
    p = p.replace(/--cref[ ]+[^\s,]+/, "")

    // 3. 添加我们自己的垫图
    let basics :string[] = []
    let srefs :string[] = []
    let crefs :string[] = []

    for (let i in refImages) {
        const img = refImages[i]
        if (img.isBasicRef) {
            basics.push(img.url)
        }
        if (img.isSRef) {
            srefs.push(img.url)
        }
        if (img.isCRef) {   
            crefs.push(img.url)
        }
    }

    // 4. 附加控制参数
    p = basics.join(" ") + " " + p
    for (let i in srefs) {
        p += " --sref " + srefs[i]
    }
    for (let i in crefs) {
        p += " --cref " + crefs[i]
    }
    if (basics.length > 0) {
        if (opts.basicRefWeight != ParamDefaults.basicRefWeight) {
            p += " --iw " + opts.basicRefWeight
        }
    }
    if (srefs.length > 0) {
        if (opts.sRefWeight != ParamDefaults.sRefWeight) {
            p += " --sw " + opts.sRefWeight
        }
    }
    if (crefs.length > 0) {
        if (opts.cRefWeight != ParamDefaults.cRefWeight) {
            p += " --cw " + opts.cRefWeight
        }
    }


    return p
}