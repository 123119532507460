<script setup lang="ts">
import {ref, onMounted, onUnmounted} from "vue"
import {notify} from "@kyvg/vue3-notification"
import {apiFetch} from "@/common"

const props = defineProps<{
    hideHeader?: boolean,
}>()

const email = ref("")
const isLoading = ref(false)
const isSuccess = ref(false)

// 剩余多少秒可以重发
const sendRemains = ref(0)

// 上次发送重置请求的时间
var sentTime :number = 0

var timer :number = 0

onMounted(() => {

})
onUnmounted(() => {
    clearInterval(timer)
})

async function requestPasswordReset() {
    if (email.value == "") {
        notify({type: "error", text: "请输入正确的邮箱"})
        return
    }

    try {
        isLoading.value = true
        isSuccess.value = false
        

        let j = await apiFetch("/goapi/request_password_reset", {
            method: "post",
            body: JSON.stringify({
                "email": email.value
            }),
        })

        isSuccess.value = true
        sentTime = (new Date()).getTime()
        updateCountdown()

    } catch (e) {
        notify({type: "error", text: (e as Error).message})
    } finally {
        isLoading.value = false
    }
}

function updateCountdown() {
    clearInterval(timer)

    sendRemains.value = 60
    setInterval(() => {
        let t = sentTime - (new Date()).getTime()  + 60 * 1000
        t = Math.round(t / 1000)
        sendRemains.value = t
    }, 1000)
}

</script>

<template>
<div class="width-max800 flex-col-left flex-gap24 margin-h-auto">
    <div class="title-box-settings" v-if="!props.hideHeader">
        <div class="ctrl-part">
            <icon name="Key-Password" />
            <h3>重置密码</h3> 
        </div>
    </div>
    
    <div class="width100 form-input-ctrl">
        <input type="text" placeholder="你的注册邮箱" v-model="email" @keypress.enter="requestPasswordReset"  />
    </div>
    <div class="width100 flex-row-between only-child-flex-h-layout-right">
        <div class="flex-row-left" v-if="isSuccess">
            <p class="font14 color-orange">重置说明已经发送到你的邮箱，请按邮件中的说明继续操作</p>
        </div>
        <div class="flex-row-left flex-gap12">
            <button class="button-g button-fill-black button-disabled" v-if="sendRemains > 0">{{sendRemains}} 秒后可重发邮件</button>
            <button class="button-g button-fill-black" @click="requestPasswordReset" v-else>重置密码</button>
        </div>
    </div>
</div>
</template>