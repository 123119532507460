interface Entry {
    key :string
    value :any
}

export default class {
    space :string

    constructor(name :string) {
        this.space = name
    }


    public list() :any[] {
        let raw = localStorage.getItem("cb_lru_" + this.space) ?? ""
        let ret = <any>[]

        try {
            // let entries = Object.values(JSON.parse(raw))
            // for (let i = 0; i < entries.length; i++) {
            //     ret.push((entries[i] as Entry).value)
            // }
            ret = JSON.parse(raw) ?? []
        } catch(e) {
            console.warn("读取 LRU 列表失败", e)
        }

        return ret
    }

    public set(key :string, value :any) {
        let raw = localStorage.getItem("cb_lru_" + this.space) ?? ""
        
        let ret :Entry[] = []
        try {
            ret = JSON.parse(raw) ?? []
        } catch (e) {}

        ret = ret.filter(v => v.key != key)
        ret.unshift({key, value})
        

        raw = JSON.stringify(raw)
        localStorage.setItem("cb_lru_" + this.space, JSON.stringify(ret))
    }

    public get(key :string) :any {
        let raw = localStorage.getItem("cb_lru_" + this.space) ?? ""
        let ret = <any>null

        try {
            ret = JSON.parse(raw)
            for (let i = 0; i < ret.length; i++) {
                if (ret[i].key == key) {
                    let val = ret[i].value

                    // 交换
                    if (i == 0) {
                        // 什么都不用做
                    } else {
                        let tmp = ret[0]
                        ret[0] = ret[i]
                        ret[i] = tmp
                    }

                    // 将交换后的数据写回本地
                    raw = JSON.stringify(raw)
                    localStorage.setItem("cb_lru_" + this.space, JSON.stringify(ret))

                    return val
                }

            }
        } catch(e) {
            console.warn("读取 LRU 失败", e)
        }

        return undefined
    }

    
}