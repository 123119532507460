export enum ToolType {
    CHAT = "chat",
    AIMAGE = "aimage",
    LINK = "link",
    LIGHTINGIMAGE = "lightingimage",
    SDXLBATCH = "sdxlbatch",
    GALLERY = "gallery",
    REMOVEBG = "removebg",
}

export interface Tool {
    name :string
    description :string
    icon :string
    backgroundColor :string
    popularity :number
    type :ToolType
    class :string
    // 该工具对应的原始数据（如 preset 数据等）
    raw :any

    // 对应的链接地址
    link ?:string
}



// 聊天相关数据
export interface Chat {
    id :number,
    // model :string,
    subject :string,
    messages :ChatMessage[],
    preset_id: number,

    // 本地状态数据
    is_synced :boolean,    // 是否已经同步到云端
    sync_version :number,

    // 是否已经生成了标题
    is_subject_maked :boolean,

    model: string | null
    temperature: number | null,
    presence_penalty: number | null,
    max_tokens: number | null,
    history_message_count: number | null,    // 最大附带的历史消息数量
    history_token_count: number | null,      // 最大附带的历史消息 token 数量


    // 下面均为发送给服务器的参数
    streaming?: boolean,    
}

// 简单的缓存信息，因为 indexDB 有点慢，我们需要一个大型的数据结构缓存数据，例如标题之类的
export interface ChatMeta {
    id :number
    subject :string
    ordering :number
}

export interface ChatMetaMap {
    [key: number] :ChatMeta
}


export interface ChatMessage {
    id :number,
    role :string,
    content :string,
    create_time :number,

    type :"text" | "voice" | "loading" | "error"
    voice_hash? :string

    // 附件的文件列表的 hash
    // 目前仅供 GPT4 使用
    attachments?: string[]

    // 是否允许对这条消息使用 TTS（即是否显示一个音频播放控件）
    allow_tts?: boolean,
}

export type G4VContent = G4VContentItem[]

export interface G4VContentItem {
    type: "text" | "image_url",
    text?: string,
    image_url?: {
        url: string,
    },
}


// 聊天消息的附件（如图片等）
export interface ChatAttachment {
    sha224 :string,
    name? :string,
    size? :number,
}



export function NewChat() :Chat {
    return {
        id: 0,
        subject: "",
        messages: [],
        preset_id: 0,
        is_synced: false,
        sync_version: 0,
        is_subject_maked: false,

        model: null,
        temperature: null,
        presence_penalty: null,
        max_tokens: null,

        history_message_count: 0,
        history_token_count: 0,
        
    }
}


// 支付页面的“待付款金额”数据
export interface PendingPaymentInfoInterface {
    // // 订单金额
    // amount :number,
    // // 实际需要支付的金额
    realAmount :number,

    // ^^^^----暂时只用 PendingPaymentInfo 来传递实际需要支付的订单金额（realAmount）

    
    // 要购买的商品名字
    // productName: string
}



export class PendingPaymentInfo implements PendingPaymentInfoInterface {
    constructor(
        // public amount :number = 0,
        public realAmount :number = 0,
        // public productName :string = ""
    ) {
        // this.amount = amount;
        this.realAmount = realAmount;
        // this.productName = productName;
    }

    // 折扣金额
    // 在尚未设置 realAmount 的情况下，总是返回 0
    // discountAmount() :number {
    //     if (this.realAmount == 0) {
    //         return 0
    //     }
    //     return this.amount - this.realAmount
    // }
}



export interface VAPIDUnregisterOptions {
    // 是否删除所有的注册信息
    all?: boolean

    // 附加的原因，可以为空
    reason :string
}
