<script setup lang="ts">
import {ref} from "vue"
import {Uploader, notifySuccess, notifyInfo, notifyError} from "@/common2"

const emit = defineEmits(["success"])

// 是否正在上传
const isUploading = ref(false)
// 是否正在选择文件
const isChoosing = ref(false)
// 上传进度，值域 [0, 1]
const progress = ref(0)

async function upload() { 
    const u = new Uploader({data: {IsShortURL: 1}})
    
    u.onprogress = (p :number) => {
        progress.value = p
    }
    u.onsubmit = () => {
        isUploading.value = true
    }
    

    try {
        // isUploading.value = true
        progress.value = 0

        isChoosing.value = true
        const res = await u.upload()
        notifySuccess("上传成功")
        
        emit('success', res.Upload)
    } catch (e) {
        if ((e as Error).message == "取消上传") {
            // 用户关闭了文件选择框，什么都不用做
            notifyInfo("上传取消")
        } else {
            notifyError("上传失败: " + (e as Error).message)
        }
    } finally {
        isUploading.value = false
        isChoosing.value = false
    }
}

</script>

<template>
    <slot
        :isUploading="isUploading"
        :progress="progress"
        :upload="upload"
    />
</template>

