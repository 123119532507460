<script setup lang="ts">
import {ref, computed} from "vue"
import {newChat, getCurrentPage} from "@/common2"
import {useRouter,useRoute} from "vue-router"
import {useAccount, useRedDot} from "@/store"
import {storeToRefs} from "pinia"
import {useChatSideBar} from "@/store"

const router = useRouter()
const route = useRoute()

import MobileModal from "@/comps/MobileModal.vue"
import InviteInfo from "@/comps/InviteInfo.vue"

const {pendingBonus} = storeToRefs(useRedDot())

const isShowInvite = ref(false)

const {isOrdering} = storeToRefs(useChatSideBar())
const {isLoggedIn} = storeToRefs(useAccount())

const {account} = storeToRefs(useAccount())

const hasActivePlan = computed(() :boolean => {
    // if (!props.currentUser) {
    //     return false
    // }

    // if (props.currentUser.current_plan_name) {
    //     return true
    // } else {
    //     return false
    // }

    if (account.value?.current_plan_name == "") {
        return false
    } else {
        return true
    }

    
})

// 当前是否在列表页面
// 如果存在 id 参数，则表示在聊天内页，否则表示在列表页面
const isInList = computed(() => {
    if (route.path.startsWith("/chat")) {
        if (route.query.id) {
            return false
        } else {
            return true
        }
    } else if (route.path.startsWith("/tools")) {
        if (route.query.t) {
            return false
        } else {
            return true
        }
    }

    return false
})

</script>

<template>
    <!-- 移动端底部菜单-->
    <div class="mobile-bottom-section newfish" v-if="isInList">
        <div class="info-a">

            <!-- 文件箱 -->
            <!--
            <div class="button-g-icon button-size-48">
                <icon name="Box-Open" />
            </div>
            -->

            <!-- 邀请奖励按钮 -->
            <div class="button-g button-g-invite" :class="{'reddot': pendingBonus}" @click="isShowInvite = !isShowInvite">邀请奖励</div>

        </div>


        <div class="info-b">
            <!-- 升级 Pro 订阅用户，仅对到期或未登录用户显示 -->
            <router-link 
                v-if="!hasActivePlan "
                to="/shop" class="button-g button-upgrade-buy"
            >
                <span class="emoji">🚀</span>
                <span class="tx">升级Pro用户</span>
            </router-link>


            <!-- 取消编辑对话 按钮，只在聊天页面显示-->
            <div
                class="button-mobile-footer-purple button-g button-fill-purple" v-show="isOrdering" 
                @click.prevent="isOrdering=false"
            >
                <icon name="Cross-Close" />
                退出编辑状态
            </div>

            <!-- 编辑对话 按钮，只在聊天页面显示-->
            
            <div
                class="button-g-icon button-size-48 color-purple button-fill-nude" v-show="!isOrdering && isLoggedIn"
                @click.prevent="isOrdering=true"
            >
                <icon name="Chat-Edit" />
            </div>
            

            <!-- “ + ” 按钮，只在聊天页面显示-->
            
            <div
                v-if="getCurrentPage() == '/chat'" 
                class="button-g-icon button-size-48 button-fill-purple"
                @click="newChat(router, '', 0)"
                v-show="!isOrdering" 
            >
                <icon name="Add-Plus" />
            </div>
            

        </div>

    </div>

    
    <Transition name="modal">
    <Component :is="MobileModal" :isShow="isShowInvite" :hideOKButton="true" @close="isShowInvite = false" title="邀请奖励" icon="User-More" >
        <Component :is="InviteInfo" />
    </Component>
    </Transition>

</template>