<script setup lang="ts">
import { ref, watch, defineAsyncComponent } from 'vue'
import {modalSlideDownOut, modalSlideDownIn, slideUpIn, slideUpOut, mobileModalFadeIn, mobileModalFadeOut} from "@/animation"
import {getCurrentPage, isMobile, newChat, L} from "@/common2"
import {apiFetch, time2Readable} from "@/common"
import {useModalState, useAccount} from "@/store"
import {useRouter} from "vue-router"
import {storeToRefs} from "pinia"
import MobileHeaderMenu from '@/comps/MobileHeaderMenu.vue'




const {isShowMobileMenu, isShowPersonal, isShowSuggestion} = storeToRefs(useModalState())
// const isShowConfig = ref(false)


// async function loadProfile() {
//     // 如果正在加载中，则跳过
//     if (isProfileLoading.value) {
//         return
//     }

//     // 如果已经加载过，则不重新加载
//     if (profile.value) {
//         return 
//     }

//     try {
//         isProfileLoading.value = true

//         let j = await apiFetch("/goapi/current_account")
//         profile.value = j
//     } catch(e) {
//         console.warn("加载个人信息出错: ", e)
//     } finally {
//         isProfileLoading.value = false
//     }       
// }



// watch(isShowPersonal, (newVal) => {
//     if (newVal == true) {
//         loadProfile()
//     }
// })
// watch(isShowInvite, (newVal) => {
//     if (newVal == true) {
//         loadProfile()
//     }
// })

// const popupPosition = function(mobilePosition : "bottom" | "top" | "center") {
//     if (isMobile()) {
//         return mobilePosition
//     } else {
//         // 电脑端总是显示在中心位置
//         return "center"
//     }
// }


</script>

<template>
<div>



    <!-- 顶部滑出菜单 -->
    <!-- <Transition @enter="modalSlideDownIn" @leave="modalSlideDownOut">
        <Component :is="MobileHeaderMenu" />
    </Transition> -->

    <div class="header header-mobile"> 
        <div class="info">
            <a class="avatar" @click="isShowPersonal = !isShowPersonal">
                <img :src="useAccount().avatar()" alt="User avatar">
            </a>
            <div class="triggers">

                <a class="trigger-advice" @click.prevent="isShowSuggestion=true">
                    <icon name="Writenote" />
                </a>

                <router-link to="/tools" class="trigger-chat-active" v-if="['/chat', '/', ''].indexOf(getCurrentPage()) >= 0">
                    <icon name="Conversation-Acitve-Fill" />
                </router-link>
                
                <router-link to="/chat" class="trigger-chat-active" v-if="getCurrentPage() == '/tools'">
                    <icon name="Conversation" />
                </router-link>

                <a class="trigger-hamburger" @click="isShowMobileMenu = true">
                    <icon name="Menu-Change" />
                </a>
            </div>
        </div>
    </div>









    
    
    

</div>
</template>

<style scoped>
.mobile-header-background-board {
    /* border: 1px solid red; */
    /* background-color: #f008; */
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: -1;
}
</style>