<!-- 这个文件放置各种弹窗 -->

<script setup lang="ts">
import {useModalState, useAccount} from "@/store"
import {storeToRefs} from "pinia"
import {isMobile} from "@/common2"
import MobileModal from "@/comps/MobileModal.vue"
import RedeemCode from "@/comps/RedeemCode.vue"
import Config from "@/pages/Config.vue"
import LoginForm from "@/comps/LoginForm.vue"
import RegisterForm from "@/comps/RegisterForm.vue"
import InviteInfo from "@/comps/InviteInfo.vue"
import MobilePersonal from "@/comps/MobilePersonal.vue"
import Suggestion from "@/comps/Suggestion.vue"
import Daily from "@/comps/Daily.vue"
import DailyShareLink from "@/comps/daily/ShareLink.vue"
import LoginRequired from "@/comps/LoginRequired.vue"
import {useRouter} from "vue-router"
import {computed} from "vue"
import MobileHeaderMenu from "@/comps/MobileHeaderMenu.vue"

const {isShowRedeemCode, isShowConfig, isShowLogin, isShowMobileMenu, isShowInvite, isShowPersonal,
        isShowDaily, isShowDailyShareLink, isShowSuggestion } = storeToRefs(useModalState())

const router = useRouter()

const popupPosition = function(mobilePosition : "bottom" | "top" | "center") {
    if (isMobile()) {
        return mobilePosition
    } else {
        // 电脑端总是显示在中心位置
        return "center"
    }
}

// 是否显示强制登录弹窗
// Hi, 登录、注册页面不显示强制登录
const isForceLogin = computed(() :boolean => {
    // 对于特殊的页面，不需要强对称登录
    let allowPages = ["/login", "/register", "/logout", "/hi", "/help", "/shop", "/r", "/rd", "/cbassets"]
    let r = router.currentRoute.value.path
    console.log("当前 r 是", r, router.currentRoute.value)
    for (let i in allowPages) {
        if (r.startsWith(allowPages[i])) {
            return false
        }
    }
    if (r == "/") {
        return false
    }
    

    console.log("当前 isForceLogin 状态是", useAccount().isLoaded, useAccount().a)

    return useAccount().isLoaded && (!(useAccount().a))

})

</script>


<template>
    <!-- 兑换码。桌面端 -->
    
    <Component :is="MobileModal" :isShow="isShowRedeemCode" @close="isShowRedeemCode = false" title="使用兑换码" :hideOKButton="true" :hideIcon="true">
        <Component :is="RedeemCode" />
    </Component>
    

    <!-- 兑换码。移动端 -->
    <!-- <Component :is="MobileModal" :isShow="isShowRedeemCode" @close="isShowRedeemCode = false" :position="popupPosition('bottom')"  title="使用兑换码" :hideOKButton="true" :hideIcon="true">
        <Component :is="RedeemCode" />
    </Component> -->



    <!-- 设置。桌面端 -->
    <!-- <Component :is="MobileModal" :isShow="isShowConfig" @close="isShowConfig = false" position="center">
        <Component :is="Config" @close="isShowConfig = false" @showMenu="isShowConfig=false" />
    </Component>  -->

    <!-- 设置。移动端 -->
    <Component :is="MobileModal" :isShow="isShowConfig" @close="isShowConfig = false" :position="popupPosition('bottom')">
        <Component :is="Config" @close="isShowConfig = false" @showMenu="isShowConfig=false; isShowMobileMenu = true;" />
    </Component>


    <!-- 登录/注册 -->
    <Transition name="modal">
    <Component :is="MobileModal" :tabs="['账户登录', '注册用户']" 
        :isShow="isShowLogin || isForceLogin" :hideOKButton="true" @close="isShowLogin = false" position="center" title="" icon="Logo-Yellow"
        slot="slotProps"
        class="popup-login"
    >
    
        <template #账户登录>
            <!-- props={{slotProps}} -->
            <Component :is="LoginForm" @close="isShowLogin=false" />
        </template>

        <template #注册用户>
            <Component :is="RegisterForm"  @close="isShowLogin=false"  />
        </template>
    </Component>
    </Transition>


    
    <!-- 邀请奖励 -->
    <Component :is="MobileModal" :isShow="isShowInvite" @close="isShowInvite = false" title="邀请奖励" >
        <Component :is="InviteInfo" />
    </Component>


    <!-- 底部弹窗，个人资料，移动版 -->
    <Component :is="MobileModal" :isShow="isShowPersonal"
        @close="isShowPersonal = false" :hideIcon="true"
        :position="popupPosition('bottom')" :hideOKButton="true"
        :title="useAccount().cached ? useAccount().cached.account.username : '需要先登录哦'" class="popup-mobile-user-info"
    >
        <Component :is="MobilePersonal" @close="isShowPersonal=false" />
    </Component>
    


    <!-- 每日任务 -->
    <Component :is="MobileModal" :isShow="isShowDaily" @close="isShowDaily=false" title="每日积分任务" :hideOKButton="true" icon="Star" class="popup-daily-task">
        <Component :is="Daily" @close="isShowDaily=false" />
    </Component>
    <!-- 每日任务：分享链接-->
    <Component :is="MobileModal" :isShow="isShowDailyShareLink" @close="isShowDailyShareLink=false" title="分享链接到社交平台">
        <Component :is="DailyShareLink" />
    </Component>
    
     

    <!-- 强制登录框 -->
    <!-- <Component :is="MobileModal" :isShow="false" title="登录 ChatBoy">
        <Component :is="LoginForm" />
    </Component> -->
    

    <!-- 移动端顶部下滑菜单-->
    <Component :is="MobileHeaderMenu" />


    <!-- “提建议”窗口-->
    <Component :is="Suggestion" :isShow="isShowSuggestion" />
    


</template>