<script setup lang="ts">
import { computed } from "@vue/reactivity";
import { onUnmounted, onMounted, defineAsyncComponent} from "vue"
import {apiFetch, duration2Readable, gaga} from "../common"
import {L} from "@/common2"
import {ref} from "vue"
import Loading from "../components/Loading.vue"
import MobileHeader from "../comps/MobileHeader.vue"
import LeftBottomBox from "@/comps/LeftBottomBox.vue"
import DailyBonus from "@/comps/DailyBonus.vue"
import MakePaymentAlipay from "@/comps/MakePaymentAlipay.vue"
import {PendingPaymentInfo} from "@/types"
import MobileModal from "@/comps/MobileModal.vue"
import MobileHeader2 from "@/comps/MobileHeader2.vue"

import "@/assets/shop.scss"


const OrderList = defineAsyncComponent(() => import("@/comps/OrderList.vue"))



defineProps<{
    // currentPage: string,
    currentUser: any,
}>()

onMounted(() => {
    loadProduct()
    loadPrice()
    gaga("event", "view_item")
})

const emit = defineEmits(["reloadCurrentUser"])

const isShowOrder = ref(false)


const error = ref("")

const products = ref(<any>[])
const currentPlan = ref("")
const streakRatios = ref(<any>{})

const isLoadingProduct = ref(false)

const tokenRequirement = ref(<any>{})

const pendingPaymentInfo = ref(new PendingPaymentInfo())

// 用户当前选中的订阅套餐
const selectedInternalName = ref("")
const selectedQty = ref(1)
const selectedPlan = computed(() => {
    return selectedProduct.value
})
const selectedProduct = computed(() => {
    for (let k in products.value) {
        if (products.value[k].InternalName == selectedInternalName.value) {
            return products.value[k]
        }
    }
    return null
})



const shouldPayAmount = computed(() :number => {
    return calculateAmount(selectedPlan.value?.Price, selectedQty.value)
})


// 传入单价和连续订阅的月份数量，计算折扣后金额
function calculateAmount(price: number, qty: number) :number {    
    return Math.round(selectedPlan.value?.Price * qty * getStreakRatio(qty) * 100) / 100
}


// 根据当前选择的套餐，判断是否允许购买这种数量
const isQtyAvailable = computed(() => (qty: number) => {
    // console.log("当前选中的套餐是 ", selectedInternalName.value)

    if (selectedInternalName.value == "") {
        return false
    }

    // 对于 Try 类型的套餐，只允许购买 1 个月
    if (selectedInternalName.value.match(/^try_/)) {
        return qty == 1
    }

    return true
})

async function loadPrice() {
    try {
        let j = await apiFetch("/goapi/token_requirement", {})
        tokenRequirement.value = j

    } catch (e) {
        console.warn(e)
    } finally {
    }
}


function planName(internalName :string) {
    // console.log("当前的 product 是", products.value)
    for (let i in products.value) {
        if (products.value[i].InternalName == internalName) {
            return products.value[i].Name
        }
    }

    return internalName
}

async function loadProduct() {
    isLoadingProduct.value = true
    try {
        let res = await apiFetch("/goapi/product/list", {})
        products.value = res.products
        currentPlan.value = res.current_plan
        streakRatios.value = res.streak_discount_ratio

        // 如果尚未选定套餐，则自动选定当前订阅套餐
        if (selectedInternalName.value == "") {
            if (res.current_plan.match(/(level_[0-9]_.+)/) ){
                selectedInternalName.value = res.current_plan
            }
        }
        // 如果当前未订阅付费套餐，则默认选定第一个套餐
        if (selectedInternalName.value == "" && products.value.length > 0) {
            selectedInternalName.value = products.value[0].InternalName
        }

    } catch (e) {
        error.value = (e as Error).message
    } finally {
        isLoadingProduct.value = false
    }
}

function selectPlan(name: string) {
    selectedInternalName.value = name

    // 用户选择了新套餐的时候，如果当前选中的 Qty 是不可用的，则自动选择另一个可用的 Qty
    if (isQtyAvailable.value(selectedQty.value) == false) {
        for (let i = 1; i < 12; i++) {
            if (isQtyAvailable.value(i)) {
                selectedQty.value = i
            }
        }
    }

    gaga("event", "add_to_cart", {
        internal_name: selectedInternalName.value,
        qty: selectedQty.value,
    })
}

function selectQty(qty: number) {
    selectedQty.value = qty

    gaga("event", "add_to_cart", {
        internal_name: selectedInternalName.value,
        qty: selectedQty.value,
    })
}

function getStreakRatio(m: number) :number {
    let ratios = streakRatios.value
    if (!ratios) {
        console.log("未能获取 streak_discount_ratio")
        return 1
    }

    if (streakRatios.value[m.toString()] != undefined) {
        return parseFloat(streakRatios.value[m.toString()])
    }
    
    return 1
}

const sellingProducts = computed(() => {
    let ret = <any>[]
    for (let i = 0; i < products.value.length; i++) {
        if (products.value[i].IsNotForSale == true) {
            continue
        }
        ret.push(products.value[i])
    }
    return ret
})

// 如果用户当前已有订阅，同时选择了其他订阅套餐时，需要显示一条警告信息
const productChangeWarning = computed(() :boolean => {
    if (currentPlan.value != "" && currentPlan.value != selectedInternalName.value) {
        return true
    } else {
        return false
    }
})

// 将套餐的可订阅时长转换为可读的样式，例如 “30天”，“1天”等
function productDurationReadable(duration :number) {
    if (duration == 86400 * 30 || duration == 86400 * 31) {
        return "30 天"
    } else if (duration == 86400) {
        return "1 天"
    } else if (duration < 86400) {
        return Math.floor(duration / 3600) + " 小时"
    } else {
        return Math.floor(duration / 86400) + " 天"
    }
}

function updatePendingPaymentInfo(newVal :PendingPaymentInfo) {
    // console.log("受到 pendingPaymentInfo", newVal)
    pendingPaymentInfo.value = newVal
}

const drawableCount = computed(() => (token :any) => {
    let r = tokenRequirement.value?.MJImage?.ImagineRelax
    if (!r) {
        return 0
    }

    let ret = Math.floor(token / r)
    
    return ret
})


// 计算连续购买的已优惠金额
// 在 pendingPaymentInfo.realAmount 大于 0 时，使用该数值进行计算。否则，使用预设的折扣率进行计算
const discountAmount = computed(() :number => {
    if (selectedPlan.value && selectedQty.value) {
        let price = selectedPlan.value.Price
        let qty = selectedQty.value

        if (pendingPaymentInfo.value.realAmount > 0) {
            return price * qty - pendingPaymentInfo.value.realAmount
        }

        return price * qty - calculateAmount(price,  qty)
    } else {
        return 0.1
    }
})


</script>

<template>


    <Component :is="MobileHeader" />

    <div class="main-sidebar">
        <div class="section section-chat">
            <Component :is="DailyBonus" />
            <div class="section-sub">
                <div class="sub-tab-b active">
                    <div class="info-main">
                        <p class="title">购买套餐</p>
                    </div>
                    <div class="info-sub">
                        <icon name="Arrow-Right" />
                    </div>
                </div>
            </div>
        </div>

        <Component :is="LeftBottomBox" />

    </div>

    <Component :is="MobileHeader2" title="商店" />

    <div class="main-content main-content-buy">

        
        <div class="title-section">
            <div class="left-part">
                <div class="ctrl">
                    <div class="title-icon">
                        <icon name="Shop-Cart" />
                    </div>
                    <h2 class="title">购买套餐</h2>
                </div>
            </div>
            <div class="right-part">
                <div class="button-g button-small button-buy-histroy" @click="isShowOrder=true">
                    <icon name="Clock-History-Time" />
                    购买/兑换历史
                </div>
            </div>
        </div>

        <!-- 待支付信息 -->
        <div class="bottom-info-section buy-section">
            <icon name="Timer" />
            <p class="">
                待付
                <span v-if="pendingPaymentInfo.realAmount == 0" class="price color-purple">{{L(shouldPayAmount)}}</span>
                <span v-else class="price color-purple">{{pendingPaymentInfo.realAmount}}</span>
                元 · <span class="color-purple">{{selectedPlan?.Name}}</span>
                <span v-if="discountAmount > 0">（已优惠 {{discountAmount.toFixed(2)}} 元）</span>
            </p>
        </div>

        <div class="content-section content-section-buy">

            <div class="title-box-buy">
                <div class="flex-row-between">
                    <h3>1. 选择购买套餐</h3> 
                    <div class="button-g button-small button-buy-histroy" @click="isShowOrder=true">
                        <icon name="Clock-History-Time" />
                        购买/兑换历史
                    </div>
                </div>
                <Transition name="slideDown">
                    <p class="tx-a color-orange" v-if="productChangeWarning">
                        ※ 您的订阅
                        <span class="color-black100">{{planName(currentPlan)}}</span>
                        还未到期，如果购买新订阅将会更新订阅状态，同时以前的订阅将会失效。
                    </p>
                </Transition>
                <Component :is="Loading" text="正在加载套餐信息" v-if="sellingProducts.length == 0" />
            </div>

            <div class="buy-box price-box">

                <!-- 企业推广卡片 -->
                <router-link to="/help?t=qa#qa-business-package" class="tile-card tile-card-sp-ad flex-gap16" v-if="sellingProducts.length>0">
                    <p class="price flex-row-center flex-gap4">
                        <span class="tx-a">💫</span>
                    </p>
                    <p class="name">企业套餐</p>
                    <div class="label-ctrl">
                        <p class="label">
                            <span>微信 chatboy666 咨询</span>
                        </p>
                    </div>
                    <div class="info flex-col-center flex-gap8">
                        <p class="weight600">&lt; 团队起飞的必备选择 &gt;</p>
                        <i>无限点数 &订阅时长</i>
                        <i>无限工具使用</i>
                    </div>
                </router-link>

                <div v-for="p in sellingProducts"
                    class="tile-card flex-gap16" :class="{'active': selectedInternalName == p.InternalName, 'inactive': selectedInternalName != p.InternalName}"
                    @click="selectPlan(p.InternalName)"
                >
                    <icon name="Ok-Tick-Circle-Fill" class="tile-icon-tick" />

                    <p class="price flex-row-center flex-gap4">
                        <span class="tx-a">{{p.Price}}</span>
                        <span class="tx-b">元/月</span>
                    </p>
                    <p class="name">{{p.Name}}</p>
                    <div class="label-ctrl">
                        <p class="label">
                            <span v-if="currentPlan == p.InternalName">订阅中</span>
                            <span v-else-if="p?.LabelText">{{p.LabelText}}</span>
                            <span v-else>全年订阅价格福利</span>
                        </p>
                    </div>

                    <div class="info flex-col-center flex-gap8">
                        <p class="">{{p.ChargeToken.toLocaleString()}} 点数</p>
                        <p v-if="drawableCount(p.ChargeToken) > 0">（约合 {{drawableCount(p.ChargeToken)}} 次 Ai 绘画）</p>
                        <p>{{productDurationReadable(p.ChargeTime)}}订阅时长</p>
                    </div>
                </div>

            </div>

            <div class="title-box-buy" v-if="selectedPlan">
                <h3>2. 选择购买时长</h3>
            </div>
            <div class="buy-box time-box" v-if="selectedPlan">
                <div v-for="i in [1, 3, 6, 12]"
                    class="tile-card flex-gap16"
                    :class="{
                        'active': selectedQty == i, 'inactive': (selectedQty != i && isQtyAvailable(i)),
                        'disable': !isQtyAvailable(i),
                        'hide': !isQtyAvailable(i),
                        'hover-cursor': isQtyAvailable(i),
                    }"
                    @click="if (isQtyAvailable(i)) { selectQty(i);}"
                >
                    <p v-if="selectedInternalName.match(/^try_/)" className="name">{{duration2Readable(selectedProduct.ChargeTime * i, 0)}}</p>
                    <p v-else className="name">{{i}} 个月</p>

                    <div v-if="i == 1" class="info flex-col-center flex-gap8">
                        <p class="">原价</p>
                        <p class="">{{selectedPlan?.Price}}元</p>
                    </div>
                    <div  v-else className="info flex-col-center flex-gap8">
                        <p><del>原价 {{Math.round(selectedPlan?.Price * i * 100) / 100}} 元</del></p>
                        <p style="white-space: nowrap;">
                            {{Math.round(getStreakRatio(i) * 10 * 10) / 10}}
                            折
                            {{calculateAmount(selectedPlan?.Price, i)}}
                            元
                        </p>
                    </div>
                </div>
            </div>
            <div class="title-box-buy" v-if="selectedPlan">
                <h3>3. 支付宝扫码支付</h3>
            </div>
            <div class="buy-box pay-box" v-if="selectedPlan">
                
                <div class="alipay-ctrl">

                    <Component :is="MakePaymentAlipay"
                        :currentUser="currentUser" :qty="selectedQty" :productInternalName="selectedInternalName" :amount="shouldPayAmount" :product="selectedProduct"
                        @reloadCurrentUser="emit('reloadCurrentUser')"
                        @onPendingPaymentInfo="updatePendingPaymentInfo"
                    />

                    <div class="mobile-bill-info">
                        <div class="tx-a">
                            <icon name="Timer" />
                            <p>待付</p>
                        </div>
                        <p class="">
                            <span v-if="pendingPaymentInfo.realAmount == 0" class="price color-purple">{{shouldPayAmount}}</span>
                            <span v-else class="price color-purple">{{pendingPaymentInfo.realAmount}}</span>
                            元 · <span class="color-purple">{{selectedPlan?.Name}}</span>
                        </p>
                        <p v-if="discountAmount > 0">（已优惠 {{discountAmount.toFixed(2)}} 元）</p>
                    </div>
                </div>

            </div>


        </div>
    </div>


    <Component :is="MobileModal" :isShow="isShowOrder" @close="isShowOrder=false" :hideIcon="true" :hideOKButton="true" title="购买/兑换历史" class="popup-order-list">
        <Component :is="OrderList" @close="isShowOrder=false" />
    </Component>

</template>

<style scoped>
</style>