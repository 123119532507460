<script setup lang="ts">
// <!-- 我的提现记录 -->
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import {apiFetch, time2Readable} from "../../common"
import {mobileModalFadeIn, mobileModalFadeOut, slideDownIn, slideUpIn, slideUpOut} from "@/animation"

const list = ref(<any>[])
const error = ref("")

onMounted(() => {
    loadWithdrawal()
})


async function loadWithdrawal() {
  try {
    error.value = ""
    let j = await apiFetch("/goapi/withdrawal/list", {})
    // console.log("载入了 withdrawal 信息", j)
    list.value = j
  } catch (e) {
    console.warn("载入 withdrawal 信息失败", e)
    error.value = (e as Error).message
  }
}



</script>

<template>
    <div class="list">
        <ul class="list-ctrl">
            
                <li class="row title-row">
                    <p>提现时间</p>
                    <p>提现金额</p>
                    <p>状态</p>
                </li>

                <li class="row" v-for="w in list.withdrawals">
                <p>{{time2Readable(w.create_time)}}</p>
                <p>{{w.amount}} 元</p>
                <p>
                    <div v-if="w.close_time == 0">
                    <span v-if="w.pay_time > 0">提现成功</span>
                    <span v-if="w.pay_time == 0">等待处理</span>
                    </div>
                    <div v-else class="color-error">已关闭</div>
                </p>
                </li>
                
                <!--为空状态-->
                <li class="row" v-if="list?.withdrawals?.length == 0">
                  <div class="tip-info">
                      <icon name="Info-Circle" />
                      <p class="tx">无记录</p>
                  </div>
                </li>

            </ul>
    </div>
</template>

