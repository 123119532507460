import dayjs from 'dayjs'
import {useCookies} from "vue3-cookies"
import useClipboard from 'vue-clipboard3'

const {cookies} = useCookies()
const {toClipboard} = useClipboard()
import { getCurrentInstance } from 'vue'
import {notify} from "@kyvg/vue3-notification"

let BASE_URL :string
let BASE_WS_URL :string
if (import.meta.env.PROD) {
    BASE_URL = "https://" + window.location.host
    BASE_WS_URL = "wss://" + window.location.host
} else {
    let url = import.meta.env.VITE_API_ENDPOINT
    
    if (!url) {
        url =  `${window.location.protocol}//${window.location.hostname}:5342`
    }
    // 如果 url 最后一个字符是 / ，则删除这个字符
    if (url.lastIndexOf('/') + 1 == url.length) {
        url = url.substring(0, url.length - 1)
    }
    BASE_URL = url

    if (window.location.protocol.startsWith("https:")) {
        BASE_WS_URL = BASE_URL.split(window.location.protocol).join("wss:")
    } else {
        BASE_WS_URL = BASE_URL.split(window.location.protocol).join("ws:")
    }
}
console.log("设置 BASE_URL 为", BASE_URL)



export function apiURL(url :string) :string {
    if (BASE_URL[BASE_URL.length - 1] == '/') {
        return BASE_URL.slice(0, -1) + url
    } else {
        return BASE_URL + url
    }
}

export function wsURL(path :string = "/goapi/ws") :string {
    let ret = BASE_WS_URL + path
    if (ret.startsWith("https://")) {
        ret = ret.replace("https://", "wss://")
    } else if (ret.startsWith("http://")) {
        ret = ret.replace("http://", "ws://")
    }
    
    return ret
    
}

export function imageURL(hash :string, storageNamespace :string = "") :string {
    if (storageNamespace != "") {
        hash += "," + storageNamespace
    }
    return apiURL("/goapi/upload/" + hash)
}

export function useWS() {
    let ws = getCurrentInstance()?.appContext.config.globalProperties.ws
    return ws
}

export function imageProxy(src: string) :string {
    if (!src) {
        return ""
    }

    // return apiURL("/goapi/ip/" + encodeURIComponent(encodeURIComponent(src)))
    return apiURL(src)
}

export function apiFetchRaw(url :string, options :any) {
    let sessionKey: string | null = localStorage.getItem("chatboy_session_key")

    // 附加 SessionKey
    if (sessionKey) {
        options.headers = options.headers || {};
        options.headers["x-chatboy-session-key"] = sessionKey;
    }

    // 附加 TrackID
    try {
        options.headers = options.headers || {};
        options.headers["x-chatboy-track-id"] = getTrackID()
    } catch (e) {
        console.warn(e)
    }

    return fetch(apiURL(url), options)
}

export async function apiFetch(url :string, options? :any) {
    if (options === undefined) {
        options = {}
    }
    let res = await apiFetchRaw(url, options)
    let j = await res.json()
    if (j.code != 0) {
        // 503 是特殊的编码，此编码为临时的自定义错误，需要将错误信息显示给用户
        if (j.code == 503) {
            notify({type: "error", text: j.message, duration: 10 * 1000})
        }

        throw new Error("错误: " + j.message)
    } 

    // 如果返回头中有 x-chatboy-session-key，则将其写入 localStorage
    // 由于通过 CORS 请求时，只能获取几个标准的 HTTP 头，所以我们只能在内容中传递 session key
    // let resSessionKey :string | null = res.headers.get("x-chatboy-session-key")
    // console.log("服务器返回的 x-chatboy-session-key 是", res.headers, resSessionKey)
    let resSessionKey :string = j?.session_key || null
    if (resSessionKey) {
        console.log("更新本地 x-chatboy-session-key 为 ", resSessionKey)
        localStorage.setItem("chatboy_session_key", resSessionKey)
    }
    

    return j.data
}

// 使用 XHR 执行上传操作，传入 options.onprogress 可以获取上传进度回调
export async function apiUploadRaw(url :string, options? :any) :Promise<XMLHttpRequest> {
    if (options === undefined) {
        options = {}
    }

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open(options.method || "POST", apiURL(url))
        xhr.setRequestHeader("x-chatboy-session-key", getSessionKey())
        xhr.setRequestHeader("x-chatboy-track-id", getTrackID())
        if (options.headers) {
            Object.keys(options.headers).forEach(function (key) {
                xhr.setRequestHeader(key, options.headers[key])
            })
        }
        
    
        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr)
            } else {
                reject(new Error(xhr.statusText))
            }
        }
        xhr.onerror = function (e) {
            reject(new Error("网络错误？"))
        }

        if (options.onprogress) {
            xhr.upload.onprogress = options.onprogress
        }

        xhr.send(options.body)
    })

}

export async function reloadCurrentUser() {
    console.debug("请求更新当前用户信息")

    try {
        let res: any = await apiFetch("/goapi/current_account", {method: "GET"})
        if (res) {
            localStorage.setItem("chatboy_current_user", JSON.stringify(res.account))
            console.debug("reloadCurrentUser(): 成功更新了当前用户信息")
            return res
        }
    } catch (e) {
        console.debug("reloadCurrentUser(): 更新当前用户信息失败", e)

        if ((e as Error).message.indexOf("用户未登录") >= 0) {
            console.debug("由于用户未登录，清除本地用户登录信息")
            localStorage.removeItem("chatboy_current_user")
            localStorage.removeItem("chatboy_session_key")
        }
    }
    return null
}

// 获取当前用户的 session key
export function getSessionKey() :string {
    let raw = localStorage.getItem("chatboy_session_key") || ""
    return raw
}

// 获取当前页面的 searchParams 对象
export function searchParams(): URLSearchParams {
    let search = window.location.href.split("?");
    if (search.length <= 1) {
        return new URLSearchParams()
    } else {
        return new URLSearchParams(search[1])
    }
}

export function duration2Readable(ts: number, precision? : number, space_between_word: boolean = false) {
    // console.log(precision)
    if (precision === undefined) {
        precision = 2
    }

    let space = ""
    if (space_between_word) {
        space = " "
    }

    if (ts > 86400) {
        let days = (ts / 86400).toFixed(precision)
        return `${days}${space}天`
    } else if (ts > 3600) {
        let hours = (ts / 3600).toFixed(precision)
        return `${hours}${space}小时`
    } else if (ts > 60) {
        let minutes = (ts / 60).toFixed(precision)
        return `${minutes}${space}分钟`
    } else {
        return `${ts}${space}秒`
    }
}

export function time2Readable (t: any, zeroText: string = '正在加载...') {
    if (!t) {
      return zeroText
    }
    return dayjs.unix(t).format("YYYY年M月D日 HH:mm:ss").toString()
}
export function time2ReadableShort (t: any, zeroText: string = '正在加载...') {
    if (!t) {
      return zeroText
    }
    return dayjs.unix(t).format("YYYY/M/D HH:mm:ss").toString()
}
  
// 将兑换码格式化为 xxxx-xxxx-xxxx 的格式（添加横线）
export function formatCode(c: string) :string {
    c = c.replace(/-/, "")
    let token = []
    for (let i = 0; i < Math.ceil(c.length / 4); i++) {
        token.push(c.slice(i * 4, i * 4 + 4))
    }
    return token.join("-")
}

export function gaga(p1: string, p2: string, p3?: any) {
    if ((window as any).gtag) {
        (window as any).gtag(p1, p2, p3)
        console.log("发送了一条 gtag 消息", p1, p2, p3)

        // 自动发送 event 给我们自己
        try {
            if (p1 == "event") {
                cbevent(p2, p3)
            }
        }catch (e) {console.warn(e)}
    } else {
        console.warn("gtag 未初始化，未能发送 gtag 事件")
    }
}

export async function cbevent(evt: string, extra?: any) {
    if (!extra) {
        extra = ""
    } else {
        extra = JSON.stringify(extra)
    }
    await apiFetch("/goapi/event/new", {
        method: "POST",
        body: JSON.stringify({
            ExtraData: extra,
            Event: evt,
            TrackID: getTrackID(),
        })
    })
}

// 获取当前用户的 TrackID,如果不存在则自动创建一个
export function getTrackID() :string {
    let trackID = cookies.get("cb_track_id")
    if (!trackID) {
        trackID = newTrackID()
    }
    cookies.set("cb_track_id", trackID, 86400 * 365)
    return trackID
}

export function newTrackID() {
    let ts = (new Date()).getTime()
    let t1 = Math.round(ts / 1000).toString(16)
    // let t2 = Math.round(ts % 1000).toString(16)
    let t3 = Math.round(Math.random() * Math.pow(2, 32)).toString(16)

    return `${t3}-${t1}`
}



export function crc32(str: string, radix = 16) {
    const Utf8Encode = function(s: string) {
      s = s.replace(/\r\n/g, "\n");
      let text = "";
      for (let n = 0; n < s.length; n++) {
        const c = s.charCodeAt(n);
        if (c < 128) {
          text += String.fromCharCode(c);
        } else if ((c > 127) && (c < 2048)) {
          text += String.fromCharCode((c >> 6) | 192);
          text += String.fromCharCode((c & 63) | 128);
        } else {
          text += String.fromCharCode((c >> 12) | 224);
          text += String.fromCharCode(((c >> 6) & 63) | 128);
          text += String.fromCharCode((c & 63) | 128);
        }
      }
      return text;
    }
  
    const makeCRCTable = function(){
      let c;
      const crcTable = [];
      for(let n =0; n < 256; n++){
        c = n;
        for(let k =0; k < 8; k++){
          c = ((c&1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
        }
        crcTable[n] = c;
      }
      return crcTable;
    }
  
    const crcTable = makeCRCTable();
    const strUTF8 = Utf8Encode(str);
    let crc = 0 ^ (-1);
    for (let i = 0; i < strUTF8.length; i++ ) {
      crc = (crc >>> 8) ^ crcTable[(crc ^ strUTF8.charCodeAt(i)) & 0xFF];
    }
    crc = (crc ^ (-1)) >>> 0;
    return crc.toString(radix);
}

export function invertColor(hex: string, bw = false) :string {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color: ' + hex);
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    let rr = (255 - r).toString(16).padStart(2, '0');
    let gg = (255 - g).toString(16).padStart(2, '0');
    let bb = (255 - b).toString(16).padStart(2, '0');
    // pad each with zeros and return
    return "#" + rr + gg + bb;
}



export function MJJobType2Readable(type: string, subType :string = "") :string {
    // GPT 大法好！没文化也能写文绉绉的文案

    // 重绘：
    // 笔墨重铸
    // 华彩再绘
    // 彩墨重铸
    // 点染画卷
    // 拂墨勾勒
    // 再绘图影


    // 扩图：
    // 增广画卷
    // 华彩再绘
    // 图景放大

    if (type == "imagine") {
        return "以词构画"
    } else if (type == "upsample") {
        return "图景扩大"
    } else if (type == "variation") {
        return "华彩再绘"
    } else if (type == "outpaint") {
        return "画卷扩阔"
    } else if (type == "upscale") {
        return "会心精描"
    } else {
        return type
    }
}

export function MJSubJobType2Readable(type: string, subType: string) :string {
    if (type == "upscale") {
        if (subType == "subtle") {
            return "稳定"
        } else if (subType == "creative") {
            return "创意"
        }
    }
    return ""
}


// 先给要复制的文本或者按钮加上点击事件后，并将要复制的值传过来
export async function writeClipboard(val: string) {
    try {
        await toClipboard(val)    
    } catch  (e) {
        console.debug("调用 vue-clipboard3 复制失败，将尝试使用后备方法", e)
        await writeClipboardNormal(val)
    }
}

async function writeClipboardNormal(val :string) {
    if ( navigator.clipboard) {
        // navigator clipboard 向剪贴板写文本
        let ret = navigator.clipboard.writeText(val)
        return ret
    } else {
        console.log("使用textarea 执行复制操作")
        // 创建text area
        const textArea = document.createElement('textarea')
        textArea.value = val
        textArea.style.opacity = "0"
        textArea.style.position = "fixed"
        textArea.style.top = "1px"
        textArea.style.left = "1px"
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        return new Promise((res, rej) => {
            // 执行复制命令并移除文本框
            if (document.execCommand('copy')) {
                res(null)
             } else {
                rej()
             }
            textArea.remove()
        })
    }
}


// 获取当前页面的 page 参数
// 如果 page 不合法，则自动处理
export function getPage() :number {
    let s = searchParams().get("page")
    let p = s ? parseInt(s) : 1
    if (p <= 0) {
        p = 1
    }
    return p
}
// 获取当前页面的 limit 参数
// 如果 limit 不合法，则自动处理
export function getLimit() :number {
    let s = searchParams().get("limit")
    let p = s ? parseInt(s) : 1
    if (p <= 0) {
        p = 50
    }
    return p
}

export function base64ToArrayBuffer(base64 :string) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
  
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
  
    return bytes;
}

export function getDevice() :"desktop" | "mobile" {
    try {
        let zIndex = window.getComputedStyle(document.querySelector(".device-indicator") as Element).zIndex
        if (zIndex == "1") {
            return "desktop"
         } else if (zIndex == "2" ) { 
            return "mobile"
        } else {
            console.warn("未能获取当前设备状态，将返回默认值 desktop。获取到的 zIndex 是", zIndex)
        }
    } catch(e){ 
        console.warn("无法获取当前设备状态")
    }

    // 默认返回 desktop 类型
    
    return "desktop"
}

// 使用提供的用户名和密码执行登录操作
export async function loginWith(username :string, password :string) {
    try {
        let res = await apiFetch("/goapi/login", {
            method: "post",
            body: JSON.stringify({
                "username": username,
                "password": password,
            }),
        })

        console.debug("登录成功，设置 chatboy_current_user 信息")
        localStorage.setItem("chatboy_current_user", JSON.stringify(res.user))

    } catch(e) {
        console.debug("登录失败", e)
        throw new Error("登录失败. " + (e as any).message)
    }
}
