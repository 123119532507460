import { createApp, defineAsyncComponent, getCurrentInstance } from 'vue'
import Alpha from './Alpha.vue'
import Notifications from '@kyvg/vue3-notification'
import {createRouter, createWebHistory, createWebHashHistory} from 'vue-router'
import {wsURL} from "./common"
import {CBWebSocket} from "./cbwebsocket"
import {createPinia} from "pinia"

import { MotionPlugin } from '@vueuse/motion'


import Home from "./pages/Home.vue"
import Hi from "./pages/Hi.vue"
import Test from "./pages/Test.vue"
import Page404 from "@/comps/404.vue"

import NProgress from 'nprogress' 
import 'nprogress/nprogress.css'


import './assets/global-reset.scss'
import "./assets/page.scss"

import "primevue/resources/themes/aura-light-purple/theme.css"





// (function dispatchEventStorage() {
//     const signSetItem = localStorage.setItem
//     localStorage.setItem = function (key, val) {
//         let setEvent: any = new Event("setItemEvent")
//         setEvent.key = key
//         setEvent.value = val
//         window.dispatchEvent(setEvent)
//         signSetItem.apply(this, [key, val])
//     }
// })()

// 路由定义开始

// const Assets = defineAsyncComponent(() => import("@/pages/Assets.vue"))
// const Assets = (() => import("@/pages/Assets.vue"))
const Assets = () => import("@/pages/Assets.vue")
// import Assets from "@/pages/Assets.vue"

const rawRoutes: Object = {
    // "": [Home, "首页"],
    // "chat": [Home, "聊天"],
    "tools": [Home, "工具"],
    "shop": [Home, "商店"],
    "help": [Home, "帮助"],
    "hi": [Hi, "介绍"],
    "index.html": [Home, "首页"],
    "test.html": [Test, "测试"],
    "assets": [Assets, "素材"],
    "cbassets": [Assets, "素材"],
    "cbicons": [defineAsyncComponent(() => import("@/comps/Icons.vue")), "素材"],
    
    


    // "404.html": Page404,
}



var routes :any[] = []
Object.keys(rawRoutes).forEach((k) => {
    let title = (rawRoutes as any)[k][1]
    if (!title) {
        title = ""
    }

    routes.push({
        path: `/${k}`, 
        component: (rawRoutes as any)[k][0],
        // meta: {title: (rawRoutes as any)[k][1] ?? "ChatBoy"},
        // meta: {title: (rawRoutes as any)[k][1] ?? ""},
        meta: {title: title},
    })    
})



// 添加聊天页面相关路由
routes.push({path: "", redirect: "/chat"})
routes.push({
    path: "/chat",
    component: Home,
    meta: {"title": "聊天"},
    // children: [],
})

routes.push({
    path: "/tools/:name",
    component: Home,
})

// 开始添加其他页面——设置页面
const Config = (() => import("@/pages/Config.vue"))
const ConfigSidebar = (() => import("@/pages/config/SideBar.vue"))
const Personal = (() => import("@/pages/config/Personal.vue"))
routes.push({
    path: "/config",
    meta: {title: "配置"},
    component: Config,
    children: [{
        path:  "personal",
        meta: {title: "个人配置"},
        components: {
            default: ConfigSidebar,
            innerPage: Personal,
        },
    }, {
        path:  "",
        redirect: "/config/personal"
    }, {
        path: "advance",
        meta: {title: "高级配置"},
        components: {
            default: ConfigSidebar,
            innerPage: (() => import("@/pages/config/Advance.vue")),
        },
    }, {
        path: "chat",
        meta: {title: "聊天配置"},
        components: {
            default: ConfigSidebar,
            innerPage: (() => import("@/pages/config/Chat.vue")),
        },
    }, {
        path: "withdrawal",
        meta: {title: "申请提现"},
        components: {
            default: ConfigSidebar,
            innerPage: (() => import("@/pages/config/Withdrawal.vue")),
        },
    }]
})

// 邀请页面
routes.push({
    path: "/hi/:inviteCode",
    component: () => import("@/pages/Hi.vue"),
    meta: {title: "快来使用 Ai 吧"},
})
routes.push({
    // path: "/rd/:inviteCode", // /rd/ 是 goapi 专用的页面，goapi 会跳转到 /hidaily/
    path: "/hidaily/:inviteCode",
    component: () => import("@/pages/Hi.vue"),
    meta: {title: "快来使用 Ai 吧"},
})

// 登录页面
routes.push({
    path: "/login",
    component: () => import("@/pages/Login.vue"),
    meta: {title: "登录"},
})
routes.push({
    path: "/register",
    component: () => import("@/pages/Login.vue"),
    meta: {title: "注册"},
})

// 账户相关
routes.push({
    path: "/password_reset",
    component: () => import("@/comps/PasswordReset.vue"),
    meta: {title: "重置密码"},
})

// 画廊
routes.push({path: "/gallery", meta: {title: "画廊"}, component: () => import("@/pages/Gallery.vue")})

routes.push({path: "/:pathMatch(.*)*", component: Page404}) // 添加 404 页面


// 管理员页面
routes.push({
    path: "/admin",
    component: () => import("@/pages/admin/Home.vue"),
    children: [
        {path: "stat", component: () => import("@/admins/AdminStat.vue")},
        {path: "code", component: () => import("@/admins/AdminCode.vue")},
        {path: "token_usage", component: () => import("@/admins/AdminTokenUsage.vue")},
        {path: "team_docs", component: () => import("@/admins/TeamDocs.vue")},
        {path: "misc", component: () => import("@/admins/AdminMisc.vue")},
        {path: "cbassets", component: () => import("@/pages/Assets.vue")},
    ],

})
// routes.push({path: "/admin/stat", component: () => import("@/components/admin/Stat.vue")})


// 路由定义结束

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    NProgress.start()
    next()
})

router.afterEach((to, from, next) => {
    try {
        if (to.meta.title ?? null) {
            document.title = to.meta.title + " - ChatBoy"
        } else {
            document.title = "ChatBoy"
        }
    } catch (e){}

    if ((window as any).gtag) {
        (window as any).gtag("config", "G-LRZT0VPRW0", {
            page_title: to.name,
            page_path: to.path,
        })
    }

    NProgress.done()
})

NProgress.configure({
    showSpinner: false,
    speed: 200,
    trickleSpeed: 100,
})




import UseIcon from "./plugins/UseIcon"
import PrimeVue from "primevue/config"
import "primevue/resources/themes/aura-light-green/theme.css"

const app = createApp(Alpha)
const pinia = createPinia()
app.use(UseIcon)
app.use(router)
app.use(Notifications)
app.use(MotionPlugin)
app.provide('bus', {})
app.use(pinia)
app.use(PrimeVue, {
    ripple: true
})


import * as Sentry from "@sentry/vue"
Sentry.init({
    app,
    dsn: "https://bb501b1bda3115c9f3adc6d2ee2f0686@o4504291785441280.ingest.us.sentry.io/4507009003618304",
    integrations: [
        Sentry.globalHandlersIntegration({
            onunhandledrejection: false,
            onerror: false,
          }),
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
  
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/chatboy\.io/],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});



try {
    const ws = new CBWebSocket(wsURL())
    ws.addEventListener("open", function() {
        console.log("WebSocket 已连接")
    })
    app.config.globalProperties.ws = ws
} catch (e) {
    console.warn("无法初始化 WebSocket")
}


app.mount('#app')
