<script setup lang="ts">
import {ref, onMounted, computed} from "vue"
import {getPresets, newChatID, isDev} from "../../common2"
import {toolCardIn, toolCardBeforeIn} from "@/animation"
import {useRouter} from "vue-router"
import {useAccount} from "@/store"
import {storeToRefs} from "pinia"
import {LocalChatStorage} from "@/chat_storage"
const lcs = new LocalChatStorage()

const router = useRouter()

import LRU from "../../lru"

import type {Tool} from  "./../../types"

const lru = new LRU("recent_used_tools_v2")

const {account} = storeToRefs(useAccount())

const presets = ref(<any>[])

onMounted( async () => {
    // 首先从缓存中加载 preset，以便显示“”首屏内容
    let raw = localStorage.getItem("cb_tools_preset_cache") || ""
    try {
        let j = JSON.parse(raw)
        if (j) {
            presets.value = j
        }
    } catch(e) {
    }


    let res = await getPresets({isTool: 1})
    let ps = Object.values(res)
    ps.sort((a :any, b:any) => {
        if (a > b) {
            return -1
        } else {
            return 1
        }
    })
    presets.value = ps

    localStorage.setItem("cb_tools_preset_cache", JSON.stringify(ps))
})

const hotPresets = computed(() => {
    let ret = Object.values(presets.value)

    ret.sort((a :any , b :any ) :number => {
        // console.log("排序", b.Popularity, a.Popularity)
        return b.Popularity - a.Popularity
    })

    // console.log("排序后 ", ret)

    return ret.slice(0, 6)
})

async function clickTool(t :Tool) {
    if (t.type == "chat") {
        let link :string = ""

        // 如果本地已经存在对话，则直接使用本地对话
        const entry = lru.get("chat-" + t.name)
        if (entry) {
            // 检查此对话是否存在本地记录
            const chatID :string = new URLSearchParams(entry.link).get("id") || ""
            if (await lcs.get(parseInt(chatID)) === undefined) {
                // 本地对话不存在，什么都不做
            } else {
                // 本地对话存在，直接使用这个链接
                console.debug("存在一个对话，直接修改 link 为这个对话", entry)
                link = entry.link
            }
        }

        if (link == "") {
            console.debug("本地对话不存在，创建链接", t.name, t.raw.ID, link, t)
            const id = newChatID(t.name, t.raw.ID)
            link =  "/tools?t=chat&id=" + id
        }
        lru.set("chat-" + t.name, {name: t.name, link})
        // window.location.hash = link 
        router.push(link)
    } else if (t.type == "aimage" || t.type == "lightingimage" || t.type == "gallery" || t.type == "sdxlbatch") {
        let link = "/tools?t=" + t.type
        lru.set("aimage", {name: t.name, link})
        router.push(link)
    } else if (t.type == "link") {
        router.push(t.link || "")
    } else if (t.type == "removebg") {
        let link = "/tools/" + t.type
        lru.set("removebg", {name: t.name, link})
        router.push(link)
    } else {
        alert("该功能尚未开放")
    }
}


const tools = computed(() :Tool[] => {
    let ret :Tool[] = []


    for (let i = 0; i < presets.value.length; i++) {
        let v = presets.value[i]
        
        ret.push(<Tool>{
            name: v.Title,
            description: v.Description,
            popularity: v.Popularity,
            backgroundColor: v.Color,
            icon: v.Icon,
            type: "chat",
            class: v.Class,
            raw: v,
        })
    }

    ret.push(<Tool>{
        name: "AI 画图",
        description: "根据文字描述生成图片",
        popularity: 100,
        backgroundColor: "#f6e5f2",
        icon: "🎨",
        type: "aimage",
        class: "card-pink",
    })

    if (isDev()) {
        ret.push(<Tool>{
            name: "闪电绘图",
            description: "眨眼的时间Ai就能画好图",
            popularity: 100,
            backgroundColor: "#f6e5f2",
            icon: "⚡️",
            type: "lightingimage",
            class: "card-blue",
        })
        
    }

    // ret.push(<Tool>{
    //     name: "SDXL 连续画<sup><small> 测试</small></sup>",
    //     description: "使用SDXL连续画一批图",
    //     popularity: 100,
    //     backgroundColor: "#f6e5f2",
    //     icon: "💫",
    //     type: "sdxlbatch",
    //     class: "card-blue",
    // })

    if (isDev()) {
    ret.push(<Tool>{
        name: "Ai 抠图",
        description: "Ai 自动抠图，省时又省力",
        popularity: 100,
        backgroundColor: "#e5f6f2",
        icon: "🫥",
        type: "removebg",
        class: "card-green",
    })
    }

    ret.push(<Tool>{
        name: "画廊",
        description: "Ai 画廊，为你提供 Ai 绘画的灵感",
        popularity: 100,
        backgroundColor: "#f6e5f2",
        icon: "🖼",
        type: "gallery",
        class: "card-peachpuff",
    })


    if (account.value?.account?.is_admin) {
        ret.push(<Tool>{
            name: "管理后台",
            description: "管理员身份登录可入",
            popularity: 999999,
            backgroundColor: "#f6e5f2",
            icon: "😈",
            type: "link", // 借用 aimage 的类型，点击后直接切换页面
            link: "/admin/stat",
        })
    }

    return ret
})

const hotTools = computed(() :Tool[] => {
    let ret :Tool[] = []
    ret.push(...tools.value)
    ret.sort((a, b)  => {
        return b.popularity - a.popularity
    })

    return ret.slice(0, 10)
})


</script>

<template>

    <div class="content-section content-section-tools">

        <div class="title-box-tool">
            <icon name="Fire-Hot" />
            <p class="title">热门工具</p>
        </div>


        <TransitionGroup tag="div" class="tool-box" @enter="toolCardIn" @before-enter="toolCardBeforeIn">        
            <div class="tool-card"
            v-for="v, k in (hotTools as any)"
            @click="clickTool(v)"
            :key="k"
            :data-index="k"
            :class="v.class"
            >
                <div class="title-ctrl">
                    <div class="info-left">
                        <span class="title-icon">{{v.icon}}</span>
                        <p class="title" v-html="v.name"></p>
                    </div>
                    <div class="info-right">
                        <icon name="Arrow-Right" />
                    </div>
                </div>
                <div class="info-ctrl">
                    <p class="tx">{{v.description}}</p>
                    <div class="sub">
                        <p class="count">
                            <!-- 暂时隐藏剩余次数 -->
                            <!-- <span>剩余次数</span>3 -->
                            <span v-if="v.popularity > 1">热度: {{v.popularity.toFixed(0)}}</span>
                        </p>
                    </div>
                </div>
            </div>
            
        </TransitionGroup>

        <div class="title-box-tool">
            <icon name="Category" />
            <p class="title">所有工具</p>
        </div>


        <!-- <div class="tool-box"> -->
        <TransitionGroup tag="div" class="tool-box" @enter="toolCardIn" @before-enter="toolCardBeforeIn">
            <div class="tool-card"
            v-for="v, k in tools"
            :key="k"
            :data-index="k"
            :class="v.class"
            @click="clickTool(v)"
            >
                <div class="title-ctrl">
                    <div class="info-left">
                        <span class="title-icon">{{v.icon}}</span>
                        <p class="title" v-html="v.name"></p>
                    </div>
                    <div class="info-right">
                        <icon name="Arrow-Right" />
                    </div>
                </div>
                <div class="info-ctrl">
                    <p class="tx">{{v.description}}</p>
                    <div class="sub">
                        <p class="count">
                            <!-- 暂时隐藏剩余次数 -->
                            <span v-if="v.popularity > 1">热度: {{v.popularity.toFixed(0)}}</span>
                        </p>
                    </div>
                </div>
            </div>
            
        </TransitionGroup>
        <!-- </div> -->
    </div>
</template>