<script setup lang="ts">
import { notify } from "@kyvg/vue3-notification"
import {getLimit, getPage, searchParams} from "../common"
import {onMounted, onUnmounted, computed, ref, watch} from "vue"

const props = withDefaults(defineProps<{
    total?: number
    limit?: number
    page?: number
    showTotal?: boolean
}>(), {
    total: 0,
    limit: 10,
    page: 0,
    showTotal: false,
})

const page = ref(props.page)
const limit = ref(props.limit)

onMounted(() => {
    page.value = props.page ? props.page : getPage()
    limit.value = props.limit ? props.limit : getLimit()
})

const maxPage = computed(() => {
    if (props.total <= 0) {
        return 1
    }
    if (limit.value == 0) {
        return 1
    }

    if (props.total % limit.value == 0) {
        return props.total / limit.value
    }

    return Math.ceil(props.total / limit.value)
})

const pagerURL = computed(() => (p: number): string => {
    let s = searchParams()
    s.set("page", p.toString())
    s.set("limit", limit.value.toString())

    if (p == 1) {
        s.delete("page")
    }

    return window.location.href.split("?")[0] + "?" + s.toString()
})

const pages = computed(() => {
    let ret: number[] = [page.value]

    // 从当前的页面开始，向两边生成页面序号
    for (let i = 1; i <= 3; i++) {
        let p = page.value - i;
        if (p <= 0) {
            break
        }
        ret.unshift(p)
    }

    
    for (let i = 1; i <= 3; i++) {
        let p = page.value + i;
        if (p > maxPage.value) {
            break
        }
        ret.push(p)
    }

    return ret
})

function scroll() {
    // 跳转到页头，这样可以在点击按钮刷新后，页面自动跳转到最底部
    // try {
    //     window.scrollTo(0, 0)
    // } catch (e){}
}

</script>

<template>

    <div class="history-pager" v-if="total > 0">
        <div v-if="page != 1">
            <a :href="pagerURL(page - 1)" @click="scroll">
                <icon name="Arrow-Tail-Left" /> 
            </a>
        </div>

        <a v-for="i in pages" :class="{'active': page == i}"
            :href="pagerURL(i)"
            @click="scroll"
        >{{i}}</a>

        <div v-if="page != maxPage">
            <a :href="pagerURL(page + 1)" @click="scroll">
                <icon name="Arrow-Tail-Right" /> 
            </a>
        </div>

        <div v-if="props.showTotal" class="total-count-tx">
            <span class="tx-a">{{(page) * limit}}</span>
            <span class="tx-b">/</span>
            <span class="tx-b">{{props.total}}</span>
        </div>
    </div>
    

    <!-- <div style="margin-top: 10em;"></div>
    <div>page={{page}}</div>
    <div>maxPage={{maxPage}}</div>
    <div>total={{props.total}}</div>
    <div>limit={{limit}}</div> -->


</template>
