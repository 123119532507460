<script setup lang="ts">
import {onBeforeMount, ref, computed} from "vue"
import LoginForm from '@/comps/LoginForm.vue';
import RegisterForm from '@/comps/RegisterForm.vue';
import MobileHeader from '@/comps/MobileHeader.vue';
import Header from '@/comps/Header.vue';
import {slideLeftIn, slideLeftOut, slideRightIn, slideRightOut} from '@/animation';

import { useRouter, useRoute } from 'vue-router';

const route = useRoute()
const router = useRouter()

const tab = ref("login")

onBeforeMount(() => {
    // 如果存在 query.tab ，则根据此信息显示
    if (route.query.tab == "register" || route.query.tab == "login") {
        tab.value = route.query.tab
    } else {
        // 默认情况下，根据 URL 判断显示何种页面
        if (route.path.endsWith("register")) {
            tab.value = "register"
        } else if (route.path.endsWith("login")) {
            tab.value = "login"
        } else {
            tab.value = "register"
        }
    }

    
})


// 计算登录或注册成功后应该跳转到的页面
const redirect = computed(() :string => {
    let back :string|undefined = router.options.history.state.back?.toString()
    if (!back) {
        back = ""
    }
    if (back.indexOf("/login") === 0) {
        return "/"
    } else {
        return back
    }
})


// 登录/注册 成功了，跳转到来源页
function onSuccess() {
    let to = redirect.value
    if (!to) {
        to = "/"
    }
    console.debug("登录/注册成功，将跳转到 ", to)
    router.push(to)
}


</script>

<template>

    <Component :is="Header" />
    <Component :is="MobileHeader" />

    <div class="popup popup-login popup-mobile-center-space popup-solo-login" style="background-image: url('demo/bg1.webp');">
        <div class="popup-ctrl">
            <div class="popup-title-section">
                <div class="left-part">
                    <div class="icon-ctrl">
                        <icon name="Logo-Yellow" preserveAspectRatio="xMaxYMid meet" />
                    </div>
                    <h3 class="title">ChatBoy.io</h3>
                </div>
                <div class="center-part">
                    <div class="g-nav-tab-popup-top">
                        <router-link :to="{'path': '/login', query: {tab : 'login'}}" :class="{'active': tab=='login'}">账户登录</router-link>
                        <router-link :to="{'path': '/login', query: {tab : 'register'}}" :class="{'active': tab=='register'}">注册用户</router-link>
                    </div>
                </div>
                <div class="right-part">
                    <!-- 蓝天大海时/登出时，这个关闭弹窗按钮没有用
                    <a class="button-g-icon button-size-36 button-fill-nude">
                        <icon name="Cross-Close" />
                    </a>
                    -->
                </div>
            </div>


            <div class="popup-content-section">

                
                    <Component @success="onSuccess" :is="RegisterForm" v-if="tab=='register'"/>
                

                
                <Component @success="onSuccess" :is="LoginForm" v-if="tab=='login'" />
                
            </div>

        </div>
    </div>
        
</template>