<script setup lang="ts">
import {onMounted, computed, ref, defineAsyncComponent} from "vue"
import ToolsSideBar from "./ToolsSideBar.vue"

import AImage from "./tools/AImage.vue"
import Home from "./tools/Home.vue"
const LightingImage = defineAsyncComponent(() => import("./tools/LightingImage.vue"))
const ChatBox = defineAsyncComponent(() => import("@/comps/ChatBox.vue"))
const SDXLBatch = defineAsyncComponent(() => import("./tools/SDXLBatch.vue"))
import MobileHeader from "./MobileHeader.vue"
import { getQuery } from "@/common2"
import {useRoute} from "vue-router"

const props = defineProps<{
    isMobile :boolean
}>()

const route = useRoute()

// TEST - This is for mobile touchstart HACK TRICK
window.onload = function() {
    if(/iP(hone|ad)/.test(window.navigator.userAgent)) {
        document.body.addEventListener('touchstart', function() {}, false);
    }
}

const InnerPage = computed(() => {
    // let page = getQuery("t")
    let page = innerPage.value
    
    if (page == "" || page == "home") {
        return Home
    } else if (page == "chat") {
        return ChatBox
    } else if (page == "aimage") {
        return AImage
    } else if (page == "lightingimage") {
        return LightingImage
    } else if (page == "sdxlbatch") {
        return SDXLBatch
    } else if (page == "gallery") {
        return defineAsyncComponent(() => import("@/comps/Waterfall.vue"))
    } else if (page == "removebg") {
        return defineAsyncComponent(() => import("./tools/RemoveBg.vue"))
    }

    return defineAsyncComponent(() => import("../components/404.vue"))
})

const innerPage = computed(() :string => {
    let t = useRoute().query.t?.toString() || ""

    if (t == "") {
        t = route.params?.name as string || ""
    }

    return t
})



const shouldShowSideBar = computed(() => {
    // 列出需要隐藏的情况，其他情况都显示
    if (props.isMobile && getQuery("id")) {
        return false
    }
    // if (shouldHideSideBar.value) {
    //     return false
    // }
    return true
})

const shouldShowInnerPage = computed(() => {
    // 现在使用 CSS 隐藏侧边栏了，所以这里可以总是显示内页
    return true


    // 只有在移动端，且显示侧边栏的时候，才需要隐藏内页
    if (shouldShowSideBar.value && props.isMobile) {
        return false
    } else {
        return true
    }
})



console.log("InnerPage=", InnerPage)
</script>

<template>
    <Component :is="MobileHeader" />
    <Component :is="ToolsSideBar" v-show="shouldShowSideBar" />

    
    <div class="main-content" v-show="shouldShowInnerPage" v-if="innerPage!='chat'">
        <Component :is="InnerPage" :shouldShowSideBar="false"  />
    </div>
    <Component v-else :is="ChatBox" :allowFlush="true" />
    
</template>