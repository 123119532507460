<script setup lang="ts">
import { onMounted, computed, defineAsyncComponent, defineProps, ref, onUnmounted } from "vue";
import {getQuery, getPreset, getChatID, getAutoIncrement, safeIncr, chatMessageTimeToReadable, wordCount, parseStreamData} from "../common2"
import {LocalChatStorage, deleteMessage} from "../chat_storage"
import { apiURL , apiFetchRaw, apiFetch, time2Readable, writeClipboard} from "@/common";
// import ChatBox from "@/comps/ChatBox.vue"
import ChatSideBar from "./ChatSideBar.vue"
import MobileHeader from "./MobileHeader.vue"


// import 'highlight.js/styles/atom-one-dark.css'


const ChatBox = defineAsyncComponent(() => import("@/comps/ChatBox.vue"))

const emit = defineEmits(['showSideBar'])
const props = defineProps<{
    isMobile? :boolean
}>()

// 是否正在同步消息
const isSyncing = ref(false)


const sideBar = ref(<any>null)

const chat = ref(<any>{})
const chatID = ref(0)

const storage = new LocalChatStorage()


onMounted(async () => {    
    // 坑注意！必须在 await doOnMounted() 之前调用 bus.on()，否则在快速切换页面的时候，有可能出现 Unmounted 已经执行完毕，而 onMounted() 仍在等待网络请求的情况
    // 从而 bus.off() 运行结束之后，onMounted（） 中的 bus.on() 才得到执行，导致事件被重复注册
    // bus.on('ws.auth_success', onWebSocket)

    await doOnMounted()
})
onUnmounted(async () => {
    // bus.off('ws.auth_success', onWebSocket)
})

async function doOnMounted() {
    let id = await getChatID()
    chatID.value = id

    let c = storage.get(chatID.value)
    chat.value = c



    // 在同步云端数据前，先滚动到底部
    setTimeout(() => {
        document.getElementById("chat_vue_chat_anchor")?.scrollIntoView({})
    }, 10)

    // 云端数据同步成功后，再执行一次滚动到底部
    setTimeout(() => {
        document.getElementById("chat_vue_chat_anchor")?.scrollIntoView({})
    }, 10)
}


function onSelectChat() {
    // console.log("发生了 seelctChat 事件")
    // shouldHideSideBar.value = true
}

const shouldShowSideBar = computed(() => {
    // 列出需要隐藏的情况，其他情况都显示
    if (props.isMobile && getQuery("id")) {
        return false
    }
    // if (shouldHideSideBar.value) {
    //     return false
    // }
    return true
})


// 用户在新的电脑上登录时，LocalStorage 是空的。
// 在 SideBar 从云端同步聊天列表后，会触发这个事件，我们需要在这里再次加载 chat，避免出现空白的聊天页面
async function afterSideBarUpdate() {
    // 由于 SideBar 更新完毕事件比本组件的 onMounted 事件更早完成，我们需要手动延迟一下，等待本组件 mounted 之后再尝试挂载页面
    setTimeout(async () => {
        if (!chat.value?.id) {
            console.debug("收到 ChatSideBar.afterUpdate 事件，且当前 chat.value 为空，触发一次页面刷新")
            await doOnMounted()
        }
    }, 1000
    )
}


</script>

<template>



    <Component :is="MobileHeader" />


    <!-- 聊天侧边栏（ChatSiddBar )-->
    
    <Component :is="ChatSideBar" ref="sideBar"
            v-if="shouldShowSideBar"
            @selectChat="onSelectChat"
            @afterUpdate="afterSideBarUpdate"
        />
    

    <!-- 聊天页面 -->
    <Component :is="ChatBox" :isMobile="props.isMobile" />
    

</template>

<style>

.katex {
    /* color: red; */
    font-size: 1.2em;
}
</style>